import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import questions from "../../utils/translation/en.json";
import * as ReactBootstrap from "react-bootstrap";
import Button from "../../Button/Button";
import savedAnswerKeys from "./schema.json";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "react-loader-spinner";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  updatePrevConfirmedState,
  isEditState,
  backToRepairAsset,
  workOrderData,
  originalWorkOrderData,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  userChosenSaveExit
} from "../../ducks/redux/actions/userChoices";
import {
  getContractorName,
  workOrderRepairObject
} from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { RepairPriority } from "../../models/Repair/CreateRepair";
import { CatalistTriageContractorType } from "../../models/Repair/Contractor";
import { SurveyTypes } from "../../models/shared/Enum";

const SurveyConfirmationDetails: React.FC<any> = (props) => {
  const savedResponses = useSelector((state: any) => state.userChoicesReducer); 
  const { t } = useTranslation();
  const dispatch = useDispatch();

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
    "Complaint"=3,
    "Technical Inspection"=5,
     "Disrepair"=6,
     "Right to Improve"=7,
     "Mutual Exchange"=8,
     "Leaseholder Improvement Request"=9,
     "Estate Management Survey"=10,
     "Keysafe Installation"=11,
     "Void Survey"=12
  }
  
  const determinedcontractordetails = useSelector(
    (state: any) => state.addresses?.determinedcontractordetails
  );
  
  const userChosenSupplementaryQuestionsVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSupplementaryQuestions
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const originalWorkOrderObject = useSelector(
    (state: any) => state?.userChoicesReducer?.originalWorkOrderData
  ); 
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const userChosenVandalismQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenCovidQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenCovidQuestion
  );
  const IsRecalled = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );
  const assetByIdDataObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  const userVandalismChoice = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );

  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );

  let isTriageCase = false;
  const isCatalystDefectTriageCase = isCatalyst && assetByIdDataObject?.statuses.isInDefects === true

  const userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );
  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );  
 
  const surveyReason = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const assetStatusVal=useSelector(
    (state: any) => state?.userChoicesReducer.assetDataStatus
    );

  let isContractorEMA = false;
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, Setdisabled] = useState(true);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  
  useEffect(() => {
    const parsedData = Object.entries(savedAnswerKeys).reduce(
      (prev: any, curr: any) => {
        let mainData: any = {};
        const combinedData = Object.entries(curr[1]);
        combinedData.forEach(([key, value]: any) => {
          if (savedResponses[value] || key === "urlPath") {
            mainData = {
              ...mainData,
              [curr[1]["urlPath"]]: {
                ...mainData[curr[1]["urlPath"]],
                [key]:
                  key === "urlPath"
                    ? value
                    : {
                      key: value,
                      value: savedResponses[value],
                    },
              },
            };
          }
        });
        return {
          ...prev,
          ...mainData,
        };
      },
      {}
    );
    dispatch(updatePrevConfirmedState(parsedData));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    (async function () {
      try {
        Setdisabled(true);
        setIsLoading(true);
        if (
          userChosenDuplicateQuestion != "" ||
          userChosenVulnerabilityAdditionalInformation != ""
        ) {
          isTriageCase = true;
        } else {
          isTriageCase = false;
        }
        if (!isTriageCase) {
          let contractorId: string = "";
          isContractorEMA = false;
          const isRecall = IsRecalled;
          let repairCauseId;
          if (assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true) {
            repairCauseId = userVandalismChoice == "Yes" ? 4 : 1;
          }
          else
            repairCauseId = 1;
          const sorCode = userChosenSOR;

          let determinedContractor: any = null;
          if (isCatalystDefectTriageCase) {
            //create dummy determined contractor object for catalyst defect triage case
            determinedContractor = [{
              "id": CatalistTriageContractorType.CATALYST_TRIAGE_DEFECT,
              "name": "Catalyst Defect Triage",
              "statuses": { 
              "isIntegrated": false,
              "isBuilder": false,
              "isSubContractor": false,
              "isEMA": false,
              "isActive": true
              },
              "contractorSites": [
                "CATALYST_TRIAGE_DEFECT"
              ],
              "emaType": "",
              "contacts": [
                {}
              ],
              "_links": [],
              "parentIdentifier": null
            }]
          } 
         // get contractor priority and check if OOH
         
          if (determinedcontractordetails) {
            const contractorDetail = determinedcontractordetails?.[0];
            if (
              contractorDetail?.name != "" &&
              contractorDetail?.name != null
            ) {
              Setdisabled(false);
            }
            dispatch(getContractorName(contractorDetail?.name));
            isContractorEMA = contractorDetail?.statuses?.isEMA;
            contractorId = contractorDetail?.id;
            
             let prioritySlot = "";
            prioritySlot = RepairPriority.NextAvailable;
            const sorcode = userChosenSOR; 
            if (
              contractorDetail &&
              workOrderObject != undefined &&
              workOrderObject != null
            ) {
              try {
                setIsLoading(true);
                workOrderObject.isOutOfHours =false;
                workOrderObject.contractorName = contractorDetail?.name;
                if (contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses && contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses.length > 0) {
                  workOrderObject.contractorPhoneNumber =
                    contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number;
                }
                if (sorcode != "") workOrderObject.sorCode = sorcode;

                const siteIdentifier = contractorDetail?.contractorSites
                  ? contractorDetail?.contractorSites[0]
                  : null;
                workOrderObject.contractor = siteIdentifier;
                userChosenSupplementaryQuestionsVal == "Yes"
                  ? (workOrderObject.isSupplementary = true)
                  : userChosenSupplementaryQuestionsVal == "No"
                    ? (workOrderObject.isSupplementary = false)
                    : (workOrderObject.isSupplementary = null);

                userChosenVandalismQuestionVal == "Yes" && assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true
                  ? (workOrderObject.isVandalism = true)
                  : userChosenVandalismQuestionVal == "No" && assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true
                    ? (workOrderObject.isVandalism = false)
                    : (workOrderObject.isVandalism = null);

                userChosenCovidQuestionVal === "Yes" && covidConfigValue
                  ? (workOrderObject.isCovidSelfIsolated = true)
                  : userChosenCovidQuestionVal === "No" && covidConfigValue
                    ? (workOrderObject.isCovidSelfIsolated = false)
                    : (workOrderObject.isCovidSelfIsolated = null);

                userChosenDuplicateQuestion == ""
                  ? (workOrderObject.isPotentialDuplicate = false)
                  : (workOrderObject.isPotentialDuplicate = true);
                userChosenVulnerabilityQuestion != "" &&
                  userChosenVulnerabilityQuestion != null &&
                  userChosenVulnerabilityQuestion != "No"
                  ? (workOrderObject.isVulnerable = true)
                  : (workOrderObject.isVulnerable = false);
                userChosenVulnerabilityAdditionalInformation != ""
                  ? (workOrderObject.VulnerableResponseText =
                    userChosenVulnerabilityAdditionalInformation)
                  : (workOrderObject.VulnerableResponseText = null);

                userChosenSOR != ""
                  ? (workOrderObject.sorCode = userChosenSOR)
                  : (workOrderObject.sorCode = "NA");
                  if (isCatalyst) {
                    workOrderObject.isCatalyst = true;
                  } 
                  workOrderObject.isInDefect = assetByIdDataObject?.statuses.isInDefects
                  workOrderObject.isOutOfHours =  false;  
                  workOrderObject.repairType= "survey";
            
                const _worksOrderRequest = new WorksOrderRequest(
                  workOrderObject
                );
                const woRequestParameters =
                  _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
                  
                woRequestParameters.originalWorkOrderIdentifier = workOrderObject.originalWorkOrderIdentifier
                woRequestParameters.repairType="survey";
                const apiResponse = await api.updateWorkOrderRepair(
                  woRequestParameters,
                  workOrderObject.id,
                  "eTag"
                );

                dispatch(workOrderRepairObject(workOrderObject));
              } catch (e) {
                console.error(e);
                props.history.push("/genericerror");
              } finally {
                setIsLoading(false);
              }
            }
          }
        } else {
          Setdisabled(false);
        }
      } catch (e) {
        props.history.push("/genericerror");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const exitButtonHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(workOrderRepairObject(null));
      dispatch(backToRepairAsset(false))
      dispatch(userChosenResumeRepair(false));
      dispatch(loadDuplicateRepairPage(false));
      dispatch(userCreatedNewRepair(null));
      if(IsRecall)
      {
         dispatch(workOrderData(originalWorkOrderObject))
         props.history.push(`/WorkOrder360View/${originalWorkOrderObject.identifiers?.worksOrderIdentifier}`);

       }
    else { 
       dispatch(workOrderRepairObject(null));
       dispatch(originalWorkOrderData(null))
       props.history.push("/LandingView");
        }
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    dispatch(originalWorkOrderData(null))
    dispatch(backToRepairAsset(false))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };
  const backButtonHandler = () => {
    if((surveyReason === SurveyTypes.Void_Survey && assetStatusVal === SurveyTypes.VOID)){
      props.history.push("/SurveyLocation");
    }
    else {
      props.history.push("/CustomerDetails");
    } 
  };
  const callPageHandler = () => {
    
    if (
      userChosenDuplicateQuestion != "" ||
      (userChosenVulnerabilityAdditionalInformation != "" && userChosenVulnerabilityAdditionalInformation != null)
    ) {
      isTriageCase = true;
    } else {
      isTriageCase = false;
    }
    let isCatalystTriageTier2Ema = false;
    let siteIdentifier = [];
    if (determinedcontractordetails)
    {
      const contractorDetail = determinedcontractordetails?.[0];
      siteIdentifier = contractorDetail?.contractorSites ?? contractorDetail?.contractorSites[0];
      isCatalystTriageTier2Ema = ((siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_TIER2) || (siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_EMA));
    }
    if (isTriageCase || isCatalystDefectTriageCase || isCatalystTriageTier2Ema) {
      submitForm(siteIdentifier[0]);
    } else {
     submitForm("");
    }
  };

  const redirect = (url: string) => {
    dispatch(isEditState(true));
    props.history.push(url);
  };

  const renderData = (item: any, index: any) => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1" + item[0]}
          className="flex items-center bg-white"
        >
          <TableCell role="presentation"
            id={"confirmation-tr1-td1" + item[0]}
            className="w-2/3 p-3 firstcol"
          >
            <div role="presentation"
              id={"confirmation-tr1-div1" + item[0]}
              className="text-base padding-new color_black global-txt"
            >
              {/* @ts-ignore */}
                {questions?.translation.Survey[item[0]]}             

            </div>
          </TableCell>
          <TableCell role="presentation"
            id={"confirmation-tr1-td2" + item[0]}
            data-testid={"confirmation-tr1-div2" + item[0]}
            className="w-2/3 p-3 secondcol"
          >
            <div role="presentation"
              id={"confirmation-tr1-div2" + item[0]}
              className="text-base padding-new color_black global-txt"
            >
              {" "}
              {!savedResponses[item[1]?.reduxKeyQs] 
              ?
                (savedResponses[item[1]?.reduxKey].split("@")[0] !="00000000000" ?
                savedResponses[item[1]?.reduxKey].split("@")[0] : "-") : ""
              }
              {savedResponses[item[1]?.reduxKeyQs]
                ?
                savedResponses[item[1]?.reduxKeys]
                : ""
              }
              {"\n"}
              {savedResponses[item[1]?.reduxKeyQs]}
              <br></br>
              {savedResponses[item[1]?.reduxKeyselect]}
            </div>
          </TableCell>

          <TableCell role="presentation"
            id={"confirmation-tr1-td3" + item[0]}
            className="w-2/3 p-3 thirdcol paddright"
          >
            <div role="presentation"
              id={"confirmation-tr1-div3" + item[0]}
              className="edt-spc padding-new text-right pr-8"
            >
              {" "}
              {(savedResponses[item[1]?.reduxKey].split("@")[0] ==="00000000000" ? <></> :
              <i
                data-testid="confirmation-icon"
                id={"confirmation-tr1-icon" + item[0]}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  redirect(`/${item[1]?.urlPath}`);
                }}
                onKeyDown={() => {
                  redirect(`/${item[1]?.urlPath}`);
                }}
              ></i> )}
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
 
  const workOrderUpdate = async () => {
    try {
      setIsLoading(true);
      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderObject.isDraft = false;
      if (isEMA) {
        workOrderObject.isEMA = true;
      }

      const siteIdentifier = contractorDetail?.contractorSites
        ? contractorDetail?.contractorSites[0]
        : null;
      workOrderObject.contractor = siteIdentifier;

      userChosenSupplementaryQuestionsVal == "Yes"
        ? (workOrderObject.isSupplementary = true)
        : userChosenSupplementaryQuestionsVal == "No"
          ? (workOrderObject.isSupplementary = false)
          : (workOrderObject.isSupplementary = null);

      userChosenVandalismQuestionVal == "Yes" && assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true
        ? (workOrderObject.isVandalism = true)
        : userChosenVandalismQuestionVal == "No" && assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true
          ? (workOrderObject.isVandalism = false)
          : (workOrderObject.isVandalism = null);

      userChosenCovidQuestionVal == "Yes" && covidConfigValue
        ? (workOrderObject.isCovidSelfIsolated = true)
        : userChosenCovidQuestionVal == "No" && covidConfigValue
          ? (workOrderObject.isCovidSelfIsolated = false)
          : (workOrderObject.isCovidSelfIsolated = null);

      userChosenDuplicateQuestion == ""
        ? (workOrderObject.isPotentialDuplicate = false)
        : (workOrderObject.isPotentialDuplicate = true);
      userChosenVulnerabilityQuestion != "" &&
        userChosenVulnerabilityQuestion != null &&
        userChosenVulnerabilityQuestion == "Yes"
        ? (workOrderObject.isVulnerable = true)
        : userChosenVulnerabilityQuestion == "No"
          ? (workOrderObject.isVulnerable = false)
          : (workOrderObject.isVulnerable = null);
      userChosenVulnerabilityAdditionalInformation != ""
        ? (workOrderObject.VulnerableResponseText =
          userChosenVulnerabilityAdditionalInformation)
        : (workOrderObject.VulnerableResponseText = null);

      userChosenSOR != ""
        ? (workOrderObject.sorCode = userChosenSOR)
        : (workOrderObject.sorCode = "NA");

      if (userVandalismChoice == "Yes" && assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true) {
        workOrderObject.crimeReferenceNumber =
          userChosenVandalismAdditionalInformationBackButton;
        workOrderObject.repairCause = "Vandalism or Damage";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        workOrderObject.crimeReferenceNumber = "";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      }
  
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.originalWorkOrderIdentifier = workOrderObject.originalWorkOrderIdentifier
      woRequestParameters.repairType="survey";
      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      const timerId = setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (siteCode : string) => {
    try {
      setIsLoading(true);
      if (
        userChosenDuplicateQuestion != "" ||
        (userChosenVulnerabilityAdditionalInformation != "" && userChosenVulnerabilityAdditionalInformation != null)
      ) {
        isTriageCase = true;
      } else {
        isTriageCase = false;
      }

      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderUpdate();
      if (!isTriageCase && !isCatalystDefectTriageCase && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_TIER2 && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_EMA) {
        const isIntegrated = contractorDetail?.statuses?.isIntegrated;

        const isBuilder = contractorDetail?.statuses?.isBuilder;
        
      }
      props.history.push("/SurveyFinalPage");
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container id="confirmation-container" role="main" className="ctn-fht">
      <div id="confirmation-back-div" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="confirmation-p" className="text-teal text-xl mt-4 mb-8"><a className="back-btn" data-testid="confirmationBackBtn" onClick={backButtonHandler} onKeyDown={backButtonHandler}>
          <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
      </div>

      <div className="mb-16 container">
        <div id="confirmation-main-div" className="global-txt">
          <h2 id="confirmation-h1" className="text-purple-100 text-2xl mb-2">
            {t("Survey.Survey_Details")}
          </h2>
          <p id="confirmation-p1" className="mb-4 color_black">
            {t("Survey.Survey_Details_Text1")}
          </p>
          <p id="confirmation-p2" className="mb-4 color_black">
            {t("Survey.Survey_Details_Text2")}
          </p>
        </div>
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={5000}
          />
        ) : (
          <div className="repair-text " id="confirmation-div-table">
            <ReactBootstrap.Table striped className="container-new">
              <TableBody role="presentation"
                id="confirmation-table"
                className="w-full border border-grey"
              >
                <th className="hidden">{t("table_heading")}</th>
                {" "}
                {Object.entries(savedAnswerKeys)
                  .filter((e: any) => {
                    if(!((surveyReason === SurveyTypes.Void_Survey || surveyReason === SurveyTypes.Keysafe_Installation) && (e[1]?.urlPath == "SurveyLocation" && e[1]?.reduxKey == "userChosenEmergencyQuestion"))) {
                      return savedResponses[e[1]?.reduxKey];
                    }
                  })
                  .map(renderData)}

              </TableBody>
            </ReactBootstrap.Table>
          </div>
        )}
      </div>
      <div
        id="confirmation-main-btn"
        className="flex justify-between items-center pb-4 md:pb-8 container"
      >
        <ExitModalAndSave
          id="confirmation-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />

        <Button
          id="btn_confirmation"
          onClick={callPageHandler}
          disabled={disabled}
        >
          {t("continuen_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};

export default SurveyConfirmationDetails;
