import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { formatContent, formatDateTime, formatDateWithFromCalendar, formathourminutes, getUpdateOwnerName, isNullOrUndefined } from "../../../utils/formatting";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import { MobilePagination, Pagination, Paginationbox, PropertySelect, Search, WebPagination } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import { CaseSortableFields } from "../../../models/Case/CaseSortableFields"
import { useDispatch, useSelector } from "react-redux";
import CaseSearchData, { Links } from "../../../models/Case/CaseResponse";
import { caseData, userChosenRaisedSince, userChosenRaisedUpto } from "../../../ducks/redux/actions/userChoices";
import moment from "moment";
import FilterComponenent from "../../Filter";
import { BackValue } from "../../../models/shared/Enum";
import { CASE_TENANCY_ID } from "../../../ducks/redux/actionTypes";
interface CaseListProps extends RouteComponentProps<any> {

}
const CaseListComponent: React.FC<CaseListProps> = (props) => {
    const arrayCases: CaseSearchData = {
        cases: [],
        "total-count": 0,
        skip: 0,
        limit: 0,
        _links: new Links(),
    };
    const arrayArea: Array<{ key: string; value: string }> = [];
    const [caseListData, setCaseList] = useState(arrayCases);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] = useState("createdDate desc");
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const defaultfromdate = formatDateTime(moment(new Date()).subtract(6, "months").toString())
    const [raisedSince, setRaisedSince] = useState(defaultfromdate);
    const defaultTodate = formatDateTime(moment(new Date()).toString())
    const [raisedUpto, setRaisedUpto] = useState(defaultTodate);
    const [isDateValid, setIsDateValid] = useState(true);
    const [dateErrorMesage, setDateErrorMessage] = useState('');
    const [isSearchValid, setIsSearchValid] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [associatedType, setAssociatedType] = useState('');
    const [caseStatusType, setCaseStatusType] = useState('');
    const [casePriority, setCasePriority] = useState('');
    const [associatedAsset, setAssociatedAsset] = useState("");
    const [assetIdentifiersAll, setassetIdentifiersAll] = useState("");
    const [selectedTenancy, setSelectedTenancy] = useState('');
    const timeValue = formathourminutes();

    const searchTextCountValue = useSelector(
        (state: any) => state.userChoicesReducer?.searchTextCountValue
    );

    const backValue = useSelector(
        (state: any) => state?.userChoicesReducer?.backValue
    );

    const customerData = useSelector(
        (state: any) => state.userChoicesReducer?.customersData
    );

    const customerID = useSelector((state: any) => state.addresses?.customerid);

    const assetDetails = useSelector(
        (state: any) =>
          state?.dashboardReducer?.formState?.assetByIdDataObject
      );

    const defaultAssetId = useSelector(
        (state: any) => state.userChoicesReducer?.defaultAssetId
    );

    const propertyAddress = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
    );

    const assetDataResponse = useSelector(
        (state: any) => state?.userChoicesReducer?.assetDataResponse
    );

    const caseTenancyIdentifier = useSelector(
        (state: any) => state?.caseCreationReducer?.caseTenancyId
    );

    const customerActiveTenancySummaries =
        useSelector((state: any) => state?.addresses?.customertenancies);

    const assetList = useSelector(
        (state: any) => state.userChoicesReducer?.getAssetList
    );

    const associatedAssetList = assetList && assetList.current && assetList.current.options;
    const [defaultAssociatedAsset, setDefaultAssociatedAsset] = useState(!assetDetails?.id ? defaultAssetId : assetDetails?.id)

    const isCaseListRequiredBasedOnCustomer = backValue == BackValue.Customer360View && !isNullOrUndefined(customerID);
    const isCaseListRequiredBasedOnAsset = backValue == BackValue.Asset360View && !isNullOrUndefined(assetDetails?.id);
    
    useEffect(() => {
        (async () => {
            try {
                let transientPropertyId = '';
                setIsLoading(true);
                if(assetDetails) {
                    if (isCaseListRequiredBasedOnCustomer == true && !isNullOrUndefined(customerActiveTenancySummaries)) {
                        if (!isNullOrUndefined(caseTenancyIdentifier)) {
                            setSelectedTenancy(caseTenancyIdentifier);
                        }
                        transientPropertyId = !assetDetails?.id ? defaultAssetId : assetDetails?.id;
                        getCaseListDataForSelectedProperty(transientPropertyId);
                    }
                    else if (isCaseListRequiredBasedOnAsset == true) {
                        transientPropertyId = getDefaultassociatedAsset(assetDetails?.id);
                        const propertiesIds: string[] = [];
                        const addressesData = await api.getAssociatedAssets(transientPropertyId);
                        addressesData.forEach((element) => {
                            if (assetDataResponse?.assetType !== 'Estate' || (assetDataResponse?.assetType == 'Estate' && element.assetType !== 'Property')) {
                                propertiesIds.push(element.id);
                            }
                        });
                        let assetIDsIntTxt = "assetIdentifiers=";
                        let assetIDsRemTxt = propertiesIds.join("&assetIdentifiers=");
                        let assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);
                        setassetIdentifiersAll(assetIDsAll);
                        getCaseListAfterAssociateCall(assetIDsAll);
                    }
                }
            } catch (e) {
                setIsLoading(false);
                props.history.push("/GenericError");
            }
        })();
    }, [assetDetails]);

    const getCaseListAfterAssociateCall = async (assetIdsAll: any) => {
        const raisedSinceDate = !raisedSince ? '' : raisedSince + 'T0' + timeValue[0] + ':' + timeValue[1] + ':00Z';
        const raisedUptoDate = !raisedUpto ? '' : raisedUpto + 'T' + (23 - timeValue[0]) + ':' + timeValue[2] + ':00Z';

        const casequeryParams = {
            searchText: "",
            limit: 10,
            skip: 0,
            orderBy: defaultSortOrder,
            status: caseStatusType,
            defaultassociatedAsset: assetIdsAll,
            customerIdentifiers: isCaseListRequiredBasedOnCustomer == true ? customerID : "",
            tenancyIdentifiers: isCaseListRequiredBasedOnCustomer == true && !isNullOrUndefined(caseTenancyIdentifier) ? caseTenancyIdentifier : "",
            assetType: associatedType,
            raisedSince: raisedSinceDate,
            raisedUpto: raisedUptoDate
        };
        const casesResponse = await api.getCaseListData(casequeryParams);
        setCaseList(casesResponse);
        setTotalCount(casesResponse["total-count"]);
        setIsLoading(false);
    }

    const getSelectedPropertyAssociatedAssets = async (selectedPropertyId: any) => {
        let assetIDsAll = '';
        const associatedAssets = await api.getAssociatedAssets(selectedPropertyId);
        if (associatedAssets != null) {
            const propertiesIds: string[] = [];
            associatedAssets.forEach((element) => {
                if (element.id !== selectedPropertyId) {
                    propertiesIds.push(element.id);
                }
            });
            let assetIDsIntTxt = "assetIdentifiers=";
            let assetIDsRemTxt = propertiesIds.join("&assetIdentifiers=");
            assetIDsAll = assetIDsIntTxt.concat(assetIDsRemTxt);
            setassetIdentifiersAll(assetIDsAll);
        }
        getDefaultassociatedAsset(selectedPropertyId);
        return assetIDsAll;
    }

    const getCaseListDataForSelectedProperty = async (transientPropertyId: any) => {
        const assetIdsAll = await getSelectedPropertyAssociatedAssets(transientPropertyId);
        if (!isNullOrUndefined(assetIdsAll)) { getCaseListAfterAssociateCall(assetIdsAll); }
    }

    const getDefaultassociatedAsset = (propertyId: any) => {
        setDefaultAssociatedAsset(propertyId);
        setAssociatedAsset(propertyId);
        return propertyId;
    }

    const getCaseData = (queryParams: any) => {
        (async () => {
            try {
                const data = await api.getCaseListData(queryParams);
                setCaseList(data);
                setTotalCount(data["total-count"]);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                props.history.push("/GenericError");
            }
        })();
    }

    const handleSubmit = (search: string) => {
        setSearchText(search);
        setCurrentPage(0);
        setResultsPerPage(10);
        setSkip(0);
        const validationMsg = getValidationMessage(search);
        if (validationMsg.length > 0) {
            setValidationMessage(validationMsg)
            setIsSearchValid(false);
            setCaseList(arrayCases);
        }
        else {
            setIsSearchValid(true);
            updateCaseData(search, 10, 0, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
        }
    }

    const handleChangeSortOrder = (e: { target: { value: string } }) => {
        setDefaultSortOrder(e.target.value);
        updateCaseData(searchText, 10, skip, e.target.value, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
    }

    const updateCaseData = (searchText: any,
        limit: any,
        skip: any,
        orderBy: any,
        statusType: any,
        associatedType: any,
        priority: any,
        raisedSinceValue: any,
        raisedUptoValue: any,
        tenancyIdentifier: any,
        assetIdsAll: any
    ) => {

        setIsLoading(true);
        setValidationMessage('');
        setIsDateValid(true);
        setIsSearchValid(true);
        setDateErrorMessage('');
        const raisedSinceDate = !raisedSinceValue ? '' : raisedSinceValue + 'T0' + timeValue[0] + ':' + timeValue[1] + ':00Z';
        const raisedUptoDate = !raisedUptoValue ? '' : raisedUptoValue + 'T' + (23 - timeValue[0]) + ':' + timeValue[2] + ':00Z';

        const queryParams = {
            searchText: searchText,
            limit: limit,
            skip: skip,
            orderBy: orderBy,
            status: statusType !== 'All' ? statusType : '',
            defaultassociatedAsset: assetIdsAll,
            customerIdentifiers: isCaseListRequiredBasedOnCustomer == true ? customerID : "",
            tenancyIdentifiers: isCaseListRequiredBasedOnCustomer == true && !isNullOrUndefined(tenancyIdentifier) ? tenancyIdentifier : "",
            associatedType: associatedType,
            raisedSince: raisedSinceDate,
            raisedUpto: raisedUptoDate,
            priority: priority
        };
        getCaseData(queryParams);

    }

    const getValidationMessage = (search: any) => {
        if (!isNullOrUndefined(search)) {
            if ((/[^0-9a-zA-Z]/.test(search.substring(0, 1))))
                return t("invalid_search_criteria");

            if (search?.includes('*')
                && search.substring(0, search.indexOf('*')).length < searchTextCountValue)
                return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

            if (search?.length < searchTextCountValue)
                return t("minimum_number_of_characters");
        }

        return "";
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
        setSkip(resultsPerPage * page);
        updateCaseData(searchText, resultsPerPage, resultsPerPage * page, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setResultsPerPage((e.target as any).value);
        setSkip(0);
        setCurrentPage(0);
        updateCaseData(searchText, (e.target as any).value, 0, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
    };

    const fetchData = () => {
        let skipResult = 0;
        let totalValue = 0;
        if (totalCount > resultsPerPage) {
            if (skip === 0 && resultsPerPage < 998) {
                skipResult = 0
                totalValue = 999
                setSkip(0);
            }
            else {
                skipResult = resultsPerPage
                totalValue = 999 + skipResult
                setSkip(skipResult);
            }
            setResultsPerPage(totalValue);
            updateCaseData(searchText, totalValue, skipResult, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
        }
    };

    const callcase360 = (caseSelectedData: any) => {
        dispatch(caseData(caseSelectedData))
        props.history.push("/Case360View");
    }


    const handleFilterSubmit = (customerStatus: any, tenancyType: any, propertyType: any, workOrderPropertyType: any, statusWorkOrder: any, priorityWorkOrder: any, customerWorkOrderAssetType: any, casePropertyType: any, caseStatusType: any, priorityCase: any, caseAssociatedType: any) => {
        setAssociatedType(caseAssociatedType);
        setCaseStatusType(caseStatusType);
        setCasePriority(priorityCase);
        setShowFilter(false);
        updateCaseData(searchText, 10, 0, defaultSortOrder, caseStatusType, caseAssociatedType, priorityCase, raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
    };

    const handelShowFilter = () => {
        setShowFilter(true);
    }

    const handleClose = () => {
        setShowFilter(false);
    }

    const handleReset = () => {
        setRaisedSince(defaultfromdate);
        setRaisedUpto(defaultTodate);
        setDefaultSortOrder("createdDate desc");
        setCaseStatusType('');
        setAssociatedType('');
        setCasePriority('');
        setSearchText('');
        setDefaultAssociatedAsset(associatedAsset);
        updateCaseData('', 10, 0, 'createdDate desc', '', '', '', defaultfromdate, defaultTodate, selectedTenancy, assetIdentifiersAll);
    }

    const handleResetSubmit = () => {
        setRaisedSince(defaultfromdate);
        setRaisedUpto(defaultTodate);
        setDefaultSortOrder("createdDate desc");
        setCaseStatusType('');
        setAssociatedType('');
        setCasePriority('');
        setSearchText('');
        updateCaseData('', 10, 0, "createdDate desc", '', '', '', raisedSince, raisedUpto, selectedTenancy, assetIdentifiersAll);
    }
    const handleDateFrom = (targetValue: any) => {
        dispatch(userChosenRaisedSince(targetValue));
        setRaisedSince(targetValue);
        setIsDateValid(false);
        const dateCheck = dateValidation(targetValue, raisedUpto);
        if (dateCheck) {
            updateCaseData(searchText, resultsPerPage, 0, defaultSortOrder, caseStatusType, associatedType, casePriority, targetValue, raisedUpto, selectedTenancy, assetIdentifiersAll);
        }
    }
    const handleDateTo = (targetValue: any) => {
        setRaisedUpto(targetValue);
        dispatch(userChosenRaisedUpto(targetValue));
        setIsDateValid(false);
        const dateCheck = dateValidation(raisedSince, targetValue);
        if (dateCheck) {
            updateCaseData(searchText, resultsPerPage, 0, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, targetValue, selectedTenancy, assetIdentifiersAll);
        }
    }

    const dateValidation = (fromDate: any, toDate: any) => {
        const todayDate = formatDateTime(moment(new Date()).toString())
        if (fromDate > todayDate) {
            setDateErrorMessage(t("start_date_validation"))
            setIsDateValid(false);
            return false;
        }
        else if (toDate > todayDate) {
            setDateErrorMessage(t("end_date_validation"))
            setIsDateValid(false);
            return false;
        }
        else if (fromDate > toDate && toDate != "") {
            setDateErrorMessage(t("valid_date_range"))
            setIsDateValid(false);
            return false;
        }
        else {
            setIsDateValid(true);
            setDateErrorMessage("")
            return true;
        }
    }

    const backButtonHandler = () => {
        if (backValue == BackValue.Customer360View || backValue == BackValue.Asset360View) {
            props.history.push("/" + backValue);
        }
    };

    const handleChangeAssociate = async (e: any) => {
        setIsLoading(true);
        const selectedTenancy =
            customerActiveTenancySummaries.filter(
                (ts: any) =>
                    ts.tenancySummary.premisesIds[0] === e
            );
        const assetIdsAll = await getSelectedPropertyAssociatedAssets(e);
        let selectedTenancyId = '';
        if (selectedTenancy.length > 0 && !isNullOrUndefined(selectedTenancy[0].tenancySummary)) {
            setSelectedTenancy(selectedTenancy[0].tenancySummary.tenancyId);
            dispatch({ type: CASE_TENANCY_ID, payload: selectedTenancy[0].tenancySummary.tenancyId });
            selectedTenancyId = selectedTenancy[0].tenancySummary.tenancyId;
        }
        updateCaseData(searchText, resultsPerPage, 0, defaultSortOrder, caseStatusType, associatedType, casePriority, raisedSince, raisedUpto, selectedTenancyId, assetIdsAll);
    };

    return (
        <div className="container ctn-fht mx-auto pb-12 my-5 global-txt">
            <div className="flex flex-row d-flex">

                <p id="vulQues-p" className="text-teal text-xl sm-pl-1">
                    <a className="back-btn" data-testid="backButtonHandler" onClick={backButtonHandler}>
                        <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
            </div>
            {backValue === BackValue.Asset360View && propertyAddress ? (
                <div className=" bd-highlight mt-4">
                    <div className="bd-highlight" data-testid="propertyAddress">
                        <h1 className="mb-4 sm-pl-1">{propertyAddress}</h1>
                    </div>
                </div>
            ) : (
                <>
                    <div className="bd-highlight">
                        {!isNullOrUndefined(customerData?.person?.name) && <h1 className="mb-4 sm-pl-1" data-testid="customerName">{customerData.person.name.title} {customerData.person.name.givenName} {customerData.person.name.surname}</h1>}
                    </div>
                    <div className="mt-4 mb-8 global-txt">
                        {associatedAssetList?.length > 1 && <span className="global-txt mb-4">{t("Select_your_tenancy")}</span>}
                        {associatedAssetList?.length > 1 ? (
                            <label className="block ">
                                <PropertySelect
                                    className="block w-full mt-2 rounded border-grey"
                                    data-bdd="cases-search-address"
                                    id="cases-search-address"
                                    data-testid="cases-search-address"
                                    defaultValue={defaultAssociatedAsset}
                                    disabled={(assetList.current.options.length + assetList.previous.options.length) === 1}
                                    onChange={handleChangeAssociate}
                                    options={assetList}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{
                                        "data-testid": "cases-search-address-select"
                                    }}
                                />
                            </label>
                        ) : (
                            <div
                                data-testid="cases-search-address"
                                id="cases-search-address"
                            >
                                {associatedAssetList?.length > 0 && <div>
                                    <input
                                        className="block w-full mt-1 rounded border border-grey "
                                        type="text"
                                        aria-label="Search Address"
                                        disabled={true}
                                        value={associatedAssetList[0].key}
                                    ></input>
                                </div>}
                            </div>
                        )}
                    </div>
                </>
            )}
            <div className="border-b border-grey ipad-grid flex flex-col md:flex-row justify-between items-center">
                <div className="w-full ipad-w-full sm:px-2 md:w-6/12 lg:w-4/12 flex items-center mb-4 sm:my-0 sm:flex-col">
                    <div className="flex ipad-w-full items-center mb-4 w-1/2 sm:w-full">
                        <label className="w-full flex items-center md:pr-2 ">
                            <span className="pr-1 text-date md:text-base pl-0 pt-2 sm:pl-4 w-1/2">{t("Created_from")}</span>
                            <input data-testid="fromDate" id="fromdate"
                                value={raisedSince}
                                defaultValue={defaultfromdate}
                                type="date"
                                max="9999-12-31"
                                onChange={e => handleDateFrom(e.target.value)}
                                className="border-grey text-sm md:text-base p-2 md:p-4 w-2/3"></input>
                        </label>
                    </div>
                    <div className="flex ipad-w-full items-center mb-4 w-1/2 sm:w-full">
                        <label className="w-full flex items-center  md:pr-2 ">
                            <span className="pr-1 text-date md:text-base pl-0 pt-2 sm:pl-4 w-1/2">{t("Created_to")} </span>
                            <input data-testid="toDate" id="todate" type="date"
                                value={raisedUpto}
                                defaultValue={defaultTodate}
                                max="9999-12-31"
                                onChange={e => handleDateTo(e.target.value)}
                                className="border-grey text-sm md:text-base p-2 md:p-4 w-2/3"></input>
                        </label>
                    </div>
                </div>
                <div className="w-full ipad-w-full ipad-flex-row sm:px-2 md:w-4/12 lg:w-4/12 flex items-center mb-8 sm:mt-2">
                    <div id="custom-search-input" className="block w-full sm-mr-1 mx-1 mb-1 sm:my-0">
                        <div className="input-group sm-pl-1 w-full">
                            <label className="w-full">
                                <Search className="border border-grey rounded-lg-search w-full"
                                    defaultValue=""
                                    data-testid="search-text-button"
                                    onSubmit={handleSubmit}
                                    placeholder={t("seach_placeholder_txt")}
                                    onClear={handleResetSubmit}
                                />
                            </label>
                        </div>
                    </div>
                    {showFilter ? <FilterComponenent setFilter={handleFilterSubmit} handleClose={handleClose}
                        searchType={"CaseList"} selectedAssociatedType={associatedType} selectedCaseStatusFilter={caseStatusType}
                        selectedPriorityCase={casePriority} customerAssociatedTypeRequired={isCaseListRequiredBasedOnAsset}
                        {...props} /> : null}
                </div>
                <div className="w-full ipad-w-full ipad-flex-row md:w-2/12 lg:w-4/12 flex items-center mb-10">
                    <div className="pr-4 mt-0 px-3">
                        <a className="text-teal no-underline hover:underline cursor-pointer a-style" data-testid="handle-filter" onClick={handelShowFilter}><span className="md:ml-0 md:visible"

                        >{t("filter_txt")}</span> <i className="fas fa-filter"></i></a></div>
                    <div className="pr-4 mt-0">
                        <a className="text-teal no-underline hover:underline cursor-pointer a-style" data-testid="handle-reset"
                            onClick={handleReset}
                        >
                            <span className=" md:visible">{t("reset_txt")}</span>
                            <i className="fas fa-sync pl-1"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-1/2 flex justify-between items-center lg:mb-0 global-txt">
            </div>
            <WebPagination>
                <div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr global-txt" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto">
                                {!caseListData?.cases?.length || !isDateValid || !isSearchValid ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center" data-testid="validationMessages">
                                                {!isDateValid ? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{dateErrorMesage}</p> : (!isSearchValid ? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {validationMessage}
                                                </p> : <p className="w-full text-left my-2 font-AvantGardeGothic-Md"> {t("Cases_no_results")}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left"><strong>{totalCount}</strong> {t("total")}</div>
                                            </div>

                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4 md:justify-end lg:justify-end">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                <select data-testid="webCaseSearchSort"
                                                    value={defaultSortOrder}
                                                    id="case-search-sort1"
                                                    className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                    onChange={handleChangeSortOrder}
                                                    aria-label="case Search"
                                                >
                                                    {CaseSortableFields.map((e, key) => {
                                                        return (
                                                            <option key={key} value={e.sortableKey}>
                                                                {e.displayName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        {caseListData?.cases.map((caseData) => (
                                            !isNullOrUndefined(caseData.caseReferenceNumber) &&
                                            <div className="w-full desk-view-only">
                                                <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                    <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <div className="my-0 text-lg">
                                                                {
                                                                    <a data-testid={"Case360Link" + caseData.caseReferenceNumber} className="my-0 text-lg text-black cursor-pointer" onClick={() => callcase360(caseData)}> <strong>  {`${caseData.caseReferenceNumber}`}</strong>
                                                                    </a>
                                                                }

                                                                </div>
                                                                <div className="my-0 text-base">
                                                               <strong>{`${!isNullOrUndefined(caseData?.entity) ? (caseData.entity === 'Contact' ? t("customer") : (caseData.entity === 'Property' ? formatContent(caseData?.asset?.type?.assetTypeName) : '-')) : '-'}  `}</strong>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                        <div className="mb-2">
                                                            <strong>{t("Date_Creation")}</strong>
                                                            <br></br>
                                                            {formatDateWithFromCalendar(caseData.createdDate || '')}
                                                        </div>

                                                        <div className="mb-2  md:ml-4">
                                                            <strong>{t("CP084")}</strong>
                                                            <br></br>
                                                            {`${formatContent(caseData.caseSubTypeCategory)}`}
                                                        </div>

                                                        <div className="mb-2">
                                                            <strong>{t("CP085")}</strong>
                                                            <br></br>
                                                            {getUpdateOwnerName(caseData?.owner?.name)}
                                                        </div>

                                                        <div className="mb-2">
                                                            <strong>{t("CP086")}</strong>
                                                            <br></br>
                                                            {`${formatContent(caseData.status)}`}
                                                        </div>
                                                        <div className="mb-2 md:ml-4">
                                                            <strong>{t("request_category")}</strong>
                                                            <br></br>
                                                            {`${isNullOrUndefined(caseData.caseCategory) ? t("no_request_category") : caseData.caseCategory}`}
                                                        </div>
                                                    </div>

                                                    <div className="px-4 md:flex md:justify-between">
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="page-mar">
                                            <Paginationbox>
                                                <label>
                                                    <Pagination
                                                        className=" ml-4 "
                                                        colSpan={7}
                                                        count={totalCount}
                                                        page={currentPage}
                                                        rowsPerPage={resultsPerPage}
                                                        rowsPerPageOptions={[10, 20, 100]}
                                                        onPageChange={onChangePage}
                                                        data-testid="casePaginationChange"
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </label>
                                            </Paginationbox>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="div-loader">
                                <div className="SpinnerWrapper">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </WebPagination>
            <MobilePagination>
                <div
                    id="scrollableDiv"
                    data-testid="scrollableDiv" className="mob-view-only"
                    style={{
                        height: 800,
                        overflowY: "scroll",
                        overflow: "auto",
                        flexDirection: "column",
                    }}
                >
                    <InfiniteScroll
                        dataLength={caseListData?.cases?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                        <div className="w-full global-txt">
                            <br></br>
                            <div className="padd-lr global-txt" role="main">
                                {!isLoading ? (
                                    <div className="container mx-auto px-3">
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left"><strong>{totalCount}</strong> {t("Total")}</div>
                                            </div>

                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4 md:justify-end">
                                                <div className="md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                <select data-testid="mobilecaseSearchSort"
                                                    value={defaultSortOrder}
                                                    id="case-search-sort1"
                                                    className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                    onChange={handleChangeSortOrder}
                                                    aria-label="case Search"
                                                >
                                                    {CaseSortableFields.map((e, key) => {
                                                        return (
                                                            <option key={key} value={e.sortableKey}>
                                                                {e.displayName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        {!caseListData?.cases?.length ? (
                                            <div className="w-full mt-4">
                                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                                    <div className="px-4 pr-8">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                                    </div>
                                                    <div className="flex items-center">
                                                        {!isDateValid ? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{dateErrorMesage}</p> : !isSearchValid ? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                            {validationMessage}
                                                        </p> : <p className="w-full text-left my-2 font-AvantGardeGothic-Md"> {t("Cases_no_results")}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            caseListData?.cases.map((caseData) => (!isNullOrUndefined(caseData.caseReferenceNumber) &&
                                                <div className="w-full desk-view-only">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                            <div className="flex flex-row justify-between items-center">
                                                                <div className="my-0 text-lg">
                                                                    {
                                                                        <a data-testid={"MobileCase360Link" + caseData.caseReferenceNumber} className="my-0 text-lg text-black cursor-pointer" onClick={() => callcase360(caseData)}> <strong>  {`${caseData.caseReferenceNumber}`}</strong>
                                                                        </a>
                                                                    }

                                                                </div>
                                                                <div className="my-0 text-base">
                                                                    <strong>{`${!isNullOrUndefined(caseData?.entity) ? (caseData.entity === 'Contact' ? t("customer") : (caseData.entity === 'Property' ? formatContent(caseData?.asset?.type?.assetTypeName) : '-')) : '-'}  `}</strong>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("Date_Creation")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(caseData.createdDate || '')}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("CP084")}</strong>
                                                                <br></br>
                                                                {`${formatContent(caseData.caseSubTypeCategory)}`}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("CP085")}</strong>
                                                                <br></br>
                                                                {getUpdateOwnerName(caseData?.owner?.name)}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("CP086")}</strong>
                                                                <br></br>
                                                                {`${formatContent(caseData.status)}`}
                                                            </div>
                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("request_category")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.caseCategory) ? t("no_request_category") : caseData.caseCategory}`}
                                                            </div>
                                                        </div>

                                                        <div className="px-4 md:flex md:justify-between">
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                ) : (
                                    <div className="div-loader">
                                        <div className="SpinnerWrapper">
                                            <LoadingSpinner />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            </MobilePagination>
        </div>
    )
}

export default CaseListComponent;