import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "@material-ui/core";
import * as api from "../../api";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import RepairReason from "../../compositions/RepairReason/index";
import {
  backToRepairAsset,
  userChosenCurrentValue,
  userChosenHighLevelDescription,
  userChosenRepairLocation,
  userChosenRepairLocationId,
  userChosenNotifyAll,
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps } from "react-router-dom";
import {
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
} from "../../ducks/redux/actions/userChoices";
import { getReferenceNumber, workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { BackValue } from "../../models/shared/Enum";

interface RepairLocationProps extends RouteComponentProps<any> { }

const RepairLocation: React.FC<RepairLocationProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const array: any[] = [];

  const [addresses, setAddresses] = useState(array);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setdisabled] = useState(false);

  const [repairLocation, setRepairLocation] = useState("");
  const [change, setChange] = useState(0);

 
  const selectedAssetType = useSelector(
    (state: any) => state.addresses?.selectedTenancy
  );
  const [isRecallValue, setIsRecallValue] = useState(false);
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenRepairLocationIdBackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );

  const resumeDraftRepair = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const repairAssetCheck = useSelector(
    (state: any) => state.userChoicesReducer.assetRepairCheck
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const workOrders = useSelector(
    (state: any) => state?.userChoicesReducer?.workOrderData
  );
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  useEffect(() => {
    if (IsRecall) {
      setIsRecallValue(true)
    }
    else {
      setIsRecallValue(false)
    }
    if (userChosenRepairLocationIdBackButton) {
      setRepairLocation(userChosenRepairLocationIdBackButton);
      setdisabled(true);
    }
  }, []);

  useEffect(() => {
    if (addresses && EditState!==true) dispatch(userChosenRepairLocation(""));
  }, [addresses]);

  useEffect(() => {
    let referenceDataresponsed;
    (async function () {
      try {
        dispatch(userChosenCurrentValue(null));
        dispatch(getReferenceNumber(null))
        //call reference data api
        
        const namespacenames = assetDetails.assetType;

        const premiseType =
          namespacenames === "Estate"
            ? "estate"
            : namespacenames === "Divided Block" ||
              namespacenames === "Divided block"
              ? "divided-block"
              : namespacenames === "Estate Area"
                ? "estate-area" :
                namespacenames;
        referenceDataresponsed = await api.getReferenceData(
          premiseType as string
        );

        let keyValuePairs: any = "";
        if (referenceDataresponsed) {
          keyValuePairs =
            referenceDataresponsed[0]?.namespaces[0]?.namespace?.keyValues;
          setAddresses(keyValuePairs);
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  }, []);

  let fieldsArray: any[] = [];
  let arrayObject = Object.entries(addresses);
  arrayObject.sort((a, b) => a[1].localeCompare(b[1]));
  arrayObject.map((eachLocation, key) => {
    fieldsArray.push(
      <option
        key={eachLocation[0]}
        className="block mt-1 width-css mt-1 text-black global-txt"
        value={eachLocation[0]}
      >
        {eachLocation[1]}
      </option>
    );
  });

  const backButtonHandler = () => {
    if (!resumeDraftRepair) {
      if (!IsRecall) {
        dispatch(userChosenRepairLocationId(0));
        dispatch(userChosenHighLevelDescription(""));
      }
    }
    if (repairAssetCheck) {
      if (backValue == BackValue.Customer360View) {
        props.history.push("/Customer360View");
      } else if (backValue == BackValue.Asset360View) {
        props.history.push("/Asset360View");
      } else {
        props.history.push(`/WorkOrder360View/${workOrders.identifiers.worksOrderIdentifier}`);

      }
    } else {
      dispatch(backToRepairAsset(true));
      props.history.push("/RepairAsset");
    }
  };

  const handleChange = (e: any) => {
    setdisabled(true);
    if (repairLocation != e.target.value) {
      if (EditState === true) {
        dispatch(userChosenNotifyAll(null)); 
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
        dispatch(userChosenRelationDetails(""));
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(isEditState(false));
        if (!IsRecall) {
          dispatch(userChosenVandalismAdditionalInformation(""));
          dispatch(userChosenVandalismQuestion("No"));
          dispatch(userChosenAdditionalDescription(""));
        }
        dispatch(userChosenDuplicateQuestion(""));
      }
      setRepairLocation(e.target.value);
      dispatch(userChosenRepairLocationId(e.target.value));
      const selectedvalues = addresses[e.target.value];
      dispatch(userChosenRepairLocation(selectedvalues));
      setdisabled(true);
    }
  };

  return (
    <Container id="reploc-container" role="main" className="ctn-fht ">
      <div
        id="RepairLocation-back-div"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="RepairLocation-p" className="text-teal text-xl mt-4 mb-8">
          <a
            className="back-btn"
            data-testid="backButtonHandler"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            {t("back")}
          </a>
        </p>
      </div>
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={7000} //3 secs
        />
      ) : (
        <div
          className="question ml-sm-5 pl-sm-5 repair-text global-txt container"
          id="reploc-main-div"
        >
          <div className="h5" id="reploc-div1">
            <h2 className="text-purple-100 text-2xl mb-2 global-txt ">
              {t("repair_location_label")}
            </h2>
          </div>
          <select
            id="reploc-sel"
            data-testid="dropDownLocation"
            name="dropDownLocation"
            placeholder="dropDownLocation"
            value={repairLocation}
            aria-label="reploc-sel"
            disabled={isRecallValue}
            onChange={handleChange}
            className="block width-css mt-1 text-black global-txt rounded border-grey"
          >
            <option
              value="No option selected"
              className="block mt-1 width-css mt-8 text-black global-txt"
              disabled={disabled}
            >
              Select
            </option>
            {fieldsArray}
          </select>
        </div>
      )}

      {<RepairReason {...props} />}
    </Container>
  );
};

export default RepairLocation;
