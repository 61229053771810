import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import { CatalistTriageContractorType } from "../../models/Repair/Contractor";

import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  backToRepairAsset,
  loadDuplicateRepairPage,
  userChosenResumeRepair,
  userCreatedNewRepair,
  workOrderData,
} from "../../ducks/redux/actions/userChoices";

import {
	getHeatingHotWaterCase,
	getMaintResp,
	getRepairResp,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { userTypedRepairReasons } from "../../ducks/redux/actions/dashboardReducer.actions";
import { BackValue } from "../../models/shared/Enum";
import { isNullOrUndefined } from "../../utils/formatting";
import * as api from "../../api";
import Loader from "react-loader-spinner";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";

interface NoAppointmentProps extends RouteComponentProps<any> { }
const NoAppointment: React.FC<NoAppointmentProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [appointmentMsg, setAppointmentMsg] = useState("");
  const [confirmationAppointmentMsg, setAppointmentConfirmationMsg] = useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  let [jobNo, setJobNo] = useState("");

  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  useEffect(() => {
    setAppointmentMessages();

    if (contractorname === "ContractorSiteOnHold") {
      setIsLoading(false)
    }
    else if (isNullOrUndefined(referenceNumberCode)) {
      getJobNo();
    }
    else {
      setJobNo(referenceNumberCode)
      setIsLoading(false)
    }
  }, []);

  let workOrderId = "";
  const getJobNo = async () => {
    const noOfHits = process.env.REACT_APP_API_HITS;
    const timeoutValue = process.env.REACT_APP_API_TIMEOUT;
    const noOfHitsValue = Number(noOfHits);
    const noOfTimeoutValue = Number(timeoutValue);
    let i = 0;
    let woJobNumber = workOrderObject.isRecall ? "" : workOrderObject?.jobNo ?? "";
    let woId = workOrderObject?.id ?? "";
    setJobNo(woJobNumber);

    if(isNullOrUndefined(woId)) {
      console.log("Missing WO Id");
      const timerId = setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    }

    let interval = setInterval(async () => {
      if (i < noOfHitsValue && (isNullOrUndefined(woJobNumber) || woJobNumber == "")&& !isNullOrUndefined(woId)) {
        i++;
        try {
          const repair = await api.getRepair(woId);
            woJobNumber = repair?.jobNo ?? "";
            if(woJobNumber.length > 0) {
              setJobNo(woJobNumber);
              setIsLoading(false);
              clearInterval(interval);
            }
        }
        catch (e) {
          console.log(e);
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        }
      }
      else {
        clearInterval(interval);
        if (isNullOrUndefined(woJobNumber) || woJobNumber == "") {
          console.log("Job Number not created");
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        } else {
          setIsLoading(false);
        }
      }
    }, noOfTimeoutValue);
    return () => clearInterval(interval);
  }
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ??
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );

  const contractorname = useSelector(
    (state: any) => state.addresses?.contractorname
  );

  const contractorPhoneNo = useSelector(
    (state: any) => state.addresses?.contractorname
  );

  const getEnhancedHubRepairPermissions = useSelector(
    (state: any) => state?.userChoicesReducer?.createEnhancedHubRepairPermissions
  );

  const callPageHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    //dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(backToRepairAsset(false))
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);
  };
  const callfinishcreatePageHandler = () => {
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    props.history.push("/RepairAsset");
  };


  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  let emergencyText = "";

  const setAppointmentMessages = () => {
    const worksOrder = new WorksOrderRequest(workOrderObject);
    if (contractorname === 'Thamesmead Environmental Services') {
      setAppointmentMsg(t("SSR035"));
      emergencyText = "";
      return;
    }
    if (contractorname === "ContractorSiteOnHold") {
      setAppointmentMsg(t("on_hold_final_msg"));
      emergencyText = "";
      return;
    }

    
    if (worksOrder?.statuses?.isOutOfHours && getEnhancedHubRepairPermissions) {
      setAppointmentMessagesOOHAR();
      return;
    }

    if (assetDetails.assetType === "Property") {
      handlePropertyAppointment();
    } else {
      handleCommunalAppointment();
    }
  };

  const handlePropertyAppointment = () => {
    //922 priority === "Emergency" || priority === "Emergency (OOH)")
    if (userChosenEmergencyQuestion === "Yes, access can be provided" || userChosenEmergencyQuestion === "Yes") {
      setAppointmentMsg(t("CPR066"));
      emergencyText = t("Emergency_Value");
    } else {
      // user selected No option // 925
      setAppointmentMsg(t("SSR035"));
      emergencyText = "";
    }
  };

  const handleCommunalAppointment = () => {
    //communal
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      setAppointmentMsg(t("CPR067"));
      emergencyText = t("Emergency_Value");
    } //925 communal
    else {
      setAppointmentMsg(t("SSR034"));
      emergencyText = "";
    }
  };

  const setAppointmentMessagesOOHAR = () => {
    const phoneNumber = contractorPhoneNo[0]?.number;
    setAppointmentMsg(t("AR1ConfirmationOOHRepairMessage1"));
    let msg = t("AR1ConfirmationOOHRepairMessage2");
    setAppointmentConfirmationMsg(msg.replace("{0}", contractorname).replace("{1}", phoneNumber));
  };

  return (
    <div>
      {isLoading ? (
        <div className="container sm:px-2 height-container">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
          //timeout={25000}
          />
          <p className="global-txt mt-4">{t("CPR052")}</p>
        </div>
      ) : (
        <div className="container sm:px-2 height-container" id="noAppoint-container" role="main">
          <div id="noAppoint-main-div" className="h5 my-3 repair-text">
            {priority === "Emergency" || priority === "Emergency (OOH)" ?
              (
                <h2 id="noAppoint-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("Repair_Appointment_Question")}</h2>
              ) : (
                <h2 id="noAppoint-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("CPR056")}</h2>
              )}
          </div>
          <div id="noAppoint-div1" className=" repair-text global-txt">
            <p id="noAppoint-p">{appointmentMsg}</p>
            <p id="noAppoint-p2">{confirmationAppointmentMsg}</p>
          </div>

          {contractorname !== "ContractorSiteOnHold" &&   
          <>
             <div
            id="noAppoint-div2"
            className="border border-grey rounded-lg mar-left mb-4 global-txt py-4"
          >
            <div id="noAppoint-div1" className="repair-text global-txt">
              <span id="noAppoint-p" className="pl-2">{t("Appointment_Confirm_Reference")}</span><br />
              <strong className="pl-4">{jobNo}</strong>
            </div>
            <br />
            <span id="noAppoint-span1" className="pl-2">{t("Repair_Appointment_Text2")}</span>
            <br />
            <span id="noAppoint-span2" className="">
              <span className="">{emergencyText}
                <strong className="pl">{contractorname}</strong></span>
            </span>
          </div>
          </>
}
        </div>
      )}
       <div
            id="noAppoint-main-btn"
            className="container button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-3 btn-left"
          >
            <a
              id="noAppoint-btn1"
              data-testid="finishandcreate"
              onClick={callfinishcreatePageHandler}
              className="finish"
            >
              <span className="mr-2 ml-0 global-txt cursor-pointer sm:ml-neg">{t("finishandcreate_button")}</span>

            </a>
            <Button
              id="btn_finish_noAppoint"
              data-testid="finshrepair"
              onClick={callPageHandler}
              className="button-class sm:mt-4"
            >
              {t("finish_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
    </div>
  );
};
export default NoAppointment;
