import { Container, FormControlLabel } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  backToRepairAsset,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  workOrderData,
} from "../../ducks/redux/actions/userChoices";
import {
	getHeatingHotWaterCase,
	getMaintResp,
	getRepairResp,
  workOrderRepairObject, saveAvailabilityResponse, repairHistoryData,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { userTypedRepairReasons } from "../../ducks/redux/actions/dashboardReducer.actions";
import { BackValue } from "../../models/shared/Enum";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";

interface NoSuitableAppointmentConfirmationProps extends RouteComponentProps<any> { }
const NoSuitableAppointmentConfirmation: React.FC<NoSuitableAppointmentConfirmationProps> = (props) => {
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [appointmentError, setAppointmentError] = useState("");
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  useEffect(() => {
    if (customerID === null || customerID === undefined || customerID === "") {
      props.history.push("/startpage");
      return;
    }
    setAppointmentMessages();
  }, []);



  const contractorname = useSelector(
    (state: any) => state.addresses?.contractorname
  );
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  
  const callPageHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(backToRepairAsset(false))
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
    const worksOrder = new WorksOrderRequest(workOrderObject);
      dispatch(workOrderData(worksOrder))
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

  };
  const callfinishcreatePageHandler = () => {
    dispatch(workOrderRepairObject(null));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
    props.history.push("/RepairAsset");
  };

  const setAppointmentMessages = () => {
    setAppointmentError(t("SSR035"));
  }

  return (
    <div id="noAppoint-container"  role="main" className="container sm:px-2 ctn-fht">
      <div id="noAppoint-main-div" className="py-2 my-3 repair-text">
      <h2 id="noAppoint-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("CPR056")}</h2> 
          </div>
        <div id="noAppoint-div1" className="repair-text global-txt">
          <p id="noAppoint-p">{appointmentError}</p>
        </div>

     <div className="repair-text">
          <p className="mt-4">
            {t("Appointment_Confirm_Reference")} <br></br><strong>{referenceNumberCode}{" "}</strong>
          </p>
          <p>
            {t("Appointment_Confirm_Assign")} <br></br> <strong>{contractorname}</strong>
          </p>
        </div>
   
      <div
        id="noAppoint-main-btn"
        className="button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-3 btn-left"
      >
        <a
          id="btn_noAppoint_finishcreate"
          data-testid="finishandcreate"
          onClick={callfinishcreatePageHandler}
          className=" finish sm:ml-no-suit"
        >
          <span className=" ml-a0  global-txt cursor-pointer">{t("finishcreate_button")} {" >"}</span>
         
        </a>
       
        <Button
          id="btn_noAppoint_finish"
          data-testid="finshrepair"
          onClick={callPageHandler}
          className="button-class mt-2"
        >
          {t("finish_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </div>
  );
};
export default NoSuitableAppointmentConfirmation;
