import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { Container, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import {
  userChosenSupplementaryQuestions,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  backToRepairAsset,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as api from "../../api";
import { CurrentRepair } from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Address from "../../models/Customer/Address";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import CustomerName from "../../models/Customer/CustomerName"
import Button from "../../Button/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { BackValue } from "../../models/shared/Enum";
import { RepairPriority } from "../../models/Repair/CreateRepair";

interface SupplementaryQuestionProps extends RouteComponentProps<any> { }
const SupplementaryQuestion: React.FC<SupplementaryQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState("");
  const [isDisabled, setButtonDisabled] = useState(false);
  const [HLRepair, setHLRepair] = useState("");
  const [subQuestion, setSubQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<any>();
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  const selectedRepairReasonObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject
  );
  const userChosenSORValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const userChosenSupplementaryQuestionsVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSupplementaryQuestions
  );
  
  const assetId = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.customerID?.assetId
  );
  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
  const userChosenRepairLocationId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? 
        state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const tenanciesData = useSelector(
    (state: any) => state.addresses.customertenancies
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDataVal = useSelector((state: any) => state.userChoicesReducer.userResumeData);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);

  useEffect(() => {
    if (props.history.action === "POP") {
      setRadioValue(userChosenSupplementaryQuestionsVal);
      setButtonDisabled(false);
    }

    if (resumeDraftRepairVal) {
      if (userResumeDataVal?.resumeHLRepair) {
        setHLRepair(userResumeDataVal?.resumeHLRepair);
        setSubQuestion(userResumeDataVal?.resumeHLSubQuestion);
        userChosenSupplementaryQuestionsVal === 'Yes' ? setRadioValue('Yes') :
          userChosenSupplementaryQuestionsVal === 'No' ? setRadioValue('No') :
            setRadioValue('');
      } else {
        props.history.replace("/RepairEmergency");
      }
    } else {
      if (
        selectedRepairReasonObject[0]?.additionalData &&
        selectedRepairReasonObject[0]?.additionalData.length &&
        selectedRepairReasonObject[0]?.additionalData[0]?.description != ""
      ) {
        setHLRepair(selectedRepairReasonObject[0]?.description);
        setSubQuestion(
          selectedRepairReasonObject[0]?.additionalData[0]?.description
        );
        userChosenSupplementaryQuestionsVal === 'Yes' ? setRadioValue('Yes') :
          userChosenSupplementaryQuestionsVal === 'No' ? setRadioValue('No') :
            setRadioValue('');
      } else {
        props.history.replace("/RepairEmergency");
      }
    }
  }, []);

  let emergencyState = false;
  if (assetDetails.assetType === "Property" && priority === "Emergency") {
    if (userChosenEmergencyQuestionVal === "Yes, access can be provided" || userChosenEmergencyQuestionVal === "Yes") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  } else {
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  }

  const workOrderRepair = new CurrentRepair();

  const _premisesTypeId: any =
    PremisesTypeId[assetDetails.assetType as keyof typeof PremisesTypeId];
  workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
  workOrderRepair.description = selectedRepairReasonObject[0]?.description;

  workOrderRepair.premisesId = assetId;
  if (assetDetails.assetType === "Divided Block") {
    workOrderRepair.premisesTypeId = "5";
  } else {
    workOrderRepair.premisesTypeId = _premisesTypeId;
  }

  workOrderRepair.isEmergency =  priority==RepairPriority.Emergency || priority =="Yes" ? true : false;;
  workOrderRepair.premisesUniqueReference = assetDetails.uprn;
  const _address = new Address();
  _address.addressLine1 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
  _address.addressLine2 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
  _address.addressLine3 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
  workOrderRepair.premisesAddress = _address;

  workOrderRepair.sorCode = userChosenSORValue?.length > 0 ? userChosenSORValue :
    selectedRepairReasonObject[0]?.sors !== undefined ? selectedRepairReasonObject[0]?.sors[0]?.sorCode :
    ""
    
  workOrderRepair.customerName = new CustomerName({
    title: customerDetails?.person?.name.title,
    givenName: customerDetails?.person?.name.givenName,
    surname: customerDetails?.person?.name.surname,
  });

  workOrderRepair.notifyAll = false;
  workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
  workOrderRepair.eligibilityOutcomes = [];
  workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
  workOrderRepair.repairLocationId = userChosenRepairLocationId;
  const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
  const woRequestParameters =
    _worksOrderRequest.createWorkOrderParameter(workOrderRepair);

  const CreateWorkOrder = async () => {
    try {
      setIsLoading(true);
      if (radioValue === "Yes") {
        woRequestParameters.isSupplementary = true;
        workOrderObject.isSupplementary = true;
      } else if (radioValue === "No") {
        woRequestParameters.isSupplementary = false;
        workOrderObject.isSupplementary = false;
      } 
      const apiResponse = await api.createWorkOrderRepair({
        ...woRequestParameters,
      });
      dispatch(workOrderRepairObject(workOrderRepair));
      if (apiResponse?.location.length > 0) {
        const workorderLocation = apiResponse?.location.split("/");
        workOrderRepair.id = workorderLocation[4];
        workOrderRepair.worksOrderIdentifier = workorderLocation[4];
        dispatch(workOrderRepairObject(workOrderRepair));
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
        setTimeout(() => {
            props.history.push("/GenericError");
        }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const UpdateWorkOrder = async () => {
    try {
      setIsLoading(true);
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.repairReason =
        selectedRepairReasonObject[0]?.description;
      woRequestParameters.description =
        selectedRepairReasonObject[0]?.description;
      if (radioValue === "Yes") {
        woRequestParameters.isSupplementary = true;
        workOrderObject.isSupplementary = true;
      } else if (radioValue === "No") {
        woRequestParameters.isSupplementary = false;
        workOrderObject.isSupplementary = false;
      }
      
      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      workOrderObject.repairReason = selectedRepairReasonObject[0]?.description;

      workOrderObject.description = selectedRepairReasonObject[0]?.description;
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
        setTimeout(() => {
            props.history.push("/GenericError");
        }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  const backButtonHandler = () => {
    props.history.push("/RepairLocation");
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    if (radioValue === "") {
      dispatch(backToRepairAsset(false))
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

      setTimeout(() => {
        dispatch(userChosenSaveExit(false))
      }, 6000);
    }

    setCloseAfterSaving(true);
    workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();

    dispatch(backToRepairAsset(false))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);
    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { supplementaryQuestions } = userResumeDraftDataCache;
      if (supplementaryQuestions !== radioValue) {
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(userChosenDuplicateQuestion(""));
        dispatch(userChosenAdditionalDescription(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (radioValue === "") {
      setErrorMessage(
        <div data-testid="alert-warning" className="error mb-8" role="alert"
          placeholder="alert">
          <div className="w-full px-2 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("CPR010")}</p>
            </div>
          </div>
        </div>
      );
      return;
    }

    if (!resumeDraftRepairVal) {
      workOrderObject == null ? CreateWorkOrder() : UpdateWorkOrder();
    }
    props.history.push("/RepairEmergency");
  };

  const handleRadioChange = (event: any) => {
    setRadioValue(event.target.value);
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      // dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      if (!IsRecall) {
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      }
      dispatch(userChosenDuplicateQuestion(""));
    }
    if (event.target.value === "No") {
      setErrorMessage(
        <div data-testid="alert-warning" className="warning mb-8 mt-4" role="alert" placeholder="alertNo">
          <div className="w-full px-2 py-2 bg-red-20 flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("CPR009")}</p>
            </div>
          </div>
        </div>
      );
      setButtonDisabled(true);
    } else if (event.target.value === "Yes") {
      setErrorMessage("");
      setButtonDisabled(false);
    }
    dispatch(userChosenSupplementaryQuestions(event.target.value));
  };

  return (
    <Container id="supp-main-container" role="main" className="ctn-fht ">
      {/* <BackModalContent id="supp-main-backmodal" back={backButtonHandler} aria-label="backmodal" /> */}
      <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >		
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 md:mb-12">
          <a className="back-btn" onClick={backButtonHandler}> 
            <i className="fas fa-angle-left mr-2"></i><span>{t("back")}</span>
          </a>
        </p>		
      </div> 
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={7000} //3 secs
        />
      ) : (
        <>
          <div
            id="supp-main-div"
            className="question ml-sm-5 pl-sm-5 container"
          >
            <div id="supp-div1" className="py-0">
              <fieldset>
                <legend>
                  <h2 id="supp-h1" className="text-purple-100 text-2xl mb-2 global-txt pt-0 mt-0">{t(HLRepair)}</h2>
                  <p id="supp-p1" className="text-black text-base global-txt global-font">
                    {subQuestion}
                  </p>
                </legend>
                <RadioGroup
                  id="supp-radiogroup"
                  role="radiogroup"
                  name="radio-buttons-group"
                  defaultValue={radioValue}
                  className="margin-radipgroup"
                  value={radioValue}
                  data-testid="sureradioValue"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    className="text-black mt-0"
                    value="Yes"
                    placeholder="Yes"
                    data-testid="yesRadio"
                    control={<Radio className="radio-yes" id="yes-radio" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    className="text-black mt-4"
                    value="No"
                    placeholder="No"
                    data-testid="noRadio"
                    control={<Radio className="ml-0 radio-yes" id="no-radio" />}
                    label="No"
                  />
                </RadioGroup>
              </fieldset>
            </div>
            {errorMessage}
          </div>
          <div
            className="flex justify-between items-center pb-4 md:pb-8 container mt-8"
            id="supp-main-btn"
          >
            <ExitModalAndSave
              id="supp-exitmodal"
              exit={exitButtonHandler}
              saveDraftAndExit={saveDraftAndExit}
              closeAfterSaving={closeAfterSaving}
            />
            <Button
              id="supp-btn1"
              onClick={callPageHandler}
              disabled={isDisabled}
              className="button-class"
            >
              {t("continue")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default SupplementaryQuestion;
