import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import { selectedTenacyType } from "../../../ducks/redux/actions/getAddress";
import {
  userChosenPropertyType,
  userChosenUPRN,
  repairAddressData,
  backToCaseAsset,
  caseAssetAddress,
  userChosenPropertyId,
  caseCreatedBy,
} from "../../../ducks/redux/actions/userChoices";
import { updateDashboardForm } from "../../../ducks/redux/actions/dashboardReducer.actions";
import houseIcon from "../../../assets/icons/my-home.png";
import blockIcon from "../../../assets/icons/my-block.png";
import estateIcon from "../../../assets/icons/estate.png";
import dividedBlockIcon from "../../../assets/icons/divided-block.png";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../../Button/Button";
import ExitModalContent from "../../Modal/ExitModalContent";
import { BackValue, SearchType } from "../../../models/shared/Enum";
import { CASE_ASSET_CHECK, CASE_ASSET_TYPE } from "../../../ducks/redux/actionTypes";

interface CaseAssetProps extends RouteComponentProps<any> {}

const CaseAsset: React.FC<CaseAssetProps> = (props) => {
  const BlockMap = {
    Property: "Property",
    "Divided Block": "Divided Block",
    Block: "Block",
    Estate: "Estate",
    "Estate Area": "Estate",
  };
  const [isLoading, setIsLoading] = useState(true);
  let tenanciesId = "";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<any>();
  const [buttondisable, setButtonDisable] = useState(false);
  const [propertyIdChosen, setPropertyIdChosen] = useState("");
  
  const customerTenancy = (state: any) => state?.addresses?.customertenancies;
  const customerTenanciesData = useSelector(customerTenancy);
  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  const addressesData = useSelector(assets);
  const tenancies = (state: any) => state?.addresses?.saveAssociatedAssests;
  const tenanciesData = useSelector(tenancies);
  if (tenanciesData != null) {
    tenanciesId = tenanciesData[0]?.tenancySummary?.tenancyId;
  }
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const premisesid = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetId
  );
  
  const backToRepair = useSelector(
    (state: any) => state?.userChoicesReducer?.backToCaseAsset
  );
  const repairAssetData = useSelector(
    (state: any) => state?.userChoicesReducer?.repairAddressData
  );
  const editButtonCheck = useSelector(
    (state: any) => state?.caseCreationReducer?.editButtonClick
  );
  const [showError, setShowError] = useState("");
  const getAssetIcon = (assetType: any) => {
    var icon = houseIcon;
    if (assetType == "Property") icon = houseIcon;
    if (assetType == "Block") icon = blockIcon;
    if (assetType == "Divided Block") icon = dividedBlockIcon;
    if (assetType.includes("Estate")) icon = estateIcon;
    return icon;
  };
  //To track div selection for RepairProperty--Start here---
  const [assetTypeValue, setAssetTypeValue] = useState("");
  //-----------Ends here-----------//

  useEffect(() => {
    window.scrollTo(0,0);
    
    (async function () {
      try {
        if (backToRepair || editButtonCheck) {
          dispatch(backToCaseAsset(false))
          const propertyCheck = repairAssetData[0].filter(
            (x: any) => x.id == premisesid
          );
          setAddresses(repairAssetData);
          const schemeCheck = repairAssetData[0].filter(
            (x: { assetType: string; id: any }) => x.assetType == "Scheme"
          );
          
          if (propertyCheck.length > 0) {
            callSelection(
              propertyCheck[0].assetType,
              propertyCheck[0].id,
              propertyCheck[0].uprn,
              ""
            );
          }
        }
         else {
          if (
            addressesData[0].length > 1 ||
            addressesData[0][0].assetType === "Property"
          ) {
            
           
              dispatch(repairAddressData(addressesData));

              const schemeCheck = addressesData[0].filter(
                (x: { assetType: string; id: any }) => x.assetType == "Scheme"
              );
             
              setAddresses(addressesData);
            
          }
        
      } }catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const backButtonHandler = () => {
    dispatch(backToCaseAsset(false));
    if (customerTenanciesData?.length > 1) {
      props.history.push("/SelectTenancy");
    } else {
      if (backValue == BackValue.Customer360View) {
        props.history.push("/Customer360View");
      } else if (backValue == BackValue.Asset360View) {
        props.history.push("/Asset360View");
      } else if(backValue == BackValue.WorkOrder360View) {
        props.history.push("/WorkOrder360View");
      }else{
        props.history.push("/Case360View");
      }
    }
  };

  const exitButtonHandler = () => {
    dispatch(backToCaseAsset(false));
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };
  const callPageHandler = () => {
    if(propertyIdChosen){
    dispatch({type: CASE_ASSET_CHECK, payload: true})
    dispatch(backToCaseAsset(false));
    props.history.push("/CaseType");
    }
    else{
      setShowError(t("OPC034"));
    }
  };

  const getValue = (assetType: string, assetId: string, uprn: string) => {
    setAssetTypeValue(assetType);
    if (customerID) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    dispatch(selectedTenacyType(assetType));
   
    if (backValue === BackValue.Customer360View) {
      dispatch(caseCreatedBy(SearchType.Customer));
      }
    else{
      dispatch(caseCreatedBy(assetType));
    }
    sessionStorage.setItem("userChosenPropertyType", assetType);
  
    dispatch(
      updateDashboardForm({
        assetType,
        assetId,
        customerID,
      })
    );
  };
  const updateSelection = (
    assetType: string,
    assetId: string,
    uprn: string,
    formattedAddress: string
  ) => {
    setShowError("");
    dispatch(caseAssetAddress(formattedAddress));
    setPropertyIdChosen(assetId);
    getValue(assetType, assetId, uprn);
    setButtonDisable(false);
    dispatch({ type: CASE_ASSET_TYPE, payload: assetType });
    dispatch(userChosenPropertyId(assetId))
  };
  const callSelection = (assetType: string, assetId: string, uprn: string, address: string) => {
    dispatch(caseAssetAddress(address));
    setPropertyIdChosen(assetId);
    getValue(assetType, assetId, uprn);
    setButtonDisable(false);
    dispatch({ type: CASE_ASSET_TYPE, payload: assetType });
  };

  let _className = "card-body1 bg-white shadow-lg1 rounded-lg";
  let fieldsArray: any[] = [];
  addresses?.forEach((element: any) => {
    element?.length > 0 &&
      element.forEach((item: any) => {
        if (item.assetType != "Scheme") {
          _className =
            item.assetType == assetTypeValue && item.id === propertyIdChosen
              ? "border-purple-100 card-body1 bg-white shadow-lg1 rounded-lg min-ht"
              : "card-body1 bg-white shadow-lg1 rounded-lg min-ht";
          fieldsArray.push(
            <div
              data-testid={item.assetType}
              className="mb-4 asset-panels margin-rt gap-4 mb-4 asset-panel border bg-white shadow-lg rounded-lg"
              id={"case-asset-main-div" + item.id}
            >
              <div
                data-testid={"updateSelect" + item.assetType}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  updateSelection(
                    item.assetType,
                    item.id,
                    item.uprn,
                    item.addresses?.postalAddresses[0]?.formattedAddress
                  );
                }}
              >
                <div
                  className="card-margin-new"
                  id={"case-asset-div1" + item.id}
                >
                  <div className={_className} id={"case-asset-div2" + item.id}>
                    <div
                      className="d-flex px-6 py-2 bg-peach rounded-t-lg flex items-center bg-peach-radius"
                      id={"case-asset-div3" + item.id}
                    >
                      <div
                        className="ic-user-new flex"
                        id={"case-asset-div4" + item.id}
                      >
                        <img
                          src={getAssetIcon(item.assetType)}
                          alt=""
                          className="me-2 my-0 text-lg"
                        ></img>
                      </div>
                      <h2 className="card-text my-0 text-lg" id={item.id}>
                        {/* @ts-ignore */}
                        {BlockMap[item.assetType]}
                      </h2>
                    </div>
                    <p
                      className="card-text px-6 pt-3 global-txt m-0"
                      id={"case-asset-p" + item.id}
                    >
                      {item.addresses?.postalAddresses[0]?.formattedAddress
                        .split(",")
                        .map((step: any, i: number) => (
                          <>
                            {i === 0 ? (
                              <strong className="py-2 my-2">{step}</strong>
                            ) : (
                              <p className="py-0 my-0">{step}</p>
                            )}
                            {"\n "}
                          </>
                        ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });
  });

  return (
    <Container id="back-modal-container" role="main" className="ctn-fht">
      <div
        id="ReapirAsset-back-div"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="ReapirAsset-p" className="text-teal text-xl mt-4 mb-8">
          <a
            className="back-btn"
            data-testid="backButtonHandler"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            {t("back")}
          </a>
        </p>
      </div>
      <div className="container">
        <div id="case-asset-divs" className="mb-4 case-text">
          <h2
            id="case-asset-h1"
            className="text-purple-100 text-2xl mb-8 global-txt"
          >
            {t("OPC033")}
          </h2>
        </div>
        <div className="lg:min-h-80">
          <div
            className="asset-panels grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 md:gap-8 mb-4 "
            id="options"
          >
            {fieldsArray}
          </div>
        </div>
      </div>
      {showError && (
              <div
                data-testid="alert-warning"
                className="error mb-8 sm:mx-1"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      {" "}
                      {t(showError)}
                    </p>
                  </div>
                </div>
              </div>
            )}
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={5000}
        />
      ) : null}
      <div
        className="flex justify-between items-center pb-4 md:pb-8 container"
        id="case-asset-btn"
      >
        <ExitModalContent
          id="case-asset-exitmodal"
          exit={exitButtonHandler}
          contentMessage="Case_OPC001"
          btn_msg="Case_Return_Button"
        />
        <Button
          id="btn_caseasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="button-class"
        >
          {t("continue")}{" "}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};
export default CaseAsset;
