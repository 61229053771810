import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  userTypedRepairReasons,
  selectedRepairReasonObject,
  selectedSorCode,
  sorErrorCode,
  selectedRepairReasonPriorityOverride
} from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  userChosenPropertyId,
  userChosenHighLevelDescription,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenUploadedMediaIdentifiers,
  clearMediaFiles,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import { PremisesType } from "../../models/Customer/Premises";
import * as api from "../../api";
import { useTranslation } from "react-i18next";
import {
  EligibilityErrorCode,
  MaintenanceResponsibility,
} from "../../models/Property";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import {
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { SorErrorEnumCode } from "../../models/Repair/SorErrorEnumCode";
import { isNullOrUndefined } from "../../utils/formatting";
import { RepairPriority } from "../../models/Repair/CreateRepair";
import { PermissionsList } from "../../models/Permissions";

export interface RepairReasonProps extends RouteComponentProps<any> {
  suggestions: string[];
  setButtonDisabled: (a: boolean) => void;
  setButtonDisabledOrbis: (a: boolean) => void;
  locatioAlert: string[];
  getMandatoryData: (args: any) => void;

}
const RepairReason: React.FC<RepairReasonProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let suggestionList;
  const [eligible, setEligible] = React.useState("none");
  const [errorCode, setErrorCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userInput, setUserInput] = useState();
  const [inputValue, setInputValue] = useState("");

  const [isSelectedValue, setSelectedValue] = React.useState(false);
  const [sorError, setSorError] = useState("");
  const [sorList, setSorList] = useState([{}]);
  const [sorValue, setSorValue] = useState("");
  const [isOrbisBlockMsg, setIsOrbisBlockMsg] = React.useState(false);
  const [blockLoading,setBlockLoading] =useState(false);
  const [seconds,setSeconds] = useState(30);
  

  const [emergencyAlertText,setEmergencyAlertText] = useState("RepairLocation_No_Emergency_Text");

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const extractedReasons: string[] = [];
  const [fetchedRepairReasonsData, setData] = useState(extractedReasons);

  //Check Repair Eligibility
  let premisesType = "";
  let maintsResp = "FMFULL";

  let repairResp = "";
  let additionalRepairInfo = false; //!!asset?.description //??
  let isRepairEligible = true;
  let isCustomerView = false; // to do discuss
  let uprn = ""; // asset store - filter
  let sorCode: string | null = "";
  let isInDefects = false; //got
  let heatingRepairContext: string | null = "";
  let sorErrorCodeValue = "";

  const [defaultEmergencyValue, setDefaultEmergencyValue] = useState("No")

  const selectedSor = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.selectedSorCode
  );
  const sorErrorValue = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.sorErrorCode
  );
  const selectedSorCodeValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenSOR
  );
  const premisesid = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetId
  );
  const [isRecallValue, setIsRecallValue] = useState(false);
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  //fetch typed object
  const fetchRepairReasons = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.fetchRepairReasons
  );
  const addressesData = useSelector(
    (state: any) => state?.addresses?.customerassociatedassets
  );

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  let workorderChoosePriorityOfRepairPermission = getPermissionsData?.indexOf(PermissionsList.workorder_choose_priority_of_repair) >= 0;
  let workorderNoTriageForHHWRepairsPremission = getPermissionsData?.indexOf(PermissionsList.workorder_no_triage_forheatingandhotwaterrepairs) >= 0;

  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.fetchedRepairReasonsSelect
  );
  const userChosenHighLevelDescriptionBackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenHighLevelDescription
  );

  const RepairLocationId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );
  const repairAssetData = useSelector(
    (state: any) => state?.userChoicesReducer?.repairAddressData
  );

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ??
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );

  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  const fetchRepairReasonsInProgressFromState = useSelector(
    (state: any) => state.dashboardReducer.formState?.fetchRepairReasonsInProgress
  );
  const selTenancyGroup = useSelector(
    (state: any) => state?.userChoicesReducer?.selectedTenancyGroup
  );

  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  useEffect(() => {
    //set isEmergency ddl value in case od Edit
    if(priority == RepairPriority.Emergency)
    { setDefaultEmergencyValue("Yes")}
    else   { setDefaultEmergencyValue("No")}

    if (IsRecall) {
      setIsRecallValue(true)
    }
    else {
      setIsRecallValue(false)
    }
    if (
      userChosenHighLevelDescriptionBackButton == "Heating or hot water repair"
    ) {
      const timer = setInterval(() => {
        if(seconds >0) {
          setSeconds(prevSeconds => prevSeconds -1);
        }
      },3000);
      if(isNullOrUndefined(sorErrorValue)){
        updateStoreForNewRepairReasonSelection(userChosenHighLevelDescriptionBackButton);
        setSorValue(selectedSorCodeValue);
      }
      else{
      setSorValue(selectedSorCodeValue);
      setSorList(selectedSor);
      setSorError(sorErrorValue); 
      }
    }
    if (props.history.action === "POP") {
      setInputValue(userChosenHighLevelDescriptionBackButton);
      if (
        sorErrorValue == SorErrorEnumCode.E28 ||
        sorErrorValue == SorErrorEnumCode.E29 ||
        sorErrorValue == SorErrorEnumCode.E30 ||
        sorErrorValue == SorErrorEnumCode.E31 ||
        (sorErrorValue == SorErrorEnumCode.E32 &&
          (isNullOrUndefined(selectedSorCodeValue) ||
            selectedSorCodeValue == "Select"))
      ) {
        props.setButtonDisabled(true);
      } else {
        setIsLoading(false)
        props.setButtonDisabled(false);
      }
    }
  }, []);
  
  

  useEffect(() => {
    if (userChosenHighLevelDescriptionBackButton) {
      setInputValue(userChosenHighLevelDescriptionBackButton);
      setDefaultEmergencyValue(userChosenEmergencyQuestionVal);
    }
  }, []);

  
  useEffect(() => {
    setRepairPriorityMessageForEnhancedHubRepairJourney(workorderChoosePriorityOfRepairPermission,defaultEmergencyValue);
  }, [defaultEmergencyValue]);

  const enableButtonPermission = () => {
    if (sorError == SorErrorEnumCode.E32 &&  !workorderNoTriageForHHWRepairsPremission ) {
      // Colleague isnt allowed to provide heating element details
      dispatch(userChosenSOR("NA"));
      props.setButtonDisabled(false);
      setSorValue("NA");
    }
  }

  const setRepairPriorityMessageForEnhancedHubRepairJourney = (workorderChoosePriorityOfRepairPermission: any,priority:any) => {
    (async () => {
      if(workorderChoosePriorityOfRepairPermission){
        if(priority === "Yes"){
          setEmergencyAlertText("RepairLocation_Emergency_Text");
        }else{
          setEmergencyAlertText("RepairLocation_No_Emergency_Text");
        }
  }    
    })()
  };

  
  useEffect(() => {
    setErrorCode("")
    props.getMandatoryData(inputValue);
  }, [inputValue]);
  const handleChangeSorCode = (e: { target: { value: any } }) => {
    if(e.target.value !== "Select"){
      dispatch(userChosenSOR(e.target.value));
      setSorValue(e.target.value);
      props.setButtonDisabled(false);
    } else {
      dispatch(userChosenSOR("NA"));
      props.setButtonDisabled(true);
      setSorValue(e.target.value);
    
    }
  };
  const updateStoreForNewRepairReasonSelection = async (value: string) => {
    try {
      setIsLoading(true);
      setIsOrbisBlockMsg(false);
      props.setButtonDisabledOrbis(false);
      const selectedReason = fetchedRepairReasonsSelection.filter(
        (reason: any) => reason.description === value
      );
      dispatch(selectedSorCode(selectedReason[0]?.sors));
      setSorList(selectedReason[0]?.sors);
      dispatch(selectedRepairReasonObject(selectedReason));
      //call api and set params values
     
      if (assetDetails && selectedReason?.length > 0) {
        // setIsLoading(false)
        premisesType = assetDetails?.assetType;
        
        maintsResp = assetDetails?.maintenanceResponsibilities?.find(
            (resp: any) => resp.type === "MAINTSRESP"
          )?.value ?? "";
        
        repairResp = assetDetails?.maintenanceResponsibilities?.find(
            (resp: any) => resp.type === selectedReason[0]?.element
          )?.value ?? "";

        if (selectedReason[0]?.additionalData?.length > 0) {
          additionalRepairInfo =
            selectedReason[0]?.additionalData[0]?.description != ""
              ? true
              : false;
        } else {
          additionalRepairInfo = false;
        }

        //start logic to get isEligibleRepair
        const asset = selectedReason[0].additionalData.find(
          (item: any) =>
            item.assetType === selectedproperty &&
            item.tenancyTypes?.some(
              (tt: any) =>
                tt.toLowerCase() ===
                selTenancyGroup.toLowerCase()
            )
        );

        isRepairEligible = asset?.isEligible;
        // end
        isCustomerView = true; // as discussed true for customer search
        uprn = assetDetails.uprn;
        sorCode = selectedReason[0]?.sors[0]?.sorCode ?? selectedSorCodeValue;
        const status =
        assetDetails.statuses &&
        assetDetails.statuses.isInDefects;
        isInDefects = status;

        dispatch(userChosenSOR(sorCode));
        dispatch(userChosenSTATUS("Unappointed,Appointed,In Progress"));

        dispatch(getMaintResp(maintsResp));
        dispatch(getRepairResp(repairResp));
        checkForRepairPriorityUpdates(selectedReason);



        // Implementation for Heating or hot water repair US
        let maintenanceResponsibilities: MaintenanceResponsibility[] = [];

        if (value === "Heating or hot water repair") {
          try {
            // get maintenanceResponsibilities for all assets
            repairAssetData[0].map(async (address: any) => {
              if (assetDetails) {
                const responsibilities = heatingAttributeFromResponsibilities(
                  assetDetails.maintenanceResponsibilities
                );
                const rep = {
                  type: assetDetails.assetType,
                  value: responsibilities,
                };
                maintenanceResponsibilities.push(rep);

                if (
                  maintenanceResponsibilities &&
                  maintenanceResponsibilities.length > 0 &&
                  maintenanceResponsibilities.length == repairAssetData[0].length
                ) {
                  const propertyAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.Property
                        ? item.value
                        : "";
                    })[0]?.value ?? "";
                  const dividedBlockAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.DividedBlock
                        ? item.value
                        : "";
                    })[0]?.value ?? "";
                  const blockAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.Block ? item.value : "";
                    })[0]?.value ?? "";
                  const estateAttribute =
                    maintenanceResponsibilities.filter((item) => {
                      return item.type === PremisesType.Estate;
                    })[0]?.value ?? "";
                  // call sor determination api
                  setBlockLoading(true);
                  const sorDeterminationResponse =await api.getSorDetermination({
                    propertyAttribute,
                    dividedBlockAttribute,
                    blockAttribute,
                    estateAttribute,
                    context: assetDetails.assetType as PremisesType,
                  });
               
                  Promise.resolve(sorDeterminationResponse).then(async (results) => {
                    if (results) {
                      
                      if (
                        sorCode === "COMHIU" &&
                        results?.repairContext !== "N/A"
                      ) {
                        heatingRepairContext = results.repairContext;
                        dispatch(userChosenSOR(sorCode));
                        setEligible("none");
                        checkRepairEligibility(selectedReason);
                      } else if (results?.errorCode) {

                        sorErrorCodeValue = results?.errorCode;
                        if (results.errorCode === "E32") {
                          //setErrorCode("SSR047");
                          setSorError(results?.errorCode)
                          setEligible("block");
                          if (isSelectedValue) {
                            props.setButtonDisabled(false);
                          }
                          props.setButtonDisabled(true);
                          dispatch(userChosenSOR(""));
                          dispatch(getHeatingHotWaterCase(results.errorCode));
                        } else {
                          setEligible("block");
                          props.setButtonDisabled(true);
                        }
                      } else {
                        sorCode = results.sor;
                        dispatch(userChosenSOR(sorCode));
                        setEligible("none");
                        checkRepairEligibility(selectedReason);
                      }
                      if (
                        results?.errorCode == "E32" ||
                        results?.errorCode == "E31" ||
                        results?.errorCode == "E30" ||
                        results?.errorCode == "E29" ||
                        results?.errorCode == "E28"
                      ) {
                        setSorError(results?.errorCode);
                        
                        dispatch(sorErrorCode(results?.errorCode));
                        setIsLoading(false)
                        props.setButtonDisabled(true);
                      }

            if(!workorderNoTriageForHHWRepairsPremission) {
              await checkEnhancedRepairEligibilities(selectedReason[0]?.priority == "Emergency", results?.sor || undefined);
            } else {
              //Check Ehnance Priority AFTER selection of SOR from dropdown
            }
             setBlockLoading(false);
                    }
                  });
                }
              }
            });

          } catch (e) {
            setIsLoading(false);
          }
        } else {
          checkRepairEligibility(selectedReason);
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const checkEnhancedRepairEligibilities = async (isEmergencyValue:any, sorValue:any) => {
    
    // call Enhanced Repair Eligibility api
    const enhancedRepairEligibilityPayload = {
      "isEmergency": isEmergencyValue,
      "sor": sorValue,
      "channel": "ColleagueSelfService",
      "userRoles": localStorage.getItem('user_role') || "",
    };
    const repairEnhancedEligibility = await api.postEnhancedRepairEligibility(enhancedRepairEligibilityPayload);   
                          
    if(repairEnhancedEligibility?.isEligible===false){
      setSorError(repairEnhancedEligibility?.errorCode); 
      setIsOrbisBlockMsg(true);
      props.setButtonDisabledOrbis(true);
      setErrorCode("");
    } 
      else {
      setIsOrbisBlockMsg(false);
      props.setButtonDisabledOrbis(false);
      }
  }

  const handleChange = (e: { target: { value: any } }) => {
    setDefaultEmergencyValue(e.target.value)
    dispatch(selectedRepairReasonPriorityOverride(e.target.value == "Yes" ? "Emergency" : "Next Available"));
    dispatch(userChosenEmergencyQuestion(e.target.value));
    
    setRepairPriorityMessageForEnhancedHubRepairJourney(workorderChoosePriorityOfRepairPermission,e.target.value)
    
  }

  const checkForRepairPriorityUpdates = async (selectedReason: any) => {
    try {
      dispatch(selectedRepairReasonPriorityOverride(null))
      const priorityDetermination = api.repairPriorityDetermination(
        selectedReason[0]?.description
      )
      Promise.all([priorityDetermination]).then((results) => {
        if (results !== null && results[0].priority !== null && results[0].priority !== '') {
          dispatch(selectedRepairReasonPriorityOverride(results[0].priority));
          if (results[0].priority === RepairPriority.Emergency) {
            setDefaultEmergencyValue("Yes");
            dispatch(userChosenEmergencyQuestion("Yes"));
          }
          else {
            setDefaultEmergencyValue("No");
            dispatch(userChosenEmergencyQuestion("No"));
          }
        }
        else if (
          fetchedRepairReasonsSelection &&
          fetchedRepairReasonsSelection.length > 0
        ) {
          const priority = fetchedRepairReasonsSelection.find(
            (reasonObject: any) => {
              return reasonObject.description === selectedReason[0]?.description;
            }
          ).priority;
          dispatch(selectedRepairReasonPriorityOverride(priority));
          if (priority === RepairPriority.Emergency) {
            setDefaultEmergencyValue("Yes");
            dispatch(userChosenEmergencyQuestion("Yes"));
          } else {
            setDefaultEmergencyValue("No");
            dispatch(userChosenEmergencyQuestion("No"));
          }
        }
        else {
          setDefaultEmergencyValue("No");
          dispatch(userChosenEmergencyQuestion("No"));
        }
      });
    } catch (e) {
      setIsLoading(false);
    }
  }

  const checkRepairEligibility = async (selectedReason: any) => {
    try {
      //call api and set errorMessage
      const eligibilityResponse = await api.getRepairEligibility(
        premisesType,
        maintsResp,
        repairResp,
        additionalRepairInfo,
        isRepairEligible,
        isCustomerView,
        sorCode,
        uprn,
        isInDefects,
        heatingRepairContext
      );
      dispatch(userChosenEligibilityErrorCode(eligibilityResponse));

      if (eligibilityResponse != null && eligibilityResponse.length > 0) {
        setIsLoading(false);
        if (!isNullOrUndefined(selectedReason)) {
          props.setButtonDisabled(false);
        }
        const isEligible = eligibilityResponse[0].isEligible;
        const error = isEligible
          ? eligibilityResponse[0].errorCode != EligibilityErrorCode.SSR037
            ? eligibilityResponse[0].errorCode != EligibilityErrorCode.E23
              ? ""
              : eligibilityResponse[0].errorCode
            : eligibilityResponse[0].errorCode
          : eligibilityResponse[0].errorCode;


        switch (error) {
          case EligibilityErrorCode.E3:
            setErrorCode(error);
            props.setButtonDisabled(false);
            setEligible("none");
            break;
          case EligibilityErrorCode.E4:
          case EligibilityErrorCode.SSR037:
          case EligibilityErrorCode.E9:
          case EligibilityErrorCode.E21:
          case EligibilityErrorCode.E22:
          case EligibilityErrorCode.E23:
          case EligibilityErrorCode.E24:
          case EligibilityErrorCode.E25:
          case EligibilityErrorCode.E26:
          case EligibilityErrorCode.SSR005:
            // case EligibilityErrorCode.SSR069:
            props.setButtonDisabled(true);
            sorErrorCodeValue = error;
            setEligible("block");
            setErrorCode(error);
            break;
          case EligibilityErrorCode.E8:
            sorErrorCodeValue = "E8";
            setErrorCode(error);
            props.setButtonDisabled(true);
            setEligible("block");
            break;
          default:
            if (isSelectedValue) {
              props.setButtonDisabled(false);
            }
            break;

        }

        if (!isRepairEligible) {
          setEligible("block");
        } else {
          setEligible("none");
          if (isSelectedValue) {
            props.setButtonDisabled(false);
          }
        }

        if (!isEligible) {
          if (
            error == EligibilityErrorCode.E2 &&
            selectedReason[0]?.additionalData[0]?.description != ""
          ) {
            setEligible("none");
            if (isSelectedValue) {
              props.setButtonDisabled(false);
            }
          } else {
            if (error !== EligibilityErrorCode.E3) {
              setEligible("block");
              props.setButtonDisabled(true);
              if (error == EligibilityErrorCode.E2) {
                setErrorCode(EligibilityErrorCode.E2);
              }
              else if (error === EligibilityErrorCode.E8) {
                props.setButtonDisabled(true);
              }
              else if (error === "") {
                props.setButtonDisabled(false);
              }
            }
          }
        } else {
          if (
            eligibilityResponse[0].errorCode === EligibilityErrorCode.SSR037 ||
            eligibilityResponse[0].errorCode === EligibilityErrorCode.E23
          ) {
            setEligible("block");
          }
          if (isSelectedValue) {
            props.setButtonDisabled(false);
          }
        }
      }
      else {
        setIsLoading(false);
        props.setButtonDisabled(true)
      }
    } catch (e) {
      props.history.push("/GenericError");
      setIsLoading(false);
      console.error(e);
    }
  };

  const inputTextChangeHandler = (event: any, newValue: any) => {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      dispatch(userChosenDuplicateQuestion(""));
    
    setUserInput(newValue);
    dispatch(userChosenHighLevelDescription(newValue));
    setData(props.suggestions);
  

    if ((
      sorErrorCodeValue == SorErrorEnumCode.E28 ||
      sorErrorCodeValue == SorErrorEnumCode.E29 ||
      sorErrorCodeValue == SorErrorEnumCode.E30 ||
      sorErrorCodeValue == SorErrorEnumCode.E31 ||
      sorErrorCodeValue == SorErrorEnumCode.E32) && inputValue == "Heating or hot water repair"
    ) {
      props.setButtonDisabled(true);
    }
    else if (sorErrorCodeValue == SorErrorEnumCode.E8 || sorErrorCodeValue == SorErrorEnumCode.E4 ||
      sorErrorCodeValue == SorErrorEnumCode.E21 || sorErrorCodeValue == SorErrorEnumCode.E25
      || sorErrorCodeValue == SorErrorEnumCode.E22 || sorErrorCodeValue == SorErrorEnumCode.E9) {
      props.setButtonDisabled(true);
    }

  };

  const suggestionClickHandler = (event: any, newInputValue: any) => {
    dispatch(clearMediaFiles([]));
    dispatch(userChosenUploadedMediaIdentifiers([]));
    dispatch(userChosenSOR(""));
    dispatch(sorErrorCode(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(selectedSorCode(null));
    setSorError("");
    setSorValue("");
    if (fetchedRepairReasonsSelection != undefined) {
      const selectedValueData = fetchedRepairReasonsSelection.filter(
        (x: { description: string }) => x.description === newInputValue
      );
    }
    props.setButtonDisabled(true);
    setEligible("none");
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(isEditState(false));
      if (!IsRecall) {
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenAdditionalDescription(""));
      }
      dispatch(userChosenDuplicateQuestion(""));
    }
    setInputValue(newInputValue);

    if (newInputValue != "") {
      setData(props.suggestions);
      dispatch(userTypedRepairReasons(newInputValue));
      updateStoreForNewRepairReasonSelection(newInputValue);
      setSelectedValue(true);
    } else {
      setSelectedValue(false);
      props.setButtonDisabled(true);
    }

  };
  if (
    props.locatioAlert &&
    props.locatioAlert.length > 1 &&
    !inputValue &&
    (RepairLocationId == 0 || RepairLocationId === "No option selected")
  ) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        className="error mb-8"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center">
            <p
              data-testid="locatioAlert_0"
              className="w-full text-left my-2 font-AvantGardeGothic-Md"
            >
              {props.locatioAlert[0]}
            </p>
          </div>
        </div>
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center">
            <p
              data-testid="locatioAlert_1"
              className="w-full text-left my-2 font-AvantGardeGothic-Md"
            >
              {props.locatioAlert[1]}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (
    props.locatioAlert &&
    props.locatioAlert.length == 1 &&
    (!inputValue ||
      RepairLocationId == 0 ||
      RepairLocationId === "No option selected")
  ) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        className="error mb-8"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center">
            <p
              data-testid="locatioAlert"
              className="w-full text-left my-2 font-AvantGardeGothic-Md"
            >
              {props.locatioAlert}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (
    (isNullOrUndefined(props?.suggestions) || !props?.suggestions?.length) &&
    inputValue && !selectedSorCodeValue && !fetchRepairReasonsInProgressFromState
  ) {
    props.setButtonDisabled(true);
    suggestionList = (
      <div className="error mb-8" role="alert">
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center text-black">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
              {t("Repair_Reason_NoMatch_Message")}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (errorCode == EligibilityErrorCode.SSR037 && !inputValue) {
    let errorCodeArray: string[] = [];
    errorCodeArray = t(errorCode).split("#");
    let errorCodeValue = errorCodeArray[0];
    let errorCodeValue1 = errorCodeArray[1];
    errorCodeArray = errorCodeArray.slice(2);
    let items = errorCodeArray.map((item, index) =>
      errorCode == EligibilityErrorCode.SSR037 && index !== 2 ? (
        <li key={index} className="ul-none">
          {item}
        </li>
      ) : (
        <strong>
          <li key={index} className="ul-none">
            {item.split(":")[0]}:<strong>{item.split(":")[1]}</strong>
          </li>
        </strong>
      )
    );
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="alert alert-warning error-reason global-txt top-btm-padding"
        role="alert"
        placeholder="alert-message"
      >
        <span className="global-error-icon covid-error">
          <i className="fas fa-exclamation me-2"></i>
        </span>
        <span><b>{errorCodeValue}</b></span><br />
        <span>  {errorCodeValue1}</span>
        {errorCode == EligibilityErrorCode.SSR037 ? (
          <ol>{items}</ol>
        ) : (
          <ul>{items}</ul>
        )}
      </div>
    );
  } else if (
    (errorCode == EligibilityErrorCode.SSR037 ||
      errorCode == EligibilityErrorCode.E21) &&
    inputValue
  ) {
    let errorCodeArray: string[] = [];
    errorCodeArray = t(errorCode).split("#");
    let errorCodeValue = errorCodeArray[0];
    let errorCodeValue1 = errorCodeArray[1];
    if (errorCode === EligibilityErrorCode.SSR037) {
      props.setButtonDisabled(false);
    }
    else
      props.setButtonDisabled(true);

    errorCodeArray = errorCodeArray.slice(2);
    let items = errorCodeArray.map((item, index) =>
      errorCode == EligibilityErrorCode.SSR037 && index !== 2 ? (
        <li key={index} className="ul-none">
          {item.indexOf('@@') > -1 ? <>{item.split('@@').slice(0, 1)} <ul className="ul20">{item.split('@@').slice(1, 3).map(content => <li className="" key={content}>{content}</li>)}</ul></> : item}
        </li>
      ) : index === 2 && errorCode == EligibilityErrorCode.SSR037 ? (
        <strong>
          <li key={index} className="ul-none">
            {item.split(":")[0]}<strong><p>{item.split(":")[1]}</p></strong>
          </li>
        </strong>
      ) : (
        <li key={index}>{item}</li>
      )
    );
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="warning mb-8"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-red-20 flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
          </div>
          <div className="flex items-center text-black pt-2">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md pb-2">
              <span className="mt-2"><b>{errorCodeValue}</b></span><br /><br />
              <span className="pt-2 mt-2">{errorCodeValue1}</span>

              {errorCode == EligibilityErrorCode.SSR037 ? (
                <ol>{items}</ol>
              ) : (
                <ul>{items}</ul>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (errorCode == EligibilityErrorCode.E23 && !inputValue) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="alert alert-warning error-reason global-txt"
        role="alert"
        placeholder="alert-message"
      >
        <span className="global-error-icon covid-error">
          <i className="fas fa-exclamation me-2"></i>
        </span>
        <span className="remove-margin-left">{t(errorCode)}</span>
      </div>
    );
  } else if (errorCode && inputValue) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        style={{ display: eligible }}
        className="alert mb-8 container"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
          <div className="px-4 pr-8">
            <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
          </div>
          <div className="flex items-center text-black">
            <p className="w-full text-left font-AvantGardeGothic-Md">
              {t(errorCode)}
            </p>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    suggestionList = (
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={50}
        width={50}
        timeout={5000}
      />
    );
  }

  return (
    <>
      <div className="h5" id="repreason-div1">
        <h2 className="text-purple-100 text-2xl mb-2 global-txt">
          {t("Repair_Reason_Main_Header_Text")}
        </h2>
      </div>
      <p className="text-base my-0 global-txt" id="repreason-p">
        {t("Repair_Reason_Header_Text")}
      </p>
      {fetchedRepairReasonsData && (
        <Autocomplete
          className="pt-01 width-css global-txt pb-2 border-color"
          data-testid="high-level-repair"
          onChange={inputTextChangeHandler}
          inputValue={inputValue}
          disabled={isRecallValue}
          onInputChange={suggestionClickHandler}
          id="high-level-repair"
          freeSolo
          disableClearable
          filterOptions={(options, state) => props.suggestions}
          options={fetchedRepairReasonsData}
          size="small"
          renderInput={(params) => (
            <label className="w-full global-txt">
              <TextField
                {...params}
                margin="normal"
                placeholder={t("repair_reason_placeholder")}
                id="repreason-text"
                className="high-level-input pt-01 global-txt"
              />
            </label>
          )}
        />
      )}
      {(workorderChoosePriorityOfRepairPermission && (
        <>

          <div className="h5" id="reploc-div1">
            <h2 className="text-purple-100 text-2xl mb-2 global-txt ">
              {t("repair_emergency_heading")}
            </h2>
          </div>
          <select
            id="reploc-sel"
            data-testid="dropDownLocation"
            name="dropDownLocation"
            placeholder="Select..."
            aria-label="reploc-sel"
            onChange={handleChange}
            value={defaultEmergencyValue}
            className="block width-css mt-1 text-black global-txt rounded border-grey"
          >
            <option
              value="No"
              className="block mt-1 width-css mt-8 text-black global-txt"
            >
              No
            </option>
            <option
              value="Yes"
              className="block mt-1 width-css mt-8 text-black global-txt"
            >
              Yes
            </option>

          </select>
           <div className="alert mt-8 p-0 container" role="alert">
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left font-AvantGardeGothic-Md">
                  {t(emergencyAlertText)}
                </p>
              </div>
            </div>
         </div>
        </>
      ))}

      {suggestionList}


      <div>
        {sorError == SorErrorEnumCode.E28 ||
          sorError == SorErrorEnumCode.E29 ||
          sorError == SorErrorEnumCode.E30 ||
          sorError == SorErrorEnumCode.E31 ? (
          <div className="w-full px-4 py-2 bg-yellow flex items-center mb-4">
            <div className="px-4 pr-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="text-brick-70 w-6 h-6"
              >
                <path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path>
              </svg>
            </div>
            <div className="flex items-center">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t(sorError)}
              </p>
            </div>
          </div>
        ) :  blockLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={50}
            timeout={8000}
          />
        ) : (isOrbisBlockMsg === true && sorError===SorErrorEnumCode.E35) ? ( 
          <div className="w-full sm:px-0 px-4 py-4 bg-yellow mb-4 flex items-center">
          <div className="w-6 h-6 md:px-4 mr-6 sm:pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="text-brick-70 w-6 h-6"
            >
              <path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path>
            </svg>
          </div>
          <div className="flex flex-wrap flex-col">
          <div className="row items-center ">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md text-lg">
              {t("E35")}
            </p>
            </div>
          </div>
      </div>
        ) : sorError == SorErrorEnumCode.E32 && workorderNoTriageForHHWRepairsPremission ? (
          <div className="w-full sm:px-0 px-4 py-4 bg-yellow mb-4 flex items-center">
            <div className="w-6 h-6 md:px-4 mr-6 sm:pl-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="text-brick-70 w-6 h-6"
              >
                <path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path>
              </svg>
            </div>
            <div className="flex flex-wrap flex-col">
              <div className="row items-center ">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md text-lg">
                  {t("E32_1")}
                </p>
              </div>

              <div className="row items-center">
                <p className="mt-1 mb-2 text-sm">{t("E32_2")}</p>
                <p className="mt-0 text-sm">{t("E32_3")}</p>
              </div>

              <div className="row items-center pb-2 mr-1">
                {sorList != undefined ? (
                  <select
                    className="block w-full mt-0 ml-3 w-95"
                    onChange={handleChangeSorCode}
                    value={sorValue}
                    data-testid="sor-change-value"
                  >
                    <option>Select</option>
                    {sorList.map((sor: any) => (
                      <option key={sor.sorCode} value={sor.sorCode}>
                        {sor.trade}
                      </option>
                    ))}
                  </select>
                ) : null}
              </div>


            </div>

          </div>
        ) : <>{enableButtonPermission}</>}
      </div>
    </>
  );
};

export const heatingAttributeFromResponsibilities = (
  responsibilities: MaintenanceResponsibility[]
) => {
  if (!responsibilities) return "";

  const elementsToCheck: readonly string[] = [
    "HEAT",
    "HEATGAS",
    "HEATOIL",
    "HEATELEC",
  ];
  return responsibilities
    .filter((resp) => elementsToCheck.includes(resp.type))
    .map((resp) => resp.value)
    .filter((value) => {
      const valueLowerCase = value?.toLowerCase();
      return (
        !!value &&
        valueLowerCase !== "null" &&
        valueLowerCase !== "unknown" &&
        valueLowerCase !== "none"
      );
    })
    .join("+");
};

export default RepairReason;
