import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { formatDateTime, formatDateWithFromCalendar, formathourminutes, isNullOrUndefined } from "../../utils/formatting";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { MobilePagination, Pagination, Paginationbox, WebPagination, PropertySelect, Search } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import {WorksOrder} from "../../models/WorksOrders"
import {WorkOrderSortableFields} from "../../models/WorksOrders/WorkOrderSortableFields"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FilterComponenent from "../Filter"; 
import { RepairChannel } from "../../models/Repair/RepairChannel";
import { userChosenPropertyType, workOrderData, userChosenUPRN } from "../../ducks/redux/actions/userChoices";
import {RelatedPremisesType} from "../../models/Property/AdminUnits";
import { PremisesType } from "../../models/Customer/Premises";
import { getCustomerId, saveCustomerphoneNumber, selectedTenacyType } from "../../ducks/redux/actions/getAddress";
import { BackValue, SurveyTypes } from "../../models/shared/Enum";
import { RepairStatusField } from "../../models/Repair/Repair";
interface CustomerWorkOrderResultProps extends RouteComponentProps<any> {
    isVisibleForAsset?: boolean
}
const CustomerWorkOrderResultComponent: React.FC<CustomerWorkOrderResultProps> = (props) => {
    const [activeTenancy, setActiveTenancy] = useState(false);
    const [dropDown, setDropDown] = useState("none");
    const array: WorksOrder[] = [];
    const [workOrderList, setworkOrderList] = useState(array);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] =  useState("raisedDate desc");
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [searchText, setSearchText] = useState('');
    const defaultfromdate = formatDateTime(moment(new Date()).subtract(6, "months").toString())
    const [raisedSince, setRaisedSince] = useState(defaultfromdate);
    const defaultTodate = formatDateTime(moment(new Date()).toString())
    const [raisedUpto, setRaisedUpto] = useState(defaultTodate);
    const [isDateValid, setIsDateValid] = useState(true);
    const [dateErrorMesage, setDateErrorMessage] = useState('');
    const [isSearchValid, setIsSearchValid] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [customerWorkOrderProperty, setCustomerWorkOrderProperty] = useState('');
    const [statusWorkOrder, setStatusWorkOrder] = useState('');
    const [priorityWorkOrder, setPriorityWorkOrder] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const dispatch = useDispatch();
    let raisedSinceDate: any;
    let raisedUptoDate: any;
    const customerId = useSelector(
        (state: any) => state?.addresses?.customerid
      );
    const assetList =  useSelector(
        (state: any) => state.userChoicesReducer?.getAssetList
      );
      const backValue = useSelector(
        (state: any) => state?.userChoicesReducer?.backValue
      );
      
      const defaultAssetId =  useSelector(
        (state: any) => state.userChoicesReducer?.defaultAssetId
      );
      const searchTextCountValue =  useSelector(
        (state: any) => state.userChoicesReducer?.searchTextCountValue
      );
      const isActiveTenancy =  useSelector(
        (state: any) => state.userChoicesReducer?.isActiveTenancy
      );
      const customerData =  useSelector(
        (state: any) => state.userChoicesReducer?.customersData
      );
      const getCompInfo = useSelector(
        (state: any) => state?.userChoicesReducer?.componentInformation
      );
      const hierarchyOfPremises = useSelector(
        (state: any) => state?.userChoicesReducer?.hierarchyOfPremises
    );
    const propertyAddress = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
    );
    let TenacyType = useSelector(
        (state: any) => state?.addresses?.selectedTenancy
      );
    const customerResponse = useSelector(
        (state: any) => state?.addresses?.saveCustomerDetails
    );  
    const uprn = useSelector(
        (state: any) => state?.addresses?.assetData?.uprn
    );  
    const workOrders = useSelector(
        (state: any) => state?.userChoicesReducer?.workOrderData
      );
      const assetDetails = useSelector(
        (state: any) =>
          state?.dashboardReducer?.formState?.assetByIdDataObject
      );
      const isP360Maintainence = useSelector(
        (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
      );
    const associatedAssetList = assetList && assetList.current && assetList.current.options;
    const unAssocaitedAssetList = assetList && assetList.previous && assetList.previous.options;
    const totalLength = associatedAssetList ? associatedAssetList.length : 0 + unAssocaitedAssetList ? unAssocaitedAssetList.length : 0;
    const [defaultAssociatedAsset, setDefaultAssociatedAsset] = useState(!assetDetails?.id ? defaultAssetId : assetDetails?.id)
    useEffect(() => {  
        window.scrollTo(0,0);
        (async () => {
           
            setActiveTenancy(isActiveTenancy)
            setIsLoading(true);
            updateWorkOrderData(searchText, resultsPerPage,defaultAssetId, 0, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty)
        })();
    }, []);
    
    const getWorkOrderData = (queryParams: any) => {
        (async () => {
            try {
                
                const propertiesIds: string[] = [];
                   const associatedAssetsResponse = await api.getAssociatedAssets(queryParams.propertiesIds);
                   associatedAssetsResponse.forEach((element) => {
                    const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
                    premisesEntry.premisesType = element.assetType as PremisesType;
                    premisesEntry.premisesTypeId = element.id;
                    propertiesIds.push(element.id)
                  });
                  queryParams.propertiesIds = propertiesIds;
                  const data = await api.getWorksOrdersApi(queryParams);
                  setworkOrderList(data?.worksOrders);
                  setTotalCount(data?.totalCount);
                  setIsLoading(false);
            
           
            } catch (e) {
                setIsLoading(false);
                console.error(e);
                props.history.push("/GenericError");
            }
        })();
    }

    const handleChangeSortOrder = (e: { target: { value: string } }) => {
        setDefaultSortOrder(e.target.value);
        updateWorkOrderData(searchText, resultsPerPage,defaultAssetId, skip,e.target.value, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
    }

    const updateWorkOrderData = (searchText: any, limit: any, assetId: any, skip: any, orderBy: any, raisedSince: any, raisedUpto: any, status: any, priority: any, propertyType: any) => {
        const timeValue = formathourminutes();
           
        (async () => {
            setIsLoading(true);
            const queryParams = {
                propertiesIds: [assetId],
                searchText: searchText,
                limit: limit,
                skip: skip,
                orderBy: orderBy,
                repairType: status==RepairStatusField.Assignedtosurveyor?"survey":'',
                status: status==RepairStatusField.UnderReview?"":status,
                priority: priority,
                raisedSince:  raisedSince + 'T0'+ timeValue[0] + ':' + timeValue[1] + ':00Z', //0:00:00Z' ,
                raisedUpto:  raisedUpto +  'T'+ (23- timeValue[0]) +':' + timeValue[2] + ':00Z',
                assetType: propertyType,
                isTriage:status==RepairStatusField.UnderReview?true:"",
            };

            getWorkOrderData(queryParams);
        })();
    }

    const getValidationMessage = (search: any) => {
     

        if ((/[^0-9a-zA-Z]/.test(search.substring(0, 1))))
            return t("invalid_search_criteria");

        if (search?.includes('*')
            && search.substring(0, search.indexOf('*')).length < searchTextCountValue)
            return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

        if (search?.length < searchTextCountValue)
            return t("minimum_number_of_characters");
         
        return "";
       
    }

    
    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setCurrentPage(page);
        setSkip(resultsPerPage * page);
        updateWorkOrderData(searchText, resultsPerPage,defaultAssetId, resultsPerPage * page, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setResultsPerPage((e.target as any).value);
        setSkip(0);
        setCurrentPage(0);
        updateWorkOrderData(searchText, (e.target as any).value,defaultAssetId, 0, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
    };

    const fetchData = () => {
        let skipResult=0;
        let totalValue=0;
        if (totalCount > resultsPerPage) {
        if(skip===0 && resultsPerPage<998)
        {
            skipResult=0
            totalValue=999
            setSkip(0);
        }
        else
        {
            skipResult=resultsPerPage
            totalValue=999+skipResult
            setSkip(skipResult);
        }
        setResultsPerPage(totalValue);
        updateWorkOrderData(searchText, totalValue,defaultAssetId, skipResult, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
    }
    };

    const handleChangeAssociate = (e: any) => {
        setDefaultAssociatedAsset(e);
        updateWorkOrderData(searchText, 10,e, 0, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
      };

      const handleSubmit = (search : string) => {
        setSearchText(search);
        setCurrentPage(0);
        setResultsPerPage(10);
        setSkip(0);
        setworkOrderList([]);
        const validationMsg = getValidationMessage(search)
        if(validationMsg.length>0 && search != ""){
        setValidationMessage(validationMsg)
        setIsSearchValid(false);
        }
        else{
        setIsSearchValid(true);
        updateWorkOrderData(search, 10,defaultAssetId, 0, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
        }
      }
      const handleDateFrom = (targetValue: any) =>{
        setRaisedSince(targetValue);
         setIsDateValid(false);
         raisedSinceDate =targetValue;
         setworkOrderList([]);
         dateValidation(); 
         if(isDateValid == true){
            updateWorkOrderData(searchText, 10,defaultAssetId, 0, defaultSortOrder, raisedSinceDate, raisedUpto, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
        }
       }
       const handleDateTo = (targetValue: any) =>{
        setRaisedUpto(targetValue);
        setIsDateValid(false);
        raisedUptoDate= targetValue;
        setworkOrderList([]);
        const dateValid = dateValidation();
        if(dateValid == true){
            updateWorkOrderData(searchText, 10,defaultAssetId, 0, defaultSortOrder, raisedSince, raisedUptoDate, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
        }
      }
      
      const dateValidation =()=>{
        if(isNullOrUndefined(raisedSinceDate)){
          raisedSinceDate = raisedSince;
        }
        if(isNullOrUndefined(raisedUptoDate)){
          raisedUptoDate = raisedUpto;
        }
        const todayDate = formatDateTime(moment(new Date()).toString())
        if(raisedSinceDate> todayDate ){
            setDateErrorMessage(t("start_date_validation"))
            setIsDateValid(false);
            return false;
        }
        else if(raisedUptoDate > todayDate){
          setDateErrorMessage(t("end_date_validation"))
            setIsDateValid(false);
            return false;
        }
        
        else if(raisedSinceDate> raisedUptoDate && raisedUptoDate != ""){
          setDateErrorMessage(t("valid_date_range"))
          setIsDateValid(false);
          return false;
        }
       
        else{
          setRaisedSince(raisedSinceDate)
          setRaisedUpto(raisedUptoDate);
          setIsDateValid(true);
          setDateErrorMessage("");
          return true;
        }
      }
      const handelShowFilter = () => {
         setShowFilter(true);
      }

      const handleFilterSubmit = (customerStatus: any, tenancyType: any, propertyType: any, workOrderPropertyType: any, statusWorkOrderType: any, priorityWorkOrderType: any, customerWorkOrderPropertyType: any) => {
     
         setStatusWorkOrder(statusWorkOrderType);
         setPriorityWorkOrder(priorityWorkOrderType);
         setCustomerWorkOrderProperty(customerWorkOrderPropertyType);
        setShowFilter(false);
        updateWorkOrderData(searchText, 10,defaultAssetId, 0, defaultSortOrder, raisedSince, raisedUpto, statusWorkOrderType, priorityWorkOrderType, customerWorkOrderPropertyType);
      };
      const handleClose = () => {
        setShowFilter(false);
      }
      const handleResetSubmit = () => {  
        setRaisedSince(defaultfromdate);
        setRaisedUpto(defaultTodate);
        setSearchText('')
        updateWorkOrderData('', 10,defaultAssetId, 0, defaultSortOrder, defaultfromdate, defaultTodate, statusWorkOrder, priorityWorkOrder, customerWorkOrderProperty);
      }
      const handleReset = () => {
        setRaisedSince(defaultfromdate);
        setRaisedUpto(defaultTodate);
        setDefaultSortOrder("raisedDate desc");
        setStatusWorkOrder('');
        setCustomerWorkOrderProperty('');
        setPriorityWorkOrder('');
        updateWorkOrderData(searchText, 10,defaultAssetId, 0, 'raisedDate desc', defaultfromdate, defaultTodate, '', '', '');
      }
      const backButtonHandler = () => {
        if (backValue == BackValue.Customer360View) {
            props.history.push("/Customer360View");
          } else if (backValue == BackValue.Asset360View) {
            props.history.push("/Asset360View");
          } else {
            props.history.push(`/WorkOrder360View/${workOrders.identifiers.worksOrderIdentifier}`);
          }
        
      };
      const dropDownFunction = () => {
        if (dropDown === "none")
          setDropDown("block");
        else
          setDropDown("none");
      }
      const callPageHandler = () => {
        if(!isP360Maintainence){
          dispatch(getCustomerId(customerId));
          dispatch(userChosenPropertyType(TenacyType));
          props.history.push("/RepairAsset");
        }
      };
      const callPageHandlerAsset = () => {
        dispatch(getCustomerId(customerId));
        dispatch(selectedTenacyType(TenacyType));
        if(isNullOrUndefined(customerResponse?.addresses?.telecomsAddresses)) {
        dispatch(saveCustomerphoneNumber(null));
        }
        else {
            dispatch(saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses));
        }
        if(hierarchyOfPremises?.length)
            props.history.push("/RepairAsset");
        else 
            props.history.push("/RepairLocation");
      };
     
      const callWorkOrder360 = (workOrder: any) => {
        dispatch(workOrderData(workOrder))
        props.history.push(`/WorkOrder360View/${workOrder.identifiers.worksOrderIdentifier}`);
    }

    const showStatus = (workorder: WorksOrder) => {
        if(workorder?.statuses?.status === "In Diagnosis" && 
            (
                workorder?.statuses.isPotentialDuplicate === true || workorder?.statuses?.isVulnerable === true 
                || workorder?.job?.sorCode === "N/A" || workorder?.job?.sorCode === "NA"
            ) && workorder.statuses.isSubmitted)   
        {
            return "Under review"
        } else if(workorder.statuses?.status === "In Diagnosis") {
            return t("draft")
        } else if(workorder?.job?.repairType === SurveyTypes.survey && !isNullOrUndefined(workorder?.identifiers?.jobNumber)) {
            if(workorder?.statuses?.status== RepairStatusField.Cancelled) {
                return t('cancelled');
            } 
            return t("assigned_surveyor")
        } else if(workorder.statuses?.status  === "Unappointed" || workorder.statuses?.status  === "Reported to EMA") {
            return t("assigned_contractor")
        } else if(workorder?.statuses?.status === RepairStatusField.CatalystEMATriage) {
            return RepairStatusField.CatalystEMAStatus
        } else {
            return workorder.statuses?.status
        }
    }
    return (
        <div className="container ctn-fht mx-auto pb-12 my-5 global-txt">
          
          <div className="flex flex-row d-flex">

          <p id="vulQues-p" className="text-teal text-xl sm-pl-1">
                <a className="back-btn"  data-testid="backButtonHandler" onClick={backButtonHandler}>		
                <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>

          {(getCompInfo==="Cust360")?(
            (isActiveTenancy) ? (
            
            <div className="menu-nav ms-auto bd-highlight">
                <div className="dropdown-container-h btn-group mb-4">
                    <div className="btn btn-sm show three-dots-ast mr-2 mt-2" 
                     data-bs-toggle="dropdown"
                    data-testid="threedothandler" 
                    onClick={dropDownFunction}>
                    </div>
                 
                    <div className="dropdown-ast dropdown-menu show  mt-45" style={{ display: dropDown }}>
                    <a data-testid="callPageHandler" onClick={callPageHandler} className="text-black no-underline">
                        <div className={"cursor-pointer text-black text-base no-underline dropdown-item " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{t("Create_repair")}</div></a>
                    </div>
                </div>
            </div>
               
               ): null
                ): null}

                {(getCompInfo === "Asset360") ?(
              
                (TenacyType)
                        ?(
                    <div className="menu-nav ms-auto bd-highlight z">
                        <div className="dropdown-container-h btn-group">
                            <div className="btn btn-sm show three-dots-ast" 
                                data-testid="dropFunction" 
                                data-bs-toggle="dropdown"
                                onClick={dropDownFunction}></div>

                            <div className="dropdown-ast px-2 dropdown-menu show mt-45" style={{ display: dropDown }}>
                                {(TenacyType === "Property" && isActiveTenancy) || TenacyType === "Divided Block" || TenacyType === "Block" || TenacyType === "Estate" ?
                                <a onClick={callPageHandlerAsset} 
                                data-testid="callHandlerAsset" 
                                className="text-black no-underline ">
                                <div className={"cursor-pointer text-black text-base no-underline dropdown-item " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>{TenacyType === "Property" ? t("Create_repair") : t("raise_comm")}</div></a> : null}
                            </div>
                        </div>
                    </div>

                ) : null   
                 ): null}

            </div>

                
                {props.isVisibleForAsset && props.isVisibleForAsset == true && propertyAddress ? (
                <div className=" bd-highlight mt-4">
                    <div className="bd-highlight">
                        <h1 className="mb-4 sm-pl-1">{propertyAddress}</h1>
                    </div>
                </div>
             ) : (
             <>
            
            
             <div className="bd-highlight">
                <h1 className="mb-4 sm-pl-1">{customerData?.person.name.title} {customerData?.person.name.givenName} {customerData?.person.name.surname}</h1>
             </div>	
             
             <br/>            
                <div className="mb-4">
                        {totalLength > 1 ? <span className="global-txt mb-4">{t("Select_your_tenancy")}</span> : null}
                        {totalLength > 1 ? (
                            <label className="block sm:px-2">
                                <PropertySelect
                                    className="block w-full mt-2 rounded border-grey"
                                    data-bdd="property-select"
                                    id="property-select"
                                    data-testid="property_select"
                                    defaultValue={defaultAssociatedAsset}
                                    disabled={(assetList.current.options.length + assetList.previous.options.length) === 1}
                                    onChange={handleChangeAssociate}
                                    options={assetList}
                                    variant="outlined"
                                    fullWidth={true}
                                />
                            </label>) : associatedAssetList && associatedAssetList.length > 0 ?
                                (<div>
                                    <input
                                        className="block w-full mt-2 rounded border-grey"
                                        type="text"
                                        aria-label="Search Address"
                                        disabled={true}
                                        value={associatedAssetList[0].key}
                                    ></input>
                                </div>) : unAssocaitedAssetList && unAssocaitedAssetList.length > 0 ? (<div>
                                    <input
                                        className="block w-full mt-2 rounded border-grey"
                                        type="text"
                                        aria-label="Search Address"
                                        disabled={true}
                                        value={unAssocaitedAssetList[0].key}
                                    ></input>
                                </div>) : null}

                        </div>
                 </>)}                
            
                <div className="border-b border-grey ipad-grid flex flex-col md:flex-row justify-between items-center"> 
                    
                <div className="w-full ipad-w-full sm:px-2 md:w-6/12 lg:w-4/12 flex items-center mb-4 sm:my-0 sm:flex-col">
               <div className="flex ipad-w-full items-center mb-4 w-1/2 sm:w-full">
                        <label className="w-full flex items-center md:pr-2 ">
                            <span className="pr-1 text-date md:text-base pl-0 pt-2 sm:pl-4 w-1/2 sea-wid sm:w-1/2">{t("Created_from")}</span>
                            <input data-testid="fromDate" id="fromdate" 
                            value = {raisedSince}
                            defaultValue = {defaultfromdate}
                            type="date"  
                            onChange={e =>handleDateFrom(e.target.value)}
                            className="border-grey text-sm md:text-base p-2 md:p-4 sm:w-2/3 md:w-1/2"></input>
                        </label>
                    </div>
                   
                    <div className="flex ipad-w-full items-center mb-4 w-1/2 sm:w-full">
                        <label className="w-full flex items-center">
                        <span className="pr-1 text-date md:text-base pl-0 pt-2 sm:pl-4 w-1/3 sm:w-1/2">{t("Created_to")} </span>
                            <input data-testid="toDate" id="todate" type="date"
                            value = {raisedUpto}
                            defaultValue = {defaultTodate}
                            onChange={e =>handleDateTo(e.target.value)} 
                            className="border-grey text-sm md:text-base p-2 md:p-4 sm:w-2/3 md:w-1/2"></input>
                        </label>
                    </div>
                    </div>
                    
                    <div className="w-full ipad-w-full ipad-flex-row  sm:px-2 md:w-4/12 lg:w-4/12 flex items-center mb-3 sm:mt-2">
                        <div id="custom-search-input" className="block w-full sm-mr-1 mx-1 mb-3 sm:my-0">
                            <div className="input-group sm-pl-1 w-full" id="searchId">
                            <label className="w-full">
                            <Search className="border border-grey rounded-lg-search w-full"
                            defaultValue=""
                                datatestid="search-text-button"
                                onSubmit={handleSubmit} 
                                placeholder={t("seach_placeholder_txt")}
                                onClear={handleResetSubmit}
                                
                            />
                            </label>
                            </div>
                        </div>
                        {showFilter? <FilterComponenent setFilter={handleFilterSubmit} handleClose={handleClose} 
                searchType={"Customer Work Order"}  selectedCustomerWorkOrderPropertyType = {customerWorkOrderProperty}
                selectedStatusWorkOrderType = {statusWorkOrder} selectedPriorityWorkOrder = {priorityWorkOrder} 
                    {...props}  /> : null }
                    </div>
                 
                        
                    <div className="w-full ipad-w-full ipad-flex-row md:w-2/12 lg:w-4/12 flex items-center mb-10">
                        <div className="pr-4 mt-0 px-3">
                            <a className="text-teal no-underline hover:underline cursor-pointer a-style" data-testid = "handle-filter" onClick={handelShowFilter}><span className="md:ml-0 md:visible"
                        
                        >{t("filter_txt")}</span> <i className="fas fa-filter"></i></a></div>
                        <div className="pr-4 mt-0">
                            <a className="text-teal no-underline hover:underline cursor-pointer a-style" data-testid = "handle-reset" 
                            onClick={handleReset}
                            >
                            <span className=" md:visible">{t("reset_txt")}</span> 
                            <i className="fas fa-sync pl-1"></i>
                            </a>
                        </div>
                    </div>
                
                </div>     
                       
                <div className="w-full lg:w-1/2 flex justify-between items-center lg:mb-0 global-txt">
                   
                
                </div>
                
                
                <WebPagination>
                <div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto">

                                {!workOrderList?.length ? (

                <div className="w-full mt-4">
                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                <div className="px-4 pr-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                </div>
                <div className="flex items-center">
                
                    {!isDateValid? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{dateErrorMesage}</p>:!isSearchValid? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                        {validationMessage}
                                                        </p> :<p className="w-full text-left my-2 font-AvantGardeGothic-Md"> {statusWorkOrder ===RepairStatusField.CatalystTier2Triage || statusWorkOrder=== RepairStatusField.CatalystEMATriage || statusWorkOrder === RepairStatusField.CatalystDefectTriage || statusWorkOrder=== RepairStatusField.Assignedtosurveyor ?t("SSR026"):  t("workorder_search_no_results")}</p>}

                </div>
</div>
 </div>
                                ) : (
                                        <div className="">
                                            <div className="flex flex-col md:flex-row justify-between items-center">
                                                {/* ====== <!-- count --> ==== */}
                                                <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left"><strong>{totalCount}</strong> {t("total")}</div>
                                                </div>
                                                
                                                <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4  md:justify-end lg:justify-end">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                    <select data-testid="webWorkOrderSearchSort"
                                                        value={defaultSortOrder}
                                                        id="WorkOrder-search-sort1"
                                                        className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                        onChange={handleChangeSortOrder}
                                                        aria-label="WorkOrder Search"
                                                    >
                                                    {WorkOrderSortableFields.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e.sortableKey}>
                                                                    {e.displayName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {workOrderList.map((workorder) => (
                                                <div className="w-full desk-view-only">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                            <div className="flex flex-row justify-between items-center">

                                                            <div className="my-0 text-lg">
                                                                <a data-testid={"WorkOrder360Link" + workorder.identifiers?.worksOrderIdentifier} className="my-0 text-lg text-black cursor-pointer" onClick = {() =>callWorkOrder360(workorder)}>
                                                                     <strong>{t("workOrder")} { (workorder?.statuses?.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.CatalystEMAStatus : (workorder?.statuses?.status ===RepairStatusField.CatalystTier2Triage || workorder?.statuses?.status ===RepairStatusField.CatalystDefectTriage) ? workorder?.statuses?.status : workorder.identifiers?.jobNumber}</strong> 
                                                                </a>
                                                            </div>
                                                            <div className="my-0 text-base">
                                                                    <strong>{(workorder?.job?.repairType === SurveyTypes.survey) ? SurveyTypes.Survey : (workorder.job?.workProgramme != null && workorder.job?.workProgramme !== "" && workorder.job?.workProgramme !== undefined) ? t(workorder.job?.workProgramme) : t("RESP")}</strong>
                                                            </div>
                                                            
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("High_Level_Description")}</strong>{" "}
                                                                <br></br>
                                                                {workorder.job?.trade == "Unknown" &&
                              workorder.channel != RepairChannel.CustomerSelfService &&
                              workorder.channel != RepairChannel.ERGO &&
                              workorder.channel != RepairChannel.ColleagueSelfServe
                              ? t("RepairDescription_ErrorMessage")
                              : (workorder.channel === RepairChannel.CustomerSelfService ||
                                workorder.channel === RepairChannel.ERGO || workorder.channel === RepairChannel.ColleagueSelfServe) 
                                ? workorder.job?.description?.split(".")[0]
                                : workorder.job?.trade}     
                                                               
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("property_type_txt")}</strong>
                                                                <br></br>
                                                                {workorder.asset?.type?.assetTypeName}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("Date_Creation")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(workorder.raisedDate || '')}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("status_txt")}</strong>
                                                                <br></br>
                                                                {(workorder?.statuses?.status === "In Diagnosis" &&
                    (workorder?.statuses.isPotentialDuplicate === true ||
                        workorder?.statuses?.isVulnerable === true ||
                        workorder?.job?.sorCode === "N/A" || workorder?.job?.sorCode === "NA") && workorder.statuses.isSubmitted)
                    ? "Under review"
                                                                :
                                                                workorder.statuses?.status === "In Diagnosis"
                                                                ? t("draft") : 
                                                                (workorder.statuses?.status  === "Unappointed" || workorder.statuses?.status  === "Reported to EMA")
                                    ? (workorder.job?.repairType === SurveyTypes.survey ? t("assigned_surveyor") : t("assigned_contractor"))
                                     : (workorder?.statuses?.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.CatalystEMAStatus : workorder.statuses?.status}
                                                            </div>
                                                        </div>

                                                        <div className="px-4 md:flex md:justify-between">
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="page-mar">
                                                <Paginationbox>
                                                    <label>
                                                        <Pagination
                                                            className=" ml-4 "
                                                            colSpan={7}
                                                            count={totalCount}
                                                            page={currentPage}
                                                            rowsPerPage={resultsPerPage}
                                                            rowsPerPageOptions={[10, 20, 100]}
                                                            onPageChange={onChangePage}
                                                            data-testid="WorkOrderPaginationChange"
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </label>
                                                </Paginationbox>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ) : (
                                <div className="div-loader">
                                    <div className="SpinnerWrapper">
                                        <LoadingSpinner />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </WebPagination>
            <MobilePagination>
                <div
                    id="scrollableDiv"
                    data-testid="scrollableDiv" className="mob-view-only"
                    style={{
                        height: 800,
                        overflowY: "scroll",
                        overflow: "auto",
                        flexDirection: "column",
                    }}
                >
                    <InfiniteScroll
                        dataLength={workOrderList?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                        <div className="w-full global-txt">
                            <br></br>
                            <div className="padd-lr" role="main">
                                {!isLoading ? (
                                    
                                    <div className="container mx-auto px-3">
                                        
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left"><strong>{totalCount}</strong> {t("Total")}</div>
                                            </div>

                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4 md:justify-end">
                                                <div className="md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                <select data-testid="mobileWorkOrderSearchSort"
                                                    value={defaultSortOrder}
                                                    id="WorkOrder-search-sort1"
                                                    className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                    onChange={handleChangeSortOrder}
                                                    aria-label="WorkOrder Search"
                                                >
                                                 {WorkOrderSortableFields.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e.sortableKey}>
                                                                    {e.displayName}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        {!workOrderList?.length ? (

<div className="w-full mt-4">
<div className="w-full px-4 py-2 bg-yellow flex items-center">
    <div className="px-4 pr-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
    </div>
    <div className="flex items-center">
       
        {!isDateValid? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{dateErrorMesage}</p>:!isSearchValid? <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                             {validationMessage}
                                             </p> :<p className="w-full text-left my-2 font-AvantGardeGothic-Md">{statusWorkOrder ===RepairStatusField.CatalystTier2Triage || statusWorkOrder=== RepairStatusField.CatalystEMATriage || statusWorkOrder === RepairStatusField.CatalystDefectTriage || statusWorkOrder === RepairStatusField.Assignedtosurveyor?t("SSR026"):  t("workorder_search_no_results")}</p>}

    </div>
</div>
 </div>
                                ) : (
                                                workOrderList.map((workorder) => (
                                                    <div className="w-full desk-view-only">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                            <div className="flex flex-row justify-between items-center">

                                                                <div className="my-0 text-lg">
                                                                    <a data-testid={"MobileWorkOrder360Link" + workorder.identifiers?.worksOrderIdentifier}  className="my-0 text-lg text-black cursor-pointer" onClick = {() =>callWorkOrder360(workorder)}>                                 
                                                                        <strong>{t("workOrder")} {(workorder?.statuses?.status === RepairStatusField.CatalystEMATriage) ? RepairStatusField.CatalystEMAStatus : (workorder?.statuses?.status ===RepairStatusField.CatalystTier2Triage || workorder?.statuses?.status ===RepairStatusField.CatalystDefectTriage) ? workorder?.statuses?.status : workorder.identifiers?.jobNumber}</strong>     
                                                                        </a>
                                                                </div>
                                                            
                                                                <div className="my-0 text-base">
                                                                <strong>{(workorder?.job?.repairType === SurveyTypes.survey) ? SurveyTypes.Survey : (workorder.job?.workProgramme != null && workorder.job?.workProgramme !== "" && workorder.job?.workProgramme !== undefined)? t(workorder.job?.workProgramme) : t("RESP")}</strong>
                                                                </div>
                                                            
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("High_Level_Description")}</strong>{" "}
                                                                <br></br>
                                                                {workorder.job?.trade == "Unknown" &&
                              workorder.channel != RepairChannel.CustomerSelfService &&
                              workorder.channel != RepairChannel.ERGO &&
                              workorder.channel != RepairChannel.ColleagueSelfServe
                              ? t("RepairDescription_ErrorMessage")
                              : (workorder.channel === RepairChannel.CustomerSelfService ||
                                workorder.channel === RepairChannel.ERGO || workorder.channel === RepairChannel.ColleagueSelfServe) 
                                ? workorder.job?.description?.split(".")[0]
                                : workorder.job?.trade}
                                                               
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                               <strong>{t("property_type_txt")}</strong>
                                                                <br></br>
                                                                {workorder.asset?.type?.assetTypeName}
                                                              
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("Date_Creation")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(workorder.raisedDate || '')}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("status_txt")}</strong>
                                                                <br></br>
                                                                {showStatus(workorder)}                
                                                            </div>
                                                        </div>

                                                        <div className="px-4 md:flex md:justify-between">
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            )}
                                    </div>
                                ) : (
                                        <div className="div-loader">
                                            <div className="SpinnerWrapper">
                                                <LoadingSpinner />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            </MobilePagination>
        </div>
    )
}

export default CustomerWorkOrderResultComponent;