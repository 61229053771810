import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenIsReporter,
  backToRepairAsset,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  workOrderData
} from "../../ducks/redux/actions/userChoices";
import "./index.css";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../Button/Button";

import {
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  workOrderRepairObject,
  selectedTenacyType,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { userTypedRepairReasons } from "../../ducks/redux/actions/dashboardReducer.actions";
import { BackValue } from "../../models/shared/Enum";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { CatalistTriageContractorType } from "../../models/Repair/Contractor";
import { PermissionsList } from "../../models/Permissions";

interface RepairFinalConfirmationProps extends RouteComponentProps<any> { }
const RepairFinalConfirmation: React.FC<RepairFinalConfirmationProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [triageMessage, setTriageMessageValue] = useState("");
  const [isDefectTriageRepair, setisDefectTriageRepair] = useState(false);
  const [isTier2TriageRepair, setIsTier2TriageRepair] = useState(false);
  const [isEmaTriageRepair, setIsEmaTriageRepair] = useState(false);
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  const determinedcontractordetails = useSelector(
    (state: any) => state.addresses?.determinedcontractordetails
  );

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  useEffect(() => {
    setTriageMessages();
  }, []);

  const _userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );

  const _userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenVulnerabilityQuestion
  );

  const heatinghotwatercase = useSelector(
    (state: any) => state?.addresses?.heatinghotwatercase
  );

  const duplicateOverridePermissions = getPermissionsData?.indexOf(PermissionsList.workorder_override_duplicate_repairs) >= 0;
  const chooseHeatingElementPermission = getPermissionsData?.indexOf(PermissionsList.workorder_no_triage_forheatingandhotwaterrepairs) >= 0;
  const hideVulnerablePagePermission = getPermissionsData?.indexOf(PermissionsList.workoder_vulnerable_hide_permission) >= 0;
  const hasAllTriageOverridePermissions = duplicateOverridePermissions && chooseHeatingElementPermission && hideVulnerablePagePermission;

  const callPageHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenIsReporter(false));
    dispatch(backToRepairAsset(false))
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

  };
  const callfinishcreatPageHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenIsReporter(false));
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
    props.history.push("/RepairAsset");
  };

  let messageTriage = t("SSR050");
  const worksOrder = new WorksOrderRequest(workOrderObject);
  const isOOH = worksOrder?.statuses?.isOutOfHours;
  var comboTriageReviewCount = 0;
  const setTriageMessages = () => {
    if (isCatalyst) {
      const contractorDetail = determinedcontractordetails?.[0];
      let siteIdentifier = contractorDetail?.contractorSites ?? contractorDetail?.contractorSites[0];

      if (assetDetails?.statuses.isInDefects) {
        setisDefectTriageRepair(true);
        setTriageMessageValue("");
      }
      else if (siteIdentifier && siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_TIER2) {
        setIsTier2TriageRepair(true);
        setTriageMessageValue("");
      }
      else if (contractorDetail?.statuses?.isEMA) {
        setIsEmaTriageRepair(true);
        setTriageMessageValue("");
      }
    }

    if (_userChosenDuplicateQuestion != "" && !duplicateOverridePermissions) {
      messageTriage = t("SSR042");
      setTriageMessageValue(messageTriage);
      comboTriageReviewCount++
    }

    if (_userChosenVulnerabilityQuestion == "Yes" && !hideVulnerablePagePermission) {
      messageTriage = t("SSR040");
      setTriageMessageValue(messageTriage);
      comboTriageReviewCount++
    }

    if (heatinghotwatercase != undefined && heatinghotwatercase != "" && !chooseHeatingElementPermission) {
      messageTriage = t("SSR046");
      setTriageMessageValue(messageTriage);
      comboTriageReviewCount++
    }

    if (comboTriageReviewCount > 0) {
      setisDefectTriageRepair(false);
      setIsTier2TriageRepair(false);
      setIsEmaTriageRepair(false);
      if (comboTriageReviewCount > 1) {
        messageTriage = t("SSR050");
        setTriageMessageValue(messageTriage);
      }
    }
  };

  return (
    <>
    <div id="repFinalConfirm-container" role="main" className="container sm:px-2 height-container">
      <div
        id="repFinalConfirm-div1"
        className="h5 repair-text mt-5"
      >
        {" "}
        {isOOH && hasAllTriageOverridePermissions ? (
          <p className="text-purple-100 text-2xl mb-2 global-txt">
            {(isTier2TriageRepair || isEmaTriageRepair || isDefectTriageRepair) ? t("AR1CatalystTriageThanksMessage") : t("thankyou_savedrepair_message")}
          </p>) :
          <p className="text-purple-100 text-2xl mb-2 global-txt">
            {(isTier2TriageRepair || isEmaTriageRepair || isDefectTriageRepair) ? t("CatalystTriageThanksMessage") : t("thankyou_savedrepair_message")}
          </p>}

      </div>
      <div id="repFinalConfirm-div2">
        {isOOH && hasAllTriageOverridePermissions ? (
          <p id="repFinalConfirm-p" className="repair-text global-txt" data-testid="catalystFinalConfirmMsg">
            {isTier2TriageRepair ? t("AR1ConfimrationCatalystTier2TriageMessage1") : isEmaTriageRepair ? t("AR1ConfirmationCatalystEMAMessage1") : isDefectTriageRepair ? t("AR1ConfirmationCatalystDefectMessage1") : triageMessage}
            <br></br>
            {isTier2TriageRepair ? t("AR1ConfimrationCatalystTier2TriageMessage2") : isEmaTriageRepair ? t("AR1ConfirmationCatalystEMAMessage2") : isDefectTriageRepair ? t("AR1ConfirmationCatalystDefectMessage2") : ""}
          </p>
        ) :
          <p id="repFinalConfirm-p" className="repair-text global-txt">
            {isTier2TriageRepair ? t("CatalystTriageTier2FinalMessage1") : isEmaTriageRepair ? t("CatalystTriageEMAFinalMessage1") : isDefectTriageRepair ? t("CatalystDefectTrageMessage1") : triageMessage}
            <br></br>
            {isTier2TriageRepair ? t("CatalystTriageTier2FinalMessage2") : isEmaTriageRepair ? t("CatalystTriageEMAFinalMessage2") : isDefectTriageRepair ? t("CatalystDefectTrageMessage2") : ""}
          </p>}
      </div>
    </div>
    <div
    id="repFinalConfirm-main-btn"
    className="container button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-2 btn-left"
  >
    <a href=""
      id="btn_repFinalConfirm_finishcreate"
      data-testid="finishandcreate"
      onClick={callfinishcreatPageHandler}
      className=""
    >
      <span className="pr-1 mr-2 ml-0 global-txt a-style"> {t("finishcreate_button")}{" >"}</span>

    </a>
    <Button
      id="btn_repFinalConfirm_finish"
      data-testid="finshrepair"
      onClick={callPageHandler}
      className="button-class mt-8"
    >
      {t("finish_button")}
      <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
    </Button>
  </div>
  </>
  );
};
export default RepairFinalConfirmation;
