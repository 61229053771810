import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import questions from "../../utils/translation/en.json";
import * as ReactBootstrap from "react-bootstrap";
import Button from "../../Button/Button";
import savedAnswerKeys from "./schema.json";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "react-loader-spinner";

import moment from "moment";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  loginTime,
  refershToken,
  loginStatus,
  refreshTokenExpirationTime,
  accessTokenExpirationTime,
  initialLoginTime,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  updatePrevConfirmedState,
  isEditState,
  backToRepairAsset,
  workOrderData,
  originalWorkOrderData,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
  userChosenSaveExit,
  userChosenNotifyAll,
  userChosenDuplicateQuestion,
  userChosenVulnerabilityQuestion
} from "../../ducks/redux/actions/userChoices";
import {
  getContractorName,
  workOrderRepairObject,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { RepairPriority } from "../../models/Repair/CreateRepair";
import { BackValue } from "../../models/shared/Enum";
import { CatalistTriageContractorType } from "../../models/Repair/Contractor";
import { PermissionsList } from "../../models/Permissions";

const ConfirmationDetails: React.FC<any> = (props) => {
    const savedResponses = useSelector((state: any) => state.userChoicesReducer);
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }

  enum AssetType {
    "Property" = "Property",
    "Block" = "Block",
  }

  enum EmergencySelection {
    "SelectedEmergencyTypeYes" = "Yes, access can be provided",
    "SelectedEmergencyTypeYesAR1" = "Yes",
    "SelectedEmergencyTypeNo" = "No, please arrange an appointment",
    "SelectedEmergencyTypeNoAR1" = "No",
  }

  const determinedcontractordetails = useSelector(
    (state: any) => state.addresses?.determinedcontractordetails
  );
  const userChosenSupplementaryQuestionsVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSupplementaryQuestions
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const originalWorkOrderObject = useSelector(
    (state: any) => state?.userChoicesReducer?.originalWorkOrderData
  ); 
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const userChosenVandalismQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenContactNameVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );

  const userChosenCovidQuestionVal = useSelector(
    (state: any) => state.userChoicesReducer.userChosenCovidQuestion
  );

  const IsRecalled = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );

  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  const userMaintResp = useSelector((state: any) => state.addresses.maintresp);
  const userSelectedRepairResp = useSelector(
    (state: any) => state.addresses.repairresp
  );
  const userVandalismChoice = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );

  const userChosenDuplicateQuestionFromStore = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );
  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  ); 

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? 
        state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );

  let isTriageCase = false;
  const isCatalystDefectTriageCase = isCatalyst && assetDetails?.statuses.isInDefects === true

  const userChosenVulnerabilityQuestionFromStore = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );

  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  
  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );

  
  const  duplicateOverridePermissions = getPermissionsData?.indexOf(PermissionsList.workorder_override_duplicate_repairs) >= 0;
  const choosePriorityOfRepairPermission = getPermissionsData?.indexOf(PermissionsList.workorder_choose_priority_of_repair) >= 0;
  const hideVulnerablePagePermission = getPermissionsData?.indexOf(PermissionsList.workoder_vulnerable_hide_permission) >= 0;
  const reviewTriageRepairPermission = getPermissionsData?.indexOf(PermissionsList.workorder_review_triage_repair) >= 0; 

  
  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  let isContractorEMA = false;


  const [isLoading, setIsLoading] = useState(false);
  const [disabled, Setdisabled] = useState(true);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [showError, setShowError] = useState("");
  const [disableContinueforEnhancedEligiblityCheck, setDisableContinueforEnhancedEligiblityCheck] = useState(false);
  
  useEffect(()=>{
    if(workOrderObject.isPotentialDuplicate === true) {
      dispatch(userChosenDuplicateQuestion(t("I don't think this is a duplicate")))
    }
    
    if(workOrderObject.isVulnerable === true) {
      dispatch(userChosenVulnerabilityQuestion("Yes"));
    }
  },[])

  const getIsEmergency = () => {
    return assetDetails.assetType === "Property" && priority === "Emergency" && userChosenEmergencyQuestionVal === "Yes, access can be provided";
  }

  const getIsPotentialDuplicate = () => {
    return userChosenDuplicateQuestionFromStore === "I don't think this is a duplicate" && !duplicateOverridePermissions;
  }

  const getIsVulnerable = () => {
    return userChosenVulnerabilityQuestionFromStore === "Yes";
  }

  useEffect(()=>{
    const enhancedRepairEligibilityPayload = {
      isEmergency: getIsEmergency(),
      sor: userChosenSOR,
      isPotentialDuplicate: getIsPotentialDuplicate(),
      isVulnerable: getIsVulnerable(),
      channel: "ColleagueSelfService",
      userRoles: localStorage.getItem('user_role') || "",
    };
    
    (async () => {
      setIsLoading(true);
      const repairEnhancedEligibility = await api.postEnhancedRepairEligibility(enhancedRepairEligibilityPayload);
      setIsLoading(false);
      
      if (repairEnhancedEligibility?.isEligible === false) {
        setShowError(t(repairEnhancedEligibility?.errorCode));
        setDisableContinueforEnhancedEligiblityCheck(true);
      }

    })();
  },[])


  //Below code used to retain Track the Data for Retain
  useEffect(() => {
    const parsedData = Object.entries(savedAnswerKeys).reduce(
      (prev: any, curr: any) => {
        let mainData: any = {};
        const combinedData = Object.entries(curr[1]);
        combinedData.forEach(([key, value]: any) => {
          if (savedResponses[value] || key === "urlPath") {
            mainData = {
              ...mainData,
              [curr[1]["urlPath"]]: {
                ...mainData[curr[1]["urlPath"]],
                [key]:
                  key === "urlPath"
                    ? value
                    : {
                      key: value,
                      value: savedResponses[value],
                    },
              },
            };
          }
        });
        return {
          ...prev,
          ...mainData,
        };
      },
      {}
    );
    dispatch(updatePrevConfirmedState(parsedData));
  }, []);


  const exitButtonHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenNotifyAll(null));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(workOrderRepairObject(null));
      dispatch(backToRepairAsset(false))
      dispatch(userChosenResumeRepair(false));
      dispatch(loadDuplicateRepairPage(false));
      dispatch(userCreatedNewRepair(null));
      if(IsRecall)
      {
         dispatch(workOrderData(originalWorkOrderObject))
         props.history.push("/WorkOrder360View");
       }
    else { 
       dispatch(workOrderRepairObject(null));
       dispatch(originalWorkOrderData(null))
       props.history.push("/LandingView");
        }
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    dispatch(originalWorkOrderData(null))
    dispatch(backToRepairAsset(false))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);
    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };
  const backButtonHandler = () => {
    if (assetDetails.assetType !== AssetType.Property) {
      dispatch(userChosenNotifyAll(workOrderObject.notifyAll));   
      props.history.push("/BlockCommunication");
      props.history.action = "POP";
      return;
    }
    else {    
    props.history.push("/CustomerDetails");
    props.history.action = "POP";
   }
  };
  const callPageHandler = () => {
    
    if (
      userChosenDuplicateQuestionFromStore != "" ||
      (userChosenVulnerabilityAdditionalInformation != "" && userChosenVulnerabilityAdditionalInformation != null)
    ) {
      isTriageCase = !reviewTriageRepairPermission; 
    } else {
      isTriageCase = false;
    }
    let isCatalystTriageTier2Ema = false;
    let siteIdentifier = [];
    if (determinedcontractordetails)
    {
      const contractorDetail = determinedcontractordetails?.[0];
      siteIdentifier = contractorDetail?.contractorSites ?? contractorDetail?.contractorSites[0];
      isCatalystTriageTier2Ema = ((siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_TIER2) || (siteIdentifier[0] === CatalistTriageContractorType.CATALYST_TRIAGE_EMA));
    }
    if (isTriageCase || isCatalystDefectTriageCase || isCatalystTriageTier2Ema) {
      props.history.push("/repairfinalconfirmation");
      submitForm(siteIdentifier[0]);
    } else {
     submitForm("");
    }
  };

  const redirect = (url: string) => {
    dispatch(isEditState(true));
    props.history.push(url);
  };

  const renderData = (item: any, index: any) => {   


    return (
      
      <>
        <TableRow
          id={"confirmation-tr1" + item[0]}
          className="flex items-center bg-white"
        >
          <TableCell role="presentation"
            id={"confirmation-tr1-td1" + item[0]}
            className="w-2/3 p-3 firstcol"
          >
            <div role="presentation"
              id={"confirmation-tr1-div1" + item[0]}
              className="text-base padding-new color_black global-txt"
            >
              {/* @ts-ignore */}
              {questions?.translation[item[0]] === t("Customer_Details")
                ? t("Customer_Details_Text_Two")
                : //  @ts-ignore
                questions?.translation[item[0]]}
            </div>
          </TableCell>
          <TableCell role="presentation"
            id={"confirmation-tr1-td2" + item[0]}
            data-testid={"confirmation-tr1-div2" + item[0]}
            className="w-2/3 p-3 secondcol"
          >
            <div role="presentation"
              id={"confirmation-tr1-div2" + item[0]}
              className="text-base padding-new color_black global-txt"
            >
              {" "}
              {!savedResponses[item[1]?.reduxKeyQs]
                ?
                savedResponses[item[1]?.reduxKey].split("@")[0]
                : ""
              }
              {savedResponses[item[1]?.reduxKeyQs]
                ?
                savedResponses[item[1]?.reduxKeys]
                : ""
              }
              {"\n"}
              {savedResponses[item[1]?.reduxKeyQs]}
              <br></br>
              {savedResponses[item[1]?.reduxKeyselect]}
            </div>
          </TableCell>

          <TableCell role="presentation"
            id={"confirmation-tr1-td3" + item[0]}
            className="w-2/3 p-3 thirdcol paddright"
          >
            <div role="presentation"
              id={"confirmation-tr1-div3" + item[0]}
              className="edt-spc padding-new text-right pr-8"
            >
              {" "}
              <i
                data-testid="confirmation-icon"
                id={"confirmation-tr1-icon" + item[0]}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  redirect(`/${item[1]?.urlPath}`);
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };

    

 
  const workOrderUpdate = async () => {
    try {
      setIsLoading(true);
      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderObject.isDraft = false;
      if (isEMA) {
        workOrderObject.isEMA = true;
      }

      const siteIdentifier = contractorDetail?.contractorSites
        ? contractorDetail.contractorSites[0]
        : null;

      workOrderObject.contractor = siteIdentifier;

      userChosenSupplementaryQuestionsVal == "Yes"
        ? (workOrderObject.isSupplementary = true)
        : userChosenSupplementaryQuestionsVal == "No"
          ? (workOrderObject.isSupplementary = false)
          : (workOrderObject.isSupplementary = null);

      userChosenVandalismQuestionVal == "Yes" && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true
        ? (workOrderObject.isVandalism = true)
        : userChosenVandalismQuestionVal == "No" && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true
          ? (workOrderObject.isVandalism = false)
          : (workOrderObject.isVandalism = null);

      userChosenCovidQuestionVal == "Yes" && covidConfigValue
        ? (workOrderObject.isCovidSelfIsolated = true)
        : userChosenCovidQuestionVal == "No" && covidConfigValue
          ? (workOrderObject.isCovidSelfIsolated = false)
          : (workOrderObject.isCovidSelfIsolated = null);

        userChosenDuplicateQuestionFromStore == "" && workOrderObject.isPotentialDuplicate != true
        ? (workOrderObject.isPotentialDuplicate = false)
        : (workOrderObject.isPotentialDuplicate = !duplicateOverridePermissions);
        userChosenVulnerabilityQuestionFromStore != "" &&
        userChosenVulnerabilityQuestionFromStore != null &&
        userChosenVulnerabilityQuestionFromStore == "Yes" || workOrderObject.isVulnerable === true
        ? (workOrderObject.isVulnerable = !hideVulnerablePagePermission) 
        : userChosenVulnerabilityQuestionFromStore == "No"
          ? (workOrderObject.isVulnerable = false)
          : (workOrderObject.isVulnerable = null);
      userChosenVulnerabilityAdditionalInformation != "" && !hideVulnerablePagePermission
        ? (workOrderObject.VulnerableResponseText =
          userChosenVulnerabilityAdditionalInformation)
        : (workOrderObject.VulnerableResponseText = null);

        
      if(userChosenSOR != ""){
        workOrderObject.sorCode = userChosenSOR;
      }else {
        workOrderObject.sorCode = "NA"
      }

      if (userVandalismChoice == "Yes" && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true) {
        workOrderObject.crimeReferenceNumber =
          userChosenVandalismAdditionalInformationBackButton;
        workOrderObject.repairCause = "Vandalism or Damage";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        workOrderObject.crimeReferenceNumber = "";
        workOrderObject.repairCause = "General Wear and Tear";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      }

      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.originalWorkOrderIdentifier = workOrderObject.originalWorkOrderIdentifier
      woRequestParameters.isSubmitted = true;
      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      const timerId = setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (siteCode : string) => {
    try {
      setIsLoading(true);
      if (
        userChosenDuplicateQuestionFromStore != "" ||
        (userChosenVulnerabilityAdditionalInformation != "" && userChosenVulnerabilityAdditionalInformation != null)
      ) {
        isTriageCase = !reviewTriageRepairPermission;
      } else {
        isTriageCase = false;
      }

      const contractorDetail = determinedcontractordetails?.[0];
      const isEMA = contractorDetail?.statuses?.isEMA;

      workOrderUpdate();
      if (!isTriageCase && !isCatalystDefectTriageCase && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_TIER2 && siteCode != CatalistTriageContractorType.CATALYST_TRIAGE_EMA) {
        const isIntegrated = contractorDetail?.statuses?.isIntegrated;

        const isBuilder = contractorDetail?.statuses?.isBuilder;
        if (
          priority === RepairPriority.Emergency &&
          assetDetails.assetType !== AssetType.Property
        ) {
          props.history.push("/NoAppointment");
        } else if (
          userChosenEmergencyQuestion ===
          EmergencySelection.SelectedEmergencyTypeYes ||
          userChosenEmergencyQuestion === EmergencySelection.SelectedEmergencyTypeYesAR1 ||
          isEMA ||
          !isIntegrated ||
          isBuilder ||
          IsRecall
        ) {
          props.history.push("/NoAppointment");
        } else {
          props.history.push("/AppointmentScheduler");
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async function () {
      try {
        Setdisabled(true);
        setIsLoading(true);
        //do not call contractor determination in case of triage
        if (
          userChosenDuplicateQuestionFromStore != "" ||
          userChosenVulnerabilityAdditionalInformation != ""
        ) {
          isTriageCase = !reviewTriageRepairPermission;
        } else {
          isTriageCase = false;
        }
        if (!isTriageCase) {
          let contractorId: string = "";
          isContractorEMA = false;
          const isRecall = IsRecalled;
          let region = assetDetails?.region;
          const borough = assetDetails?.borough ? assetDetails?.borough?.trim() : "";
          let uprn = assetDetails.uprn;
          let maintenanceResponsibility: string = userMaintResp;
          let repaiResponsiblity: string = userSelectedRepairResp;

          const repairId = workOrderObject?.id;
          let repairCauseId;
          if (assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true) {
            repairCauseId = userVandalismChoice == "Yes" ? 4 : 1;
          }
          else
            repairCauseId = 1;
          const sorCode = userChosenSOR;
          const raisedDate = new Date();
          const repairTime = raisedDate.getHours().toString();
          const priorityCode = workOrderObject?.isEmergency ? "EME" : "NA";
          const raisedFormattedDate = moment(new Date()).format("YYYY-MM-DD");

          let determinedContractor: any = null;
          if (isCatalystDefectTriageCase) {
            // determinedContractor
            //create dummy determined contractor object for catalyst defect triage case
            determinedContractor = [{
              "id": CatalistTriageContractorType.CATALYST_TRIAGE_DEFECT,
              "name": "Catalyst Defect Triage",
              "statuses": {
                "isIntegrated": false,
                "isBuilder": false,
                "isSubContractor": false,
                "isEMA": false,
                "isActive": true
              },
              "contractorSites": [
                "CATALYST_TRIAGE_DEFECT"
              ],
              "emaType": "",
              "contacts": [
                {}
              ],
              "_links": [],
              "parentIdentifier": null
            }]
          }
          else if(isRecall)
          {
            // Recall jobs go to the original contract   
            let getContractorsByConsiteResponse = await api.getContractors(workOrderObject.contractor);
            if(getContractorsByConsiteResponse?.length) {
              determinedContractor = getContractorsByConsiteResponse
              determinedContractor[0].contractorSites = determinedContractor[0]?.contractorSites?.filter((conSite: string) => conSite === workOrderObject.contractor)
            } else {
              console.log("Cannot retrieve old contract");
            }         
          }
          else {

          //call contractorv2  api
          //logic to determine single contractor
           determinedContractor = await api.getDeterminedContractor(
            repairId,
            priorityCode,
            region,
            borough,
            sorCode,
            repairTime,
            uprn,
            raisedFormattedDate,
            repairCauseId,
            maintenanceResponsibility,
            repaiResponsiblity
          );
        }
          dispatch(getDeterminedContractorDetails(determinedContractor));
          // get contractor priority and check if OOH
          if (determinedContractor) {
            const contractorDetail = determinedContractor?.[0];
            if (
              contractorDetail?.name != "" &&
              contractorDetail?.name != null
            ) {
              Setdisabled(false);
            }
            dispatch(getContractorName(contractorDetail?.name));
            isContractorEMA = contractorDetail?.statuses?.isEMA;
            contractorId = contractorDetail.id;
            const contractorPriorityPayload = await api.getContractorPriority({
              sorCode,
              contractorIdentifier: contractorId,
              priority: priorityCode,
              isRecall: !!isRecall,
              isEMA: isContractorEMA,
            });
            dispatch(getContractorPayload(contractorPriorityPayload));
            let prioritySlot = "";

            const { isWorkingHours, contractorPriority } =
              contractorPriorityPayload;
            if (contractorPriority === "NA" || isRecall) {
              prioritySlot = RepairPriority.NextAvailable;
            }
            if (contractorPriority === "EME" && isWorkingHours) {
              prioritySlot = RepairPriority.Emergency;
            }
            if (contractorPriority === "OOH") {
              prioritySlot = RepairPriority.EmergencyOOH;
            }

            const sorcode = userChosenSOR; // to be replaced when hot water US done on HL desc page
            if (
              contractorDetail &&
              workOrderObject != undefined &&
              workOrderObject != null
            ) {
              try {
                setIsLoading(true);

                workOrderObject.isOutOfHours =
                  contractorPriority === "OOH" ? true : false;
                workOrderObject.contractorName = contractorDetail?.name;
                if (contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses && contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses.length > 0) {
                  workOrderObject.contractorPhoneNumber =
                    contractorDetail?.contacts?.[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number;
                }
                
                if (sorcode != "") workOrderObject.sorCode = sorcode;

                const siteIdentifier = contractorDetail?.contractorSites
                  ? contractorDetail.contractorSites[0]
                  : null;

                workOrderObject.contractor = siteIdentifier;

                userChosenSupplementaryQuestionsVal == "Yes"
                  ? (workOrderObject.isSupplementary = true)
                  : userChosenSupplementaryQuestionsVal == "No"
                    ? (workOrderObject.isSupplementary = false)
                    : (workOrderObject.isSupplementary = null);

                userChosenVandalismQuestionVal == "Yes" && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true
                  ? (workOrderObject.isVandalism = true)
                  : userChosenVandalismQuestionVal == "No" && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true
                    ? (workOrderObject.isVandalism = false)
                    : (workOrderObject.isVandalism = null);

                userChosenCovidQuestionVal === "Yes" && covidConfigValue
                  ? (workOrderObject.isCovidSelfIsolated = true)
                  : userChosenCovidQuestionVal === "No" && covidConfigValue
                    ? (workOrderObject.isCovidSelfIsolated = false)
                    : (workOrderObject.isCovidSelfIsolated = null);

                  userChosenDuplicateQuestionFromStore == "" && workOrderObject.isPotentialDuplicate != true
                  ? (workOrderObject.isPotentialDuplicate = false)
                  : (workOrderObject.isPotentialDuplicate = !duplicateOverridePermissions);
                  userChosenVulnerabilityQuestionFromStore != "" &&
                  userChosenVulnerabilityQuestionFromStore != null &&
                  userChosenVulnerabilityQuestionFromStore == "Yes" || workOrderObject.isVulnerable === true
                  ? (workOrderObject.isVulnerable = !hideVulnerablePagePermission)
                  : (workOrderObject.isVulnerable = false);
                userChosenVulnerabilityAdditionalInformation != "" && !hideVulnerablePagePermission
                  ? (workOrderObject.VulnerableResponseText =
                    userChosenVulnerabilityAdditionalInformation)
                  : (workOrderObject.VulnerableResponseText = null);

                if(userChosenSOR != ""){
                  workOrderObject.sorCode = userChosenSOR;
                }else {
                  workOrderObject.sorCode = "NA"
                }
                
                  if (isCatalyst) {
                    workOrderObject.isCatalyst = true;
                  } 
                  workOrderObject.isInDefect = assetDetails?.statuses.isInDefects
                  workOrderObject.isOutOfHours =
                  contractorPriority === "OOH" ? true : false;  

                const _worksOrderRequest = new WorksOrderRequest(
                  workOrderObject
                );
                const woRequestParameters =
                  _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
                  
                woRequestParameters.originalWorkOrderIdentifier = workOrderObject.originalWorkOrderIdentifier

                const apiResponse = await api.updateWorkOrderRepair(
                  woRequestParameters,
                  workOrderObject.id,
                  "eTag"
                );

                dispatch(workOrderRepairObject(workOrderObject));
              } catch (e) {
                console.error(e);
                props.history.push("/genericerror");
              } finally {
                setIsLoading(false);
              }
            }
          }
        } else {
          Setdisabled(false);
        }
      } catch (e) {
        props.history.push("/genericerror");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return (
    <Container id="confirmation-container" role="main" className="ctn-fht">
      <div id="confirmation-back-div" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="confirmation-p" className="text-teal text-xl mt-4 mb-8"><a className="back-btn" data-testid="confirmationBackBtn" onClick={backButtonHandler}>
          <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
      </div>

      <div className="mb-16 container">
        <div id="confirmation-main-div" className="global-txt">
          <h2 id="confirmation-h1" className="text-purple-100 text-2xl mb-2">
            {t("Repair_Details_Head")}
          </h2>
          {disableContinueforEnhancedEligiblityCheck && (
            <div
              data-testid="alert-warning"
              className="error mb-8 sm:mx-1"
              role="alert"
              placeholder="alert-message"
            >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
          )}
         
          {!disableContinueforEnhancedEligiblityCheck  && 
            <>
              <p id="confirmation-p1" className="mb-4 color_black">
              {t("Repair_Details_Text1")}
              </p>
              <p id="confirmation-p2" className="mb-4 color_black">
              {t("Repair_Details_Text2")}
              </p>
            </>
          }
        </div>

        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={5000}
          />
        ) : (
          <div className="repair-text " id="confirmation-div-table">
            <ReactBootstrap.Table striped className="container-new">
              <TableBody role="presentation"
                id="confirmation-table"
                className="w-full border border-grey"
              >
                <th className="hidden">{t("table_heading")}</th>
                {" "}
                {Object.entries(savedAnswerKeys)
                  .filter((e: any) => {
                    if (
                      (e[1].reduxKey === "mediaAttached" &&
                      isRepairMedia === "false") 
                  ) {
                      return false;
                  }
                  if(
                    hideVulnerablePagePermission && 
                      (e[1].reduxKey === "userChosenVulnerabilityQuestion" || 
                      (e[1].reduxKey === "userChosenEmergencyQuestion" && e[1].urlPath == "RepairEmergency"))
                    ) 
                  {
                      return false;
                  }

                  if(!choosePriorityOfRepairPermission && e[1].reduxKey === "userChosenEmergencyQuestion" && e[1].urlPath == "RepairLocation") {
                    return false;
                  }
                  if(e[1].reduxKey === "userChosenEmergencyQuestion" &&  priority != "Emergency") {
                    return false;
                  }

                    if ((e[1]?.urlPath == "VandalismQuestion" && assetDetails?.statuses?.isInDefects === true && vandalismConfigValue === true) || e[1]?.urlPath != "VandalismQuestion") {
                      if ((e[1]?.urlPath == "CovidQuestion" && covidConfigValue) || e[1]?.urlPath != "CovidQuestion") {
                        if((e[1]?.urlPath == "VulnerabilityQuestion" && priority != "Emergency" ) || e[1]?.urlPath != "VulnerabilityQuestion"){
                        return savedResponses[e[1]?.reduxKey];
                        }
                      }
                    }

                  })
                  .map(renderData)}

              </TableBody>
            </ReactBootstrap.Table>
          </div>
        )}
      </div>
      <div
        id="confirmation-main-btn"
        className="flex justify-between items-center pb-4 md:pb-8 container"
      >
        <ExitModalAndSave
          id="confirmation-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />

        <Button
          id="btn_confirmation"
          disabled={disabled || disableContinueforEnhancedEligiblityCheck}
          onClick={callPageHandler}
          className="button-class"
        >
          {t("continuen_button")}
          <i className="fas fa-angle-right relative text-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};

export default ConfirmationDetails;
