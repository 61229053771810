import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";

import {
  userChosenDuplicateQuestion,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  backToRepairAsset,
  resumeDraftRepair,
  userChosenIsReporter,
  userChosenPropertyAddress,
  userResumeData,
  userChosenPropertyType,
  userChosenUPRN,
  userChosenRepairLocationId,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenAdditionalDescriptionCOUNT,
  userResumeDraftDataCache,
  userChosenPropertyId,
  userChosenSOR,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenResumeRepair,
  loadDuplicateRepairPage,
  userChosenDuplicateEntity,
  userCreatedNewRepair,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { Container, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { WorksOrdersResponse } from "../../models/WorksOrders";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { saveCustomerDetails, saveCustomerphoneNumber, selectedTenacyType, workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import "./style.css";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { CurrentRepair,RepairStatusField} from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Address from "../../models/Customer/Address";
import CustomerName from "../../models/Customer/CustomerName";
import { PermissionsList } from "../../models/Permissions";
import { assetByIdDataObject } from "../../ducks/redux/actions/dashboardReducer.actions";

interface DuplicateRepairProps extends RouteComponentProps<any> { }
const DuplicateRepair: React.FC<DuplicateRepairProps> = (props) => {
  let moment = require("moment");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [radiovalue, setRadioValue] = React.useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [error, setError] = useState(false);;
  const [errorMessage, setErrorMessage] = useState("");
  const [isResumeDraftRepair, setIsResumeDraftRepair] = useState(false);
  const [disableContinueforEnhancedEligiblityCheck, setDisableContinueforEnhancedEligiblityCheck] = useState(false);
  const [showError, setShowError] = useState("");
  const [noneOftheDuplicateSelected,setNoneOftheDuplicateSelected] = useState(false);
  const [showHLDescription, setShowHLDescription] = useState(new Array(50).fill(false));
 

  const userChosenSORValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  const userDuplicateQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenDuplicateQuestion
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );
  const userChosenDuplicate = useSelector(
    (state: any) => state.userChoicesReducer.userChosenDuplicateEntity
  );
  const fetchedRepairReasonsSelection = useSelector(
    (state: any) => state.dashboardReducer.formState.fetchedRepairReasonsSelect
  );
  const selectedproperty = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetType
  );
  
  const isEmailRedirect = useSelector(
    (state: any) =>
      state?.financialReferral?.woRedirectFromEmail
  );
  
 
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ??
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  
  const [duplicateRepairResult, setDuplicateRepairResult] =
    useState<WorksOrdersResponse>();
  const userChosenResumeRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenResumeRepair
  );

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  
  const userCreatedNewRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userCreatedNewRepair
  );


  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );

  let duplicateOverridePermissions = getPermissionsData?.indexOf(PermissionsList.workorder_override_duplicate_repairs) >= 0;

  const userChosenEmergency = useSelector(
    (state: any) => state.userChoicesReducer.userChosenEmergencyQuestion
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userChosenDuplicate != "") {
      setRadioValue(userChosenDuplicate);
      dispatch(loadDuplicateRepairPage(true));
    }
    else {
      setIsDisabled(true)
    }
    (async () => {
      try {
        const propertiesIds = [];
        if (assetDetails?.id) propertiesIds.push(assetDetails?.id);
        let duplicateRepairStatuses = "Unappointed,Appointed,In Progress,In Diagnosis"
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystDefectTriage
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystTier2Triage
        duplicateRepairStatuses += ',' + RepairStatusField.CatalystEMATriage


        const queryParams = {
          propertiesIds,
          sor: userChosenSORValue,
          status: duplicateRepairStatuses,
          isDuplicateCheck: true
        };
        let duplicateRepair = await api.getWorksOrdersApi(queryParams);
        Promise.all([duplicateRepair]).then((results) => {

          const filteredWOExcludingCurrentRepairs = results[0].worksOrders.filter(
              repair => repair?.identifiers?.worksOrderIdentifier !== workOrderObject?.id
            )

            const filteredWOExcludingCommunalDrafts = filteredWOExcludingCurrentRepairs.filter(
              repair => repair?.asset?.type?.assetTypeName === "Property" || !(repair?.statuses?.status === "In Diagnosis") || getWOUnderReviewStatus(repair)
            )

          const filteredWOResp = new WorksOrdersResponse(
              { "worksOrders": filteredWOExcludingCommunalDrafts, 
                "totalCount" : filteredWOExcludingCommunalDrafts.length}
            )

          if (filteredWOResp?.totalCount === 0 && props?.history?.action !== "PUSH") {
            props.history.push("/RepairAdditionalDetails");
          }
          else {

            setDuplicateRepairResult(filteredWOResp);
            if (filteredWOResp?.totalCount === undefined || filteredWOResp?.totalCount <= 0) {

              if (props.history.action === "POP") {
                props.history.goBack();
              } else {
                props.history.push("/RepairAdditionalDetails");
              }
            } else {
              setIsLoading(false);
            }
          }
        });

      } catch (error) {
        props.history.push("/GenericError");
      }
    })();
  }, []);

  const handleRadioChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRadioValue(event.target.value);
    setDisableContinueforEnhancedEligiblityCheck(false);
    const isEmergency =
      userChosenEmergency === "Yes" ||
        userChosenEmergency === t("Repair_Emergency_Radio1_Text")
        ? true
        : false;
    let outsideContactingHours = false;

    if (isEmergency) {
      try {
        setIsDisabled(true);
        const enhancedRepairEligibilityPayload = {
          isEmergency: isEmergency,
          sor: userChosenSORValue,
          channel: "ColleagueSelfService",
          userRoles: localStorage.getItem('user_role') || "",
          isPotentialDuplicate:
            event.target.value.split("&")[0] ===
              t("IDT_its_duplicate")
              ? !duplicateOverridePermissions 
              : false,
        };
        const repairEnhancedEligibility = await api.postEnhancedRepairEligibility(enhancedRepairEligibilityPayload);
        outsideContactingHours = !repairEnhancedEligibility.isEligible;
      } catch (error) {
        setShowError(t('SSR001'));
        setDisableContinueforEnhancedEligiblityCheck(true);
        return;
      }
    }
    if (outsideContactingHours) {
      setDisableContinueforEnhancedEligiblityCheck(true);
      setShowError("Duplicate_Repair_SSR056");
      return;
    }

    setIsDisabled(false);
    setError(false);
    setErrorMessage('');

    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));

      if (!IsRecall) {
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenAdditionalDescription(""));
      }
    }
    setError(true);
    setIsDisabled(true);
    dispatch(userChosenDuplicateQuestion(event.target.value));
    dispatch(userChosenDuplicateEntity(event.target.value));
    setErrorMessage("Duplicate_Repair_SSR044");
    if (event.target.value.split("&")[2] === "true" && (!duplicateOverridePermissions)) {
      setIsDisabled(true);
      setErrorMessage("CPR079");
    } else if (event.target.value.split("&")[0] === "In Diagnosis") {
      setIsResumeDraftRepair(true);
      setIsDisabled(false);
      setErrorMessage("CPR078");
    } else if (
      event.target.value.split("&")[0] === RepairStatusField.CatalystDefectTriage) {
      setIsResumeDraftRepair(false);
      setIsDisabled(true);
      setErrorMessage("duplicate_msg_catalyst_triage");
    }
    else if (
      event.target.value.split("&")[0] === RepairStatusField.CatalystEMATriage ||
      event.target.value.split("&")[0] === RepairStatusField.CatalystTier2Triage) {
      setIsResumeDraftRepair(false);
      setIsDisabled(true);
      setErrorMessage("duplicate_msg_catalyst_triage_ema_tier2");
    }
    else if (event.target.value.split("&")[0] === "I don't think this is a duplicate") {
      if(isEmailRedirect){
        setIsResumeDraftRepair(true);
      }else {
        setIsResumeDraftRepair(false);
      }
      setNoneOftheDuplicateSelected(true);
      setIsDisabled(false);
      if(duplicateOverridePermissions) {
        setErrorMessage("Duplicate_Repair_AdvanceRepair");
      } else {
        setErrorMessage("Duplicate_Repair_SSR045");
      }
    }
  };

  const GetErrorMessage = (errMessage: string, includeSpace: boolean) => {

    return (
      errMessage.includes("CPR079") ? (
        <>
          <div className="container w-full px-2 py-2 bg-red-20 flex items-center mb-8">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
            </div>
            <div className="div-txt-newline flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {includeSpace && " "}
                {t(errMessage)}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="alert mb-8 p-0 container" role="alert">
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {includeSpace && " "}
                  {t(errMessage)}
                </p>
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const backButtonHandler = () => {
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenDuplicateEntity(""));
    if (!(priority === "Emergency" && selectedproperty === "Property")) {
      if (
        fetchedRepairReasonsSelection[0]?.additionalData.length > 0 &&
        fetchedRepairReasonsSelection[0]?.additionalData[0]?.description != ""
      ) {
        props.history.replace("/SupplementaryQuestion");
      } else {
        props.history.replace("/RepairLocation");
      }
    }
    else if (duplicateOverridePermissions) {
      props.history.replace("/RepairLocation");
    } else {
      props.history.push("/RepairEmergency");
    }
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    if (radiovalue === "") {
      dispatch(backToRepairAsset(false))
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

    } else {
      //setError(<div></div>);
      setError(false);
      //update workorder
      if (workOrderObject != null) {
        UpdateWorkOrder();
      }
      setCloseAfterSaving(true);
      dispatch(backToRepairAsset(false))
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);
      setTimeout(() => {
        dispatch(userChosenSaveExit(false))
      }, 6000);
    }
  };



  const UpdateWorkOrder = async () => {
    try {
      if (userDuplicateQuestion == "") {
        workOrderObject.isPotentialDuplicate = false
      } else {
        if ((duplicateOverridePermissions && noneOftheDuplicateSelected)) {
          workOrderObject.isPotentialDuplicate = false
        } else {
          workOrderObject.isPotentialDuplicate = true
        }
      }

      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      props.history.push("/GenericError");
    } finally {
    }
  };
  const callPageHandler = async () => {
    setIsLoading(true);
    if (isResumeDraftRepair) {
      dispatch(userChosenResumeRepair(true))
      const workOrderId = radiovalue.split("&")[1];

      if (workOrderObject != null && workOrderId !== workOrderObject.id) {
        deleteWorkOrderAndDispatch(workOrderObject, false);
      }
      const workOrderResponse = await api.getWorkOrderEntity(workOrderId);
      dispatch(workOrderRepairObject(workOrderResponse));
      dispatch(userCreatedNewRepair(workOrderResponse));
      resumeRepairHandler(workOrderResponse);

    } else if (!radiovalue) {
      setErrorMessage("Duplicate_Repair_SSR055");
    }
    else {
      dispatch(userChosenResumeRepair(false));
      dispatch(userChosenDuplicateQuestion("I don't think this is a duplicate"));
      if (workOrderObject != null) {
        UpdateWorkOrder();
      }
      props.history.push("/RepairAdditionalDetails");

    }
  };

  const deleteWorkOrderAndDispatch = async (workOrderObject: any, proceedToCreateNewWorkOrder: boolean) => {
    const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
    if (proceedToCreateNewWorkOrder) {
      const createWorkOrder = createNewWorkOrder(getWorkOrderResult);
    }
    const deleteWorkOrderResponse: any = await api.deleteWorkOrder(workOrderObject.id, getWorkOrderResult.eTag.toString());
  };

  const createNewWorkOrder = async (getWorkOrderResult: any) => {
    const workOrderRepair = new CurrentRepair();
    enum RepairCauseId {
      "General Wear and Tear" = 1,
      "Vandalism or Damage" = 4,
    }
    //  const getWorkOrderResult = await api.getWorkOrderEntity(workOrderObject.id);
    const _premisesTypeId: any =
      PremisesTypeId[getWorkOrderResult.premisesId as keyof typeof PremisesTypeId];
    workOrderRepair.repairReason = getWorkOrderResult.description
    workOrderRepair.area = getWorkOrderResult.area

    workOrderRepair.description = getWorkOrderResult.description

    workOrderRepair.premisesId = getWorkOrderResult.assetIdentifier;
    if (selectedproperty === "Divided Block") {
      workOrderRepair.premisesTypeId = "5";
    } else {
      workOrderRepair.premisesTypeId = getWorkOrderResult.premisesTypeId;
    }
    workOrderRepair.isPotentialDuplicate = getWorkOrderResult.isPotentialDuplicate
    workOrderRepair.customerIdentifier = getWorkOrderResult.customerIdentifier;
    workOrderRepair.isEmergency = getWorkOrderResult.isEmergency;
    workOrderRepair.premisesUniqueReference = getWorkOrderResult.premisesUniqueReference;
    const _address = new Address();
    _address.addressLine1 =
      customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
    _address.addressLine2 =
      customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
    _address.addressLine3 =
      customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
    workOrderRepair.premisesAddress = _address;
    workOrderRepair.sorCode = getWorkOrderResult.sorCode
    workOrderRepair.customerName = new CustomerName({
      title: customerDetails?.person?.name?.title,
      givenName: customerDetails?.person?.name?.givenName,
      surname: customerDetails?.person?.name?.surname,
    });
    workOrderRepair.reportingMethodId = getWorkOrderResult.reportingMethodId

    workOrderRepair.reportingMethod = getWorkOrderResult.reportingMethod;

    workOrderRepair.notifyAll = true;
    workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
    workOrderRepair.eligibilityOutcomes = [];
    workOrderRepair.assetIdentifier = getWorkOrderResult?.premisesId;
    workOrderRepair.repairLocationId = getWorkOrderResult.repairLocationId;
    workOrderRepair.repairCause = "General Wear and Tear";

    workOrderRepair.repairCauseId =
      RepairCauseId[
      workOrderRepair.repairCause as keyof typeof RepairCauseId
      ];
    const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
    const woRequest = _worksOrderRequest.createWorkOrderParameter(
      workOrderRepair
    );
    woRequest.repairReason = getWorkOrderResult.repairReason
    woRequest.description = getWorkOrderResult.description

    const apiResponse = await api.createWorkOrderRepair({
      ...woRequest,
    });
    if (apiResponse?.location.length > 0) {
      const workorderLocation = apiResponse?.location.split("/");
      workOrderRepair.id = workorderLocation[4];
      workOrderRepair.worksOrderIdentifier = workorderLocation[4];
      workOrderRepair.repairReason = getWorkOrderResult.description
      workOrderRepair.description = getWorkOrderResult.description
      dispatch(workOrderRepairObject(workOrderRepair));
      dispatch(userCreatedNewRepair(workOrderRepair));
    }

  }
  const resumeRepairHandler = async (draftRepair: any) => {
    dispatch(resumeDraftRepair(true));
    let assetrResponse = null;
    const customerResponse = await api.getCustomer(draftRepair.customerIdentifier);
    //customerResponse = customerDetails
    dispatch(saveCustomerDetails(customerResponse));

    dispatch(
      saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses))
    dispatch(userChosenIsReporter(draftRepair?.reporterToBeContacted));
    if (draftRepair?.reporterContactDetails != null) {
      const name = draftRepair.reporterContactDetails?.name?.givenName?.trim();
      dispatch(userChosenContactPhone(draftRepair.reporterContactDetails.contactNumber))
      dispatch(userChosenContactName(name))
      dispatch(userChosenRelationDetails(draftRepair.reportedBy));
      dispatch(userChosenContactDetails(draftRepair.reporterContactDetails.contactNumber));
      //draftRepair.reporterContactDetails.name = null;
    }
    else {
      dispatch(userChosenRelationDetails("Customer"));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactDetails(customerResponse?.addresses?.telecomsAddresses?.[0].number));

    }
    draftRepair.isDraft = true;
    dispatch(workOrderRepairObject(draftRepair));

    const {
      id,
      premisesId,
      repairLocationId,
      repairLocation,
      repairReason,
      isSupplementary,
      isEmergency,
      isPotentialDuplicate,
      additionalInformation,
      isVandalism,
      repairCauseId,
      crimeReferenceNumber,
      isVulnerable,
      VulnerableResponseText,
      isCovidSelfIsolated,
      reporterContactDetails,
      premisesUniqueReference,
      originalWorkOrderSorCode,
      priority,
      area
    } = draftRepair;
    // const area = props?.area != undefined ? props?.area : "";
    assetrResponse = await api.getAssetsByid(draftRepair.premisesId);
    dispatch(assetByIdDataObject(assetrResponse));
    if (isPotentialDuplicate) {
      dispatch(userChosenDuplicateQuestion("I don't think this is a duplicate"));
    }
    else {
      dispatch(userChosenDuplicateQuestion(""));
    }
    if (assetrResponse?.addresses?.postalAddresses[0].formattedAddress != null) {
      dispatch(userChosenPropertyAddress(assetrResponse?.addresses?.postalAddresses[0].formattedAddress));
    }
    dispatch(selectedTenacyType(area));

    const premiseType =
      area === "Block" ||
        area === "Estate" ||
        area === "Divided block"
        ? "Communal"
        : area;
    const responsedata = await api.searchRepairReasons(repairReason, premiseType, isCatalyst);
    const selectedReason = responsedata.filter(
      (reason: any) => reason.description === repairReason
    );
    if (
      selectedReason[0]?.additionalData &&
      selectedReason[0]?.additionalData.length &&
      selectedReason[0]?.additionalData[0]?.description != ""
    ) {
      const resumeData = {
        resumeHLRepair: selectedReason[0]?.description,
        resumeHLSubQuestion: selectedReason[0]?.additionalData[0]?.description,
        resumeSelectedProperty: area,
        resumePriority: priority,
      };
      dispatch(userResumeData(resumeData));
    } else {
      const resumeData = {
        resumeHLRepair: "",
        resumeHLSubQuestion: "",
        resumeSelectedProperty: area,
        resumePriority: priority,
      };
      dispatch(userResumeData(resumeData));
    }   
    dispatch(userChosenSOR(originalWorkOrderSorCode));
    repairLocation &&
      dispatch(userChosenRepairLocation(repairLocation)) &&
      dispatch(userChosenRepairLocationId(repairLocationId));
    repairReason && dispatch(userChosenHighLevelDescription(repairReason));
    isSupplementary === true
      ? dispatch(userChosenSupplementaryQuestions("Yes"))
      : isSupplementary === false
        ? dispatch(userChosenSupplementaryQuestions("No"))
        : dispatch(userChosenSupplementaryQuestions(""));
    isEmergency &&
      dispatch(userChosenEmergencyQuestion(t("Repair_Emergency_Radio1_Text")));
    additionalInformation &&
      dispatch(userChosenAdditionalDescription(t(additionalInformation))) &&
      dispatch(
        userChosenAdditionalDescriptionCOUNT(additionalInformation.length)
      );
    isVandalism
      ? dispatch(userChosenVandalismQuestion("Yes")) &&
      dispatch(userChosenVandalismAdditionalInformation(crimeReferenceNumber))
      : dispatch(userChosenVandalismQuestion("No"));
    isVulnerable
      ? dispatch(userChosenVulnerabilityQuestion("Yes")) &&
      dispatch(
        userChosenVulnerabilityAdditionalInformation(VulnerableResponseText)
      )
      : dispatch(userChosenVulnerabilityQuestion("No"));
    isCovidSelfIsolated === true
      ? dispatch(userChosenCovidQuestion("Yes"))
      : isCovidSelfIsolated === false
        ? dispatch(userChosenCovidQuestion("No"))
        : dispatch(userChosenCovidQuestion(""));
    const resumeDraftDataCache = {
      repairLocation,
      repairLocationId,
      repairReason,
      supplementaryQuestions:
        isSupplementary === true
          ? "Yes"
          : isSupplementary === false
            ? "No"
            : "",
      emergencyQuestion: isEmergency && t("Repair_Emergency_Radio1_Text"),
      additionalInformation,
      vandalismQuestion: isVandalism ? "Yes" : "No",
      crimeReferenceNumber: isVandalism ? crimeReferenceNumber : "",
      vulnerabilityQuestion: isVulnerable ? "Yes" : "No",

      vulnerableResponseText: isVulnerable ? VulnerableResponseText : "",
      covidQuestion:
        isCovidSelfIsolated === true
          ? "Yes"
          : isCovidSelfIsolated === false
            ? "No"
            : "",
      reporterContactDetails,
    };
    dispatch(userResumeDraftDataCache(resumeDraftDataCache));
    if (!repairLocation || !repairReason) {
      props.history.push("/RepairLocation");
    }

    else if (!additionalInformation) {
      if (isSupplementary === null && isPotentialDuplicate !== true) {
        props.history.push("/SupplementaryQuestion");

      } else if (isEmergency !== true && isEmergency !== false && isPotentialDuplicate !== true) {
        props.history.push("/RepairEmergency");
      }
      else {
        props.history.push("/RepairAdditionalDetails");
      }
    }

    else if (isVandalism === null && (assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true)) {
      props.history.push("/VandalismQuestion");
    } else if (isVulnerable === null) {
      props.history.push("/VulnerabilityQuestion");
    }
    else if (isVulnerable === true && VulnerableResponseText === null) {
      props.history.push("/VulnerabilityQuestion");
    }


    else if (area === "Property" && isCovidSelfIsolated === null && covidConfigValue) {
      props.history.push("/CovidQuestion");
    }
    else if (reporterContactDetails != null) {

      if (draftRepair.reporterContactDetails?.name?.givenName?.trim()?.length === 0 || draftRepair.reporterContactDetails?.contactNumber?.trim()?.length === 0 || draftRepair.reporterContactDetails?.name === null) {

        if (isCovidSelfIsolated === null && covidConfigValue) {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/CovidQuestion");
        }
        else {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/CustomerDetails");
        }
      }
      else {
        draftRepair.reporterContactDetails.name = null;
        props.history.push("/ConfirmationDetails");
      }
    }
    else if (draftRepair.reportedBy.trim().length <= 0) {
      props.history.push("/CustomerDetails");
    }
    else {

      if (draftRepair.reporterContactDetails != null) {
        const name = draftRepair.reporterContactDetails?.name?.givenName?.trim();
        dispatch(userChosenContactPhone(draftRepair.reporterContactDetails.contactNumber))
        dispatch(userChosenContactName(name))
        dispatch(userChosenRelationDetails(draftRepair.reportedBy));
        dispatch(userChosenContactDetails("I would like to provide number for this repair."));
      }
      else {
        dispatch(userChosenRelationDetails("Customer"));
        dispatch(userChosenContactPhone(customerResponse?.addresses?.telecomsAddresses?.[0].number));
        dispatch(userChosenContactDetails(customerResponse?.addresses?.telecomsAddresses?.[0].number));
      }
      props.history.push("/ConfirmationDetails");
    }
  };

  const showHLDescriptionMessage = (index:number) => {
    setShowHLDescription(prevState => prevState.map((item, idx) => idx === index ? !item : item))
  }

  const getWOUnderReviewStatus = (woUnderReviewToBeDetermined: any) => {
    return (woUnderReviewToBeDetermined?.statuses?.status === "In Diagnosis" &&
      (woUnderReviewToBeDetermined?.statuses.isPotentialDuplicate === true ||
        woUnderReviewToBeDetermined?.statuses.isVulnerable === true ||
        woUnderReviewToBeDetermined?.job.sorCode === "N/A" || woUnderReviewToBeDetermined?.job.sorCode === "NA" ||
        woUnderReviewToBeDetermined?.job.description.includes("heating or hot water")))
  }

  const alldata = (item: any, index: any) => {
    const triageStatus = getWOUnderReviewStatus(item);

    return (
    <div className="row rounded-lg mb-4">
    <div className="columnRadio">
        <FormControlLabel
            id="duprepair-label1"
            key={index}
          control={<Radio id="duprepair-radio1" name={item.identifiers.worksOrderIdentifier} data-testid={item.identifiers.worksOrderIdentifier} title="" role="radio" />}
            value={item.statuses.status + "&" + item.identifiers.worksOrderIdentifier + "&" + triageStatus}
              label=""
            aria-label="tableDup"
            className="global-txt"
          />
    </div>
        <div className="dupDiv bordertable mb-2 divLeft parent">
            <div className="colLocation divRight">
              <h6 className="headDiv">{t("Date_Creation")}</h6>
              <p className="bdyDiv">{moment(item.raisedDate).format("MMMM Do YYYY, h:mm:ss A")}</p>
            </div>
            <div className="columnDes divRight">
              <div className="flex"><h6 className="headDiv flex-item-left">{t("High_Level_Description")}</h6> 
                  <div className="bdyDiv flex-item-right div-mr-left mt-1" onClick={(e:any)=> showHLDescriptionMessage(index)} onKeyPress={(e:any)=> showHLDescriptionMessage(index)}> {showHLDescription[index]? "x" : "+"}</div>
              </div>
              <p className="bdyDiv">{
                            item.channel != RepairChannel.CustomerSelfService &&
                            item.channel != RepairChannel.ERGO &&
                            item.channel != RepairChannel.ColleagueSelfServe
                            ? (item.job.trade == "Unknown" ? t("RepairDescription_ErrorMessage") : item.job.trade)
                            : item.job.description?.split(".")[0]
                                }</p>
              {showHLDescription[index] && <p>{
                            item.channel != RepairChannel.CustomerSelfService &&
                            item.channel != RepairChannel.ERGO &&
                            item.channel != RepairChannel.ColleagueSelfServe
                            ? item?.job?.description || "-": item?.job?.description?.split(".")[1] || "-"
                            }</p>}
            </div>
            <div className="colLocation divRight">
              <h6 className="headDiv">{t("Location")}</h6>
              <p className="bdyDiv">{item.job.location.location}</p>
            </div>
            <div className="column divRight" >
              <h6 className="headDiv">{t("WO Reference")}</h6>
              <p className="bdyDiv workorderLink" onClick={(e:any)=> window.open(`/WorkOrder360View/${item?.identifiers?.worksOrderIdentifier}`, "_blank")} onKeyPress={(e:any)=> window.open(`/WorkOrder360View/${item?.identifiers?.worksOrderIdentifier}`, "_blank")}>{item?.identifiers?.jobNumber ||"View details"}</p>
            </div>
            <div className="column divRight" >
              <h6 className="headDiv">{t("Priority_txt")}</h6>
              <p className="bdyDiv">{item.statuses.isEmergency === true
                            ? "Emergency"
                            : item.statuses.isEmergency === false
                              ? "Next Available"
                              : item.statuses.isEmergency === true &&
                                item.statuses.isOutOfHours === true
                                ? "Emergency (OOH)"
                                : null}</p>
            </div>
            <div className="column mob-div">
              <h6 className="headDiv">{t("Status_txt")}</h6>
              <p className="bdyDiv">{(item.statuses.status === "In Diagnosis" &&
                            (item.statuses.isPotentialDuplicate === true ||
                              item.statuses.isVulnerable === true ||
                              item.job.sorCode === "N/A" || item.job.sorCode === "NA" ) && item.statuses.isSubmitted)
                            ? "Under review"
                            : (item.statuses.status === "In Diagnosis"
                              ? "Draft"
                              : item.statuses.status)}</p>
            </div>
            
        </div>
  </div>
    );
      
    
  };

  return (
    <Container id="duprepair-container" role="main" className="ctn-fht">
      <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 md:mb-12 ml-2">
          <a className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left mr-2"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
      {!isLoading ? (
        <>
          <div className="container">
            <div id="duprepair-label2" className="h5 my-4 ml-2">
              {" "}
              <p className="text-purple-100 text-2xl mb-4 global-txt">
                {t("Duplicate_Repair_CPR015")}{" "}
              </p>
            </div>
            <div
              id="duprepair-div3"
              className="mb-4 text-base repair-text global-txt ml-2"
            >
              <p>{t("Duplicate_Repair_CPR016")}</p>
            </div>
            
           
                <RadioGroup
                  id="duprepair-radiogroup"
                  onChange={handleRadioChange}
                  data-testid="none-of-above"
                  value={radiovalue}
                  className="question-btn "
                >
                  {duplicateRepairResult && duplicateRepairResult?.totalCount > 0
                    && duplicateRepairResult?.worksOrders?.sort(
                      (a: any, b: any) =>
                        new Date(b.raisedDate).valueOf() -
                        new Date(a.raisedDate).valueOf()
                    ).map(alldata)}

                  <fieldset><legend>
                    <FormControlLabel
                      id="duprepair-label1"
                      className="mt-4"
                      control={
                        <Radio
                          id="duprepair-radio2"
                          data-testid="NoneOfTheAbove"
                          placeholder="NoneOfTheAbove"
                          aria-label={t("NOTA")} className="global-txt "
                        />
                      }
                      label={t("NOTA")}
                      value={t("IDT_its_duplicate")}
                    />
                  </legend></fieldset>
                </RadioGroup>
              </div>
          {error && !disableContinueforEnhancedEligiblityCheck ? GetErrorMessage(errorMessage, true) : <div></div>}
          {disableContinueforEnhancedEligiblityCheck && (
                  <div
                    data-testid="alert-warning"
                    className="error mb-8 sm:mx-1"
                    role="alert"
                    placeholder="alert-message"
                  >
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center">
                        <p
                          data-testid="locatioAlert_0"
                          className="w-full text-left my-2 font-AvantGardeGothic-Md"
                        >
                          {" "}
                          {t(showError)}
                        </p>
                      </div>
                    </div>
                  </div>
          )}

          <div
            id="duprepair-main-btn"
            className="flex justify-between items-center pb-4 md:pb-8 container"
          >
            <ExitModalAndSave
              id="duprepair-exitmodal"
              exit={exitButtonHandler}
              saveDraftAndExit={saveDraftAndExit}
              closeAfterSaving={closeAfterSaving}
            />
            <Button
              id="duprepair-btn1"
              onClick={callPageHandler}
              disabled={isDisabled}
              className="button-class"
            >
              {t("continue")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
        </>
      ) : (
        <div className="div-loader">
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        </div>
      )
      }
    </Container>
  );
};
export default DuplicateRepair;
