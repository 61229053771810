import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import CaseDetails from "../CaseDetails";
import TenancySummary from "../../../models/Customer/TenancySummary";
import { CASE_ASSET_CHECK, CASE_ASSET_TYPE,CASE_CUSTOMER_NAME,CASE_CUSTOMER_SEARCH,CASE_SELECTED_ANSWERS,CASE_TENANCY_ID,CASE_TENANCY_TYPE,CLEAR_CASE_STATE,CONTACT_INFORMATION_VALUE,EDIT_BUTTON_CLICK,USERCHOSEN_PROPERTY_ID } from "../../../ducks/redux/actionTypes";
import * as api from "../../../api";
import { assetDataStatus, caseAssetAddress, caseCreatedBy, clearMediaFiles, customerAdditionalAlertData, customerPreferenceData, customersData, isActiveTenancy, selectedTenancyGroup, userChosenAssetValue, userChosenBackValue, userChosenPropertyAddress, userChosenPropertyId, userChosenRelationDetails, userChosenReporterId, userChosenUPRN, userChosenUploadedMediaIdentifiers } from "../../../ducks/redux/actions/userChoices";
import { getCustomerId, saveAssociatedAssests, saveCustomerDetails, saveCustomerphoneNumber, saveCustomerTenacies, saveTenancySummaryList, selectedTenacyType } from "../../../ducks/redux/actions/getAddress";
import CustomerResponseDetails from "../../../models/Customer/CustomerResponseDetails";
import { BackValue } from "../../../models/shared/Enum";
import CaseChronology from "../ChronologyCard";
import { isNullOrUndefined } from "../../../utils/formatting";
import Preference from "../../../models/Customer/Preference";
import CautionAlert from "../../CautionAlert";
import KeyContact from "../../KeyContact";
import { PremisesType } from "../../../models/Customer/Premises";
import AssetDetails from "../../../models/Customer/AssetDetails";
import AssetCard from "../../AssetCard";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import CustomerAssetCard from "../../CustomerAssetCard";
import CustomerCardDetails from "../../../models/Customer/CustomerCardDetail";
import { PremisesAddress } from "../../../models/shared/Address/PremisesAddress";
import AdditionalNeedAlert from "../../AdditionalNeedAlert";
import { assetByIdDataObject } from "../../../ducks/redux/actions/dashboardReducer.actions";

interface Case360ViewProps extends RouteComponentProps<any> { }
const Case360View: React.FC<Case360ViewProps> = (props) => {
  let customerEntity: CustomerCardDetails[] = [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const caseData = useSelector(
    (state: any) => state?.userChoicesReducer?.caseData
  );

  let tenancyData: TenancySummary[] = [];
  let customerResponse: CustomerResponseDetails;
  const [isEligibleForSelectCustomer, setIsEligibleForSelectCustomer] = useState(false);
  const [isCustomerComponent, setIsCustomerComponent] = useState(false);
  const [dropDown, setDropDown] = useState("none");
  const [isLoading, setIsLoading] = useState(true);
  const [customerList, setCustomerList] = useState(customerEntity);
  const [tenancyActiveList, setTenancyActiveList] = useState(tenancyData);
  const [preferenceCheck, setPreferenceCheck] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      setIsLoading(true)
      clear();
      dispatch(customerPreferenceData(""))
      dispatch(userChosenBackValue(BackValue.Case360View));
      dispatch(selectedTenacyType(caseData?.asset?.type?.assetTypeName));
      dispatch(userChosenPropertyId(caseData?.identifiers?.assetIdentifier));
      dispatch(userChosenPropertyAddress(caseData?.asset?.addresses?.formattedAddress));
      callGetAssetById(caseData?.identifiers?.assetIdentifier);
        
      callGetAssetDataByIdentifier(caseData?.identifiers?.assetIdentifier) 
      dispatch(caseCreatedBy(caseData?.asset?.type?.assetTypeName));     
    })();
  }, []);
  const clear = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS });
    dispatch({type: CASE_CUSTOMER_SEARCH})
    dispatch({type: CASE_TENANCY_ID})
    dispatch({ type: CONTACT_INFORMATION_VALUE})
    dispatch({type: CASE_TENANCY_TYPE})
    dispatch(saveCustomerphoneNumber(''))
    dispatch(caseAssetAddress(""))
    dispatch(userChosenRelationDetails(''))
    dispatch(userChosenReporterId(''))
    dispatch({type: EDIT_BUTTON_CLICK, payload: false})
    dispatch(getCustomerId(''));
    dispatch({ type: CASE_CUSTOMER_SEARCH, payload: '' })
    dispatch({ type: CASE_CUSTOMER_NAME, payload: '' })	
    dispatch({ type: CASE_ASSET_CHECK, payload: false })
    dispatch(customerPreferenceData({}))
    dispatch(customerAdditionalAlertData({}))
    dispatch(clearMediaFiles([]));
    dispatch(userChosenUploadedMediaIdentifiers([]));
    dispatch(selectedTenancyGroup(''))
  }
  const callGetAssetById = (assetIdentifiers: string) => {
    (async () => {
      try {
        const AssetResponse = await api.getAssetsByid(assetIdentifiers);
        dispatch(assetByIdDataObject(AssetResponse));
        if (AssetResponse?.uprn !== "" && AssetResponse?.uprn !== undefined && AssetResponse?.uprn !== null) {
          customerCallApi(AssetResponse.uprn, caseData?.asset?.type?.assetTypeName, caseData?.identifiers.customerIdentifier, caseData?.identifiers.assetIdentifier);
        }
        else
        {
          setIsLoading(false)
        }
      }
      catch (e) {
        setIsLoading(false)
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  };

  const callGetAssetDataByIdentifier = (id: string) => {
    (async () => {
      let associtaedAssets: AssetDetails[] = [];
      let associtaedAssetsList: AssetDetails[] = [];
      const assetResponse = await api.getAssetsByid(id);
      dispatch(assetByIdDataObject(assetResponse));
      const associatedAssetsResponse = await api.getAssociatedAssets(id);
      associtaedAssets.push(associatedAssetsResponse)
      if (assetResponse?.addresses?.postalAddresses[0].formattedAddress != null) {
        dispatch(userChosenPropertyAddress(assetResponse?.addresses?.postalAddresses[0].formattedAddress));
      }
      let associatedAssetData: any;      
      dispatch(userChosenAssetValue(assetResponse?.assetType));
      const queryParams = {
        searchText: "",
        limit: 1,
        skip: 0,
        customerType: "",
        orderBy: "",
        tenancyType: "",
        uprn: assetResponse?.uprn,
      };
     
     
      if (assetResponse?.assetType === PremisesType.Estate) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) => x.assetType === PremisesType.Estate
        );
        associtaedAssetsList.push(associatedAssetData)
      }
      else if (assetResponse?.assetType === PremisesType.Block) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) => x.assetType === PremisesType.Block || x.assetType === PremisesType.Estate
        );
        associtaedAssetsList.push(associatedAssetData)
      }
      else if (assetResponse?.assetType === PremisesType.DividedBlock) {
        associatedAssetData = associtaedAssets[0].filter(
          (x) => x.assetType === PremisesType.Block || x.assetType === PremisesType.Estate || x.assetType === PremisesType.DividedBlock
        );
        associtaedAssetsList.push(associatedAssetData)
      }
      else if (assetResponse?.assetType === PremisesType.Property) {
        associatedAssetData = associtaedAssets[0]
        associtaedAssetsList.push(associatedAssetData)
      }
 
      dispatch(saveAssociatedAssests(associtaedAssetsList));     
      dispatch(assetDataStatus(assetResponse?.assetStatus))
    })();
  }

  const dropDownFunction = () => {
    if (dropDown === "none")
      setDropDown("block");
    else
      setDropDown("none");
  }

    const callCaseHandler = () => {
        dispatch({ type: CASE_ASSET_TYPE, payload: caseData?.asset?.type?.assetTypeName });
    if(caseData?.asset?.type?.assetTypeName !== "Property"){
      props.history.push("/CaseType");
      //communal
    }
    else {
      if (caseData?.asset?.type?.assetTypeName === "Property" && isEligibleForSelectCustomer === true) {
        dispatch({ type: USERCHOSEN_PROPERTY_ID, payload: caseData?.identifiers?.assetIdentifier });
        props.history.push("/SelectCustomer");
        //multiple customer
      }
      else if (tenancyActiveList.length ===1 && caseData?.asset?.type?.assetTypeName === "Property") {
        props.history.push("/CaseType");
        //single tenancy && single Customer
      }
      else if (tenancyActiveList.length > 1) {
        props.history.push("/SelectTenancy");
        //multiple tenancy
      }
      else
        {
          props.history.push("/CaseAsset");
          //coming from property(with 0 customer)
        }
    }
  }
const customerCallApi = (assetDataUprn: string, assetType: string, customerIdentifier: string, assetIdentifier: string) => {
   
    (async () => {
      try {
        const queryParams = {
          searchText: "",
          limit: 10,
          skip: 0,
          customerType: "",
          orderBy: "",
          tenancyType: "",
          uprn: assetDataUprn,
        };
        let customerPreferenceDataObj: Preference[] = [];
        let customerSearchApi = await api.getCustomerData(queryParams);
        const customerCallResponse = customerSearchApi?.models;
        if (customerCallResponse.length === 0 && isNullOrUndefined(caseData?.identifiers?.customerIdentifier)) {
         
          setIsCustomerComponent(false)
          setIsLoading(false)
        } else {
          if(caseData?.identifiers?.customerIdentifier!== undefined && caseData?.identifiers?.customerIdentifier!== "" && caseData?.identifiers?.customerIdentifier!== null){
            dispatch(getCustomerId(caseData?.identifiers?.customerIdentifier));
            setIsCustomerComponent(true)
            customerResponse = await api.getCustomer(caseData?.identifiers?.customerIdentifier);
            getCustomerDetail(customerResponse)
            setPreferenceCheck(true);
            customerPreferenceDataObj = customerResponse.preferences
            let customerAdditionalAlertDataObj: Preference[] = [];
            let customerPreferenceAlertObj: Preference[] = [];
            customerAdditionalAlertDataObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type == 'additionalNeeds');
            customerPreferenceAlertObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type != 'additionalNeeds');
            if(customerPreferenceAlertObj.length>0){
              setPreferenceCheck(true);
            dispatch(customerPreferenceData(customerPreferenceAlertObj))}
            if(customerAdditionalAlertDataObj.length>0){
             dispatch(customerAdditionalAlertData(customerAdditionalAlertDataObj))
            }
          }
          else if(customerCallResponse[0]?.id !== undefined &&  customerCallResponse[0]?.id !== ""){
            dispatch(getCustomerId(customerCallResponse[0]?.id));
            setIsCustomerComponent(true)
            customerResponse = await api.getCustomer(customerCallResponse[0]?.id);
            setPreferenceCheck(true);
            customerPreferenceDataObj = customerResponse.preferences
            let customerAdditionalAlertDataObj: Preference[] = [];
            let customerPreferenceAlertObj: Preference[] = [];
            customerAdditionalAlertDataObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type == 'additionalNeeds');
            customerPreferenceAlertObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type != 'additionalNeeds');
            if(customerPreferenceAlertObj.length>0){
              setPreferenceCheck(true);
            dispatch(customerPreferenceData(customerPreferenceAlertObj))}
            if(customerAdditionalAlertDataObj.length>0){
             dispatch(customerAdditionalAlertData(customerAdditionalAlertDataObj))
            }
            getCustomerDetail(customerResponse)
          }
          
            
            const tenancyActive = customerResponse?.tenanciesSummaries.filter(
              (ts) =>
                ts.tenancySummary.statuses.find(
                  (s) => s.type === "isTenancyActive"
                )?.value === true
            );
            dispatch(saveCustomerTenacies(tenancyActive));
            setTenancyActiveList(tenancyActive)
           if(tenancyActive.length> 0){
             dispatch(isActiveTenancy(true));
           }
            dispatch(
              saveCustomerphoneNumber(
                customerResponse?.addresses?.telecomsAddresses
              )
            );
            dispatch(saveCustomerDetails(customerResponse));
            dispatch(customersData(customerResponse));
            dispatch(
              saveTenancySummaryList(customerResponse?.tenanciesSummaries)
            );
          }
        
            if (customerCallResponse?.length > 1) {
                let activeTenancyCount = 0;
                customerCallResponse.map((customer) => {
                    const activeTenant = customer.tenancyModels?.length > 0 ? customer.tenancyModels.filter(
                        (ts: any) => { return ts.isTenancyActive == true; }
                    ): null;
                    if (activeTenant != null && activeTenant != undefined) {
                        activeTenancyCount++;
                    }
                });
                if (activeTenancyCount > 1) {
                    setIsEligibleForSelectCustomer(true);
                }
            }
        if(customerCallResponse.length>1 && isNullOrUndefined(customerIdentifier) && !isNullOrUndefined(assetIdentifier))
        {
        if(customerIdentifier != customerCallResponse[0].id){
          const customerResponse1 = await api.getCustomer(customerIdentifier);
          if(customerResponse1.preferences.length > 0){
            setPreferenceCheck(true);
            dispatch(customerPreferenceData(customerResponse1.preferences));
          }
        }
      }
        if(customerCallResponse.length>1 && isNullOrUndefined(customerIdentifier) && !isNullOrUndefined(assetIdentifier)){
          if(customerCallResponse[1]?.id !== undefined &&
            customerCallResponse[1]?.id !== "" ){
          const customerResponse2 = await api.getCustomer(customerCallResponse[1]?.id);
          if(customerResponse.preferences.length){
          customerPreferenceDataObj = customerResponse2.preferences.concat(customerPreferenceDataObj)
          dispatch(customerPreferenceData(customerPreferenceDataObj))
          }
        }
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  };
  const getCustomerDetail=(customerResponse: CustomerResponseDetails)=>{
    let customerListData: CustomerCardDetails[] = [];
    let customerCardDetail=new CustomerCardDetails();
    customerCardDetail.id = customerResponse["customer-identifier"];

    customerCardDetail.givenName = customerResponse.person.name.givenName || "";
    customerCardDetail.surname = customerResponse.person.name.surname || ""
    customerCardDetail.email = customerResponse?.addresses.digitalAddresses.find(
        (a:any) => a.digitalAddressType === "email"
    )?.address || ""
    customerCardDetail.homePhone = customerResponse?.addresses.telecomsAddresses.find(
        (a:any) => a.telecomsAddressType === "homePhone"
    )?.number || "";
    customerCardDetail.workPhone = customerResponse?.addresses.telecomsAddresses.find(
        (a:any) => a.telecomsAddressType === "workPhone"
    )?.number || "";
    customerCardDetail.mobilePhone = customerResponse?.addresses.telecomsAddresses.find(
        (a:any) => a.telecomsAddressType === "mobilePhone"
    )?.number || "";
    customerCardDetail.tenancyModels = customerResponse?.addresses?.postalAddresses[0] as PremisesAddress;
    customerListData.push(customerCardDetail);
    setCustomerList(customerListData);
    
  }


  const backButtonHandler = () => {
    props.history.push("/LandingView");
  };

  return (
    <div className="border-t border-grey pb-12 bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
      <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 global-txt" >
        <div id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="caseBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </div>
      </div>
      {!isLoading ? (
      <div className="container mx-auto">
        <div className="w-full global-txt px-2 ">

          <div className="flex flex-row d-flex bd-highlight">
            <div className="bd-highlight  ">
              <h1 className="mb-4 sm:mt-1">{caseData.caseReferenceNumber}</h1>
            </div>

            {caseData?.status === "Active" ? (
              <div className="menu-nav ms-auto bd-highlight ">
                <div className="dropdown-container-h btn-group">
                  <div data-testid="dotsmenu"
                    className="btn btn-sm show three-dots-ast dwn_rt"
                    data-bs-toggle="dropdown"
                    onClick={dropDownFunction}
                  >
                  </div>
                  <div className="dropdown-ast px-2 dropdown-menu show mt-45" style={{ display: dropDown }}>
                    <a data-testid="casepage" onClick={callCaseHandler} className="text-black text-base no-underline global-txt">
                      <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("CP104")}</div></a>
                  </div>
                </div>
              </div>
            ) : null}

          </div>

            { preferenceCheck  ? (
          <div className="pb-3">
            <CautionAlert {...props} />
          </div>
        ) : null}

{  
          <div className="pb-3">
            <AdditionalNeedAlert {...props} />
          </div>
        }

          <div className="grid grid-cols-1 gap-4 md:gap-8 mt-2">
            <CaseDetails {...props} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card">
              <div>
                <CaseChronology {...props} />
              </div>
            </div>
                 {isCustomerComponent ? (
              <div>
                <CustomerAssetCard
                 customerAssetList={customerList}
                 {...props} />
              </div>
              ):null}
            <div className="mt-card">
                  <AssetCard {...props} />
                </div>
            <div>
              <div>
                <KeyContact {...props} />
              </div>
         
            </div>
          </div>

        </div>

      </div>
       ):(
        <div className="div-loader">
        <div className="SpinnerWrapper">
          <LoadingSpinner />
        </div>
      </div>
      )}
    </div>
  );
};

export default Case360View;
