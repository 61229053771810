import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import * as api from "../../api";
import {  
  selectedTenacyType,
  saveIsEligibilityRepair,
  workOrderRepairObject,assetData, getContractorName
} from "../../ducks/redux/actions/getAddress";
import {
  userChosenSurveyReason,
  userChosenPropertyType,
  userChosenUPRN,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
  assetRepairCheck,
  repairAddressData,
  backToRepairAsset,
  userChosenIsRecall,
  userChosenDuplicateEntity,
  userChosenCurrentValue,
  userChosenPropertyId,
  userChosenSaveExit,
  userChoiceRepairReasons,
  userTypedSurveyReason, 
  userChosenPropertyAddress,
  createEnhancedHubRepairPermissions,
  selectedTenancyGroup
} from "../../ducks/redux/actions/userChoices";
import {
  updateDashboardForm,
  userTypedRepairReasons,
  assetByIdDataObject
} from "../../ducks/redux/actions/dashboardReducer.actions";
import houseIcon from "../../assets/icons/my-home.png";
import blockIcon from "../../assets/icons/my-block.png";
import estateIcon from "../../assets/icons/estate.png";
import dividedBlockIcon from "../../assets/icons/divided-block.png";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import ExitModalContent from "../Modal/ExitModalContent";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { BackValue } from "../../models/shared/Enum";

interface RepairAssetProps extends RouteComponentProps<any> {}

const RepairAsset: React.FC<RepairAssetProps> = (props) => {
  enum AssetTypeMappingEnum {
    Social = "Property",
    "Peabody Property" = "Property",
    "Non-residential" = "Property",
    Commercial = "Property",
    "Market" = "Property",
    Leasehold = "Block",
    Freehold = "Block",
  }
  const BlockMap = {
    Property: "Property",
    "Divided Block": "Divided Block",
    Block: "Block",
    Estate: "Estate",
    "Estate Area": "Estate",
  };
  const [isLoading, setIsLoading] = useState(true);
  let tenanciesId = "";
  let assetType = "";
  let premisesId = "";
  let isPremisesElegible = true;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<any>();
  const [eligible, setEligible] = React.useState("none");
  const [errorCode, setErrorCode] = useState("");
  const [buttondisable, setButtonDisable] = useState(false);
  const [disableSelection, setDisableSelection] = useState(true);
  const [propertyIdChosen, setPropertyIdChosen] = useState("");
  const [isRecallValue, setIsRecallValue] = useState(false);
  var arrayErrorCode: string[] = [];
  let [errorCodeArray, setErrorCodeArray] = useState(arrayErrorCode);
  let mainSet: string;
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const isSurvey = useSelector(
    (state: any) => state.userChoicesReducer?.isSurvey
  );
  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  const addressesData = useSelector(assets);
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const tenancies = (state: any) => state?.addresses?.customertenancies;
  const tenanciesData = useSelector(tenancies);
  if (tenanciesData != null) {
    tenanciesId = tenanciesData[0]?.tenancySummary?.tenancyId;
  }
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const selectedAssetType = useSelector(
    (state: any) => state.addresses?.selectedTenancy
  );
  const premisesid = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.tracks[0]?.assetId
  );
  
  const backToRepair = useSelector(
    (state: any) => state?.userChoicesReducer?.backToRepairAsset
  );
  const repairAssetData = useSelector(
    (state: any) => state?.userChoicesReducer?.repairAddressData
  );


const workOrders = useSelector(
  (state: any) => state?.userChoicesReducer?.workOrderData
);
 const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const getAssetIcon = (assetType: any) => {
    var icon = houseIcon;
    if (assetType == "Property") icon = houseIcon;
    if (assetType == "Block") icon = blockIcon;
    if (assetType == "Divided Block") icon = dividedBlockIcon;
    if (assetType.includes("Estate")) icon = estateIcon;
    return icon;
  };
  //To track div selection for RepairProperty--Start here---
  const [assetTypeValue, setAssetTypeValue] = useState("");
  //-----------Ends here-----------//

  const SetErrorDetails = () => {
    setIsLoading(false);
    setErrorCode("SSR001");
    setEligible("block");
    setButtonDisable(true);
  };

  useEffect(() => {
    if(IsRecall)
    {
      setIsRecallValue(true)
    }
    else
    {
      setIsRecallValue(false)
    }

    setPropertyIdChosen(assetDetails?.id);
    (async function () {
      try {
        dispatch(userChosenCurrentValue(null));
        dispatch(assetRepairCheck(false));
        dispatch(workOrderRepairObject(null));
        dispatch(userChosenSaveExit(null))
        dispatch(userChosenDuplicateEntity(""));
        if(backToRepair){
          const propertyCheck = repairAssetData[0].filter(
            (x: any) => x.id == premisesid
          );
          setAddresses(repairAssetData)
          if(propertyCheck.length>0){
            callSelection(
              propertyCheck[0].assetType,
              propertyCheck[0].id,
              propertyCheck[0].uprn,
              propertyCheck[0].addresses.postalAddresses[0]?.formattedAddress
            );
          }
              
        }
        else{
        if (addressesData[0].length > 1 || addressesData[0][0].assetType === "Property")  {
          const propertyCheck = addressesData[0].filter(
            (x: any) => x.id == premisesid
          );
          if (
            propertyCheck.length > 0 &&
            propertyCheck[0].assetType != "Property"
          ) {
            let addressSelectedDataList;
            if (propertyCheck[0].assetType == "Divided Block") {
              addressSelectedDataList = addressesData[0].filter(
                (x: { assetType: string; id: any }) =>
                  x.assetType === "Block" ||
                  x.assetType === "Estate" ||
                  x.assetType == "Scheme" ||
                  x.id == propertyCheck[0].id
              );
            } else if (propertyCheck[0].assetType == "Block") {
              addressSelectedDataList = addressesData[0].filter(
                (x: { assetType: string; id: any }) =>
                  x.assetType === "Estate" ||
                  x.assetType == "Scheme" ||
                  x.id == propertyCheck[0].id
              );
            } else if (propertyCheck[0].assetType == "Estate") {
              addressSelectedDataList = addressesData[0].filter(
                (x: { assetType: string; id: any }) =>
                  x.assetType == "Scheme" || x.id == propertyCheck[0].id
              );
            } else if (propertyCheck[0].assetType == "Scheme") {
              addressSelectedDataList = addressesData[0].filter(
                (x: { assetType: string; id: any }) =>
                  x.id == propertyCheck[0].id
              );
            }
            let filteredAddress = [];
            filteredAddress.push(addressSelectedDataList);
            dispatch(repairAddressData(filteredAddress));
            const schemeCheck =  filteredAddress[0]?.filter(
              (x: { assetType: string; id: any }) =>
                x.assetType == "Scheme"
            );
            if (filteredAddress != undefined && (filteredAddress[0]?.length > 1 || (filteredAddress[0]?.length >2 && schemeCheck.length>0))) {
              setAddresses(filteredAddress);
              callSelection(
                propertyCheck[0].assetType,
                propertyCheck[0].id,
                propertyCheck[0].uprn,
                propertyCheck[0].addresses.postalAddresses[0]?.formattedAddress
              );
            } else {
              callSelection(
                propertyCheck[0].assetType,
                propertyCheck[0].id,
                propertyCheck[0].uprn,
                propertyCheck[0].addresses.postalAddresses[0]?.formattedAddress
              );

              if(filteredAddress[0].length ==1){
              dispatch(assetRepairCheck(true));
              }

              dispatch(repairAddressData(filteredAddress));

              if(isSurvey){
                props.history.push("/SurveyLocation");
              }else{
                props.history.push("/RepairLocation");
              }
            }
          } else {
            dispatch(repairAddressData(addressesData));
            
            const schemeCheck =  addressesData[0].filter(
              (x: { assetType: string; id: any }) =>
                x.assetType == "Scheme"
            );
            if(((addressesData[0].length ==1) || (addressesData[0].length== 2 && schemeCheck.length>0 )) && addressesData[0][0].assetType !== "Property"){
              dispatch(assetRepairCheck(true));
              callSelection(
                addressesData[0][0].assetType,
                addressesData[0][0].id,
                addressesData[0][0].uprn,
                propertyCheck[0].addresses.postalAddresses[0]?.formattedAddress
              );

              if(isSurvey){
                props.history.push("/SurveyLocation");
              }else{
                props.history.push("/RepairLocation");
              }
            }
            setAddresses(addressesData);
          }
        }
        else
        {
          callSelection(
            addressesData[0][0].assetType,
            addressesData[0][0].id,
            addressesData[0][0].uprn,
            addressesData[0][0].addresses.postalAddresses[0]?.formattedAddress
          );

          if(addressesData[0].length ==1){
          dispatch(assetRepairCheck(true));
          }

          dispatch(repairAddressData(addressesData));

          if(isSurvey){
            props.history.push("/SurveyLocation");
          }else{
            props.history.push("/RepairLocation");
          }
          }
      }

        if (customerID) {          
          const responseTenancies = await api.getTenancies(customerID);
          if (responseTenancies) {
            setIsLoading(false);
          } else {
            SetErrorDetails();
          }
          var defaultSelectedTenancies = responseTenancies.filter(
            (ts: { tenancyIdentifier: string }) =>
              ts.tenancyIdentifier === tenanciesId
          );
          let assettypeKey: string = "";
          if (defaultSelectedTenancies) {
            assettypeKey = defaultSelectedTenancies[0]?.tenancyGroup;
            assetType =
              AssetTypeMappingEnum[
                assettypeKey as keyof typeof AssetTypeMappingEnum
              ];
          }
          if (!isUndefinedNullOrEmpty(selectedAssetType)) {
            assetType = selectedAssetType;
          }
          if(premisesId && assettypeKey&& !isSurvey){
            checkPrimiseEligilibility(premisesId, assettypeKey);
          }
          else if(isSurvey)
          {
            setEligible("none");
            setButtonDisable(false);
          }
        }
        addressesData?.forEach((element: any) => {
          if (element.length > 0) {
            element.forEach((item: any) => {
              if (item.assetType === assetType) {
                premisesId = item.id;
                if (!assetTypeValue) {
                  callSelection(assetType, item.id, item.uprn,item.addresses.postalAddresses[0]?.formattedAddress);
                }
              }
            });
          }
        });
      } catch (e) {
        SetErrorDetails();
        console.error(e);
      }
    })();
  }, []);

  const backButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else {
      props.history.push(`/WorkOrder360View/${workOrders.identifiers.worksOrderIdentifier}`);

    }
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };
  const callPageHandler = () => {
    dispatch(backToRepairAsset(false))
    if(isSurvey){
      dispatch(userTypedRepairReasons(''));
      dispatch(userChoiceRepairReasons(''));
      dispatch(getContractorName(''));
      dispatch(userChosenAdditionalDescription(''));
      dispatch(userChosenEmergencyQuestion(''));
      dispatch(userTypedSurveyReason(''));
      dispatch(userChosenSurveyReason(''));
      dispatch(createEnhancedHubRepairPermissions(null))
      props.history.push("/SurveyLocation");
    }else{
      props.history.push("/RepairLocation");
    }
   };
  const checkPrimiseEligilibility = async (
    premisesId: any,
    tenancyGroup: any
  ) => {
    try {
      const responsePremisesEligibility = await api.postPremisesEligibility(
        premisesId,
        tenancyGroup
      );
      if (responsePremisesEligibility?.length > 0) {
        setIsLoading(false);
        setButtonDisable(false);
        setDisableSelection(false)
        isPremisesElegible = Boolean(
          JSON.parse(responsePremisesEligibility[0]?.isEligible)
        );
        let errorCode = responsePremisesEligibility[0]?.errorCode;
        if (
          errorCode === "E1" ||
          errorCode === "E4" ||
          errorCode === "E24" ||
          errorCode === "E5"
        ) {
          setErrorCode(errorCode);
          setEligible("block");
          setButtonDisable(true);
          if (
            tenancyGroup === "LEASEHOLD" ||
            tenancyGroup === "FREEHOLD" ||
            tenancyGroup === "AGENT"
          ) {
            errorCode = t(errorCode) + tenancyGroup;
            setErrorCode(errorCode);
            setEligible("block");
            setButtonDisable(true);
          } else if (errorCode === "E1" || errorCode === "E4") {
            let tempError;
            tempError = t(errorCode).replace("@tenancyGroup", tenancyGroup);
            var tempArray = tempError.split("?");
            setErrorCodeArray(tempArray);
          } else if (errorCode === "E5") {
            errorCode = t(errorCode) + mainSet;
            let tempError;
            tempError = t(errorCode);
            var tempArray = tempError.split("?");
            setErrorCodeArray(tempArray);
          }
        } else {
          setErrorCode(responsePremisesEligibility[0]?.errorCode);
          if (!isPremisesElegible) {
            setEligible("block");
            setButtonDisable(true);
          } else {
            setEligible("none");
            setButtonDisable(false);
            setDisableSelection(false)
          }
        }
        dispatch(saveIsEligibilityRepair(isPremisesElegible));
      } else {
        SetErrorDetails();
        setDisableSelection(false)
      }
    } catch (e) {
      setDisableSelection(false)
      SetErrorDetails();
      console.error(e);
    }
  };

  const GetTenancyGroup = async (customerID: any, premisesId: any) => {
    const responseTenancyGroup = await api.getTenanciesGroup(
      customerID,
      premisesId
    );
    let assetType =
      responseTenancyGroup.length > 0
        ? responseTenancyGroup[0]?.tenancyGroup
        : "";
        dispatch(selectedTenancyGroup(responseTenancyGroup[0]?.tenancyGroup))
    setIsLoading(true);
    if(!isSurvey)
    {
    checkPrimiseEligilibility(premisesId, assetType);
    }
    else
    {
      setEligible("none");
      setButtonDisable(false);
    }
  };
  const getValue = async (assetType: string, assetId: string, uprn: string,address: string) => {
    setAssetTypeValue(assetType);
    if (customerID) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    dispatch(selectedTenacyType(assetType));
    setPropertyIdChosen(assetId);
   
    dispatch(userChosenPropertyAddress(address));

    
    const userChosenAssetResponse = await api.getAssetsByid(assetId);
    dispatch(assetByIdDataObject(userChosenAssetResponse));

    if (assetId && customerID) {
      GetTenancyGroup(customerID, assetId);
    }
    sessionStorage.setItem("userChosenPropertyType", assetType);
    
    dispatch(
      updateDashboardForm({
        assetType,
        assetId,
        customerID,
      })
    );
  };
  const updateSelection = (
    assetType: string,
    assetId: string,
    uprn: string,address: string
  ) => {
    if (EditState === true) {
      if(!IsRecall)
      {
      dispatch(userChosenRepairLocationId(0));
      dispatch(userChosenHighLevelDescription(""));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenAdditionalDescription(""));
      }
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(userChosenEmergencyQuestion(""));
      dispatch(userChosenPropertyId(""));
      dispatch(isEditState(false));

      dispatch(userChosenDuplicateQuestion(""));
    }
    dispatch(userTypedRepairReasons(""));
    if(!disableSelection){
    setPropertyIdChosen(assetId);
    getValue(assetType, assetId, uprn,address);
    setDisableSelection(true)
    }
  };
  const callSelection = (assetType: string, assetId: string, uprn: string, address: string) => {
    getValue(assetType, assetId, uprn,address);

  };

  let _className = "card-body1 bg-white shadow-lg1 rounded-lg";
  let fieldsArray: any[] = [];
  addresses?.forEach((element: any) => {
    element?.length > 0 &&
      element.forEach((item: any) => {
        if (item.assetType !== "Scheme" && item.assetType !== "Estate Area") {
          _className =
            item.assetType === assetTypeValue && item.id === propertyIdChosen
              ? "border-purple-100 card-body1 bg-white shadow-lg1 rounded-lg min-ht"
              : "card-body1 bg-white shadow-lg1 rounded-lg min-ht";
          fieldsArray.push(
            <div
              data-testid={item.assetType}
              
              className="mb-4 asset-panels margin-rt gap-4 mb-4 asset-panel border bg-white shadow-lg rounded-lg"
              id={"repair-asset-main-div" + item.id}
            >
              {isRecallValue?
                (
                  <div
                  data-testid="update-select1"
                >
                  <div
                    className="card-margin-new"
                    id={"repair-asset-div1" + item.id}
                  >
                    <div
                      className={_className}
                      id={"repair-asset-div2" + item.id}
                    >
                      <div
                        className="d-flex px-6 py-2 bg-peach rounded-t-lg flex items-center bg-peach-radius card-title-height"
                        id={"repair-asset-div3" + item.id}
                      >
                        <div
                          className="ic-user-new flex"
                          id={"repair-asset-div4" + item.id}
                        >
                          <img
                            src={getAssetIcon(item.assetType)}
                            alt=""
                            className="me-2 my-0 text-lg"
                          ></img>
                        </div>
                        <h2 className="card-text my-0 text-lg" id={item.id}>
                          {/* @ts-ignore */}
                          {BlockMap[item.assetType]}
                        </h2>
                      </div>
                      <p
                        className="card-text px-6 pt-3 global-txt m-0"
                        id={"repair-asset-p" + item.id}
                      >
                        {item.addresses?.postalAddresses[0]?.formattedAddress
                          .split(",")
                          .map((step: any, i: number) => (
                            <>
                              {i === 0 ? (
                                <strong className="py-2 my-2">{step}</strong>
                              ) : (
                                <p className="py-0 my-0">{step}</p>
                              )}
                              {"\n "}
                            </>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                ):(
                  <div
                  data-testid="update-select"
                  className={`${disableSelection ? 'pe-none' : 'cursor-pointer'}`}
                  onClick={() => {
                    updateSelection(item.assetType, item.id, item.uprn, item.addresses.postalAddresses[0]?.formattedAddress);             
                  }}
                >
                  <div
                    className="card-margin-new"
                    id={"repair-asset-div1" + item.id}
                  >
                    <div
                      className={_className}
                      id={"repair-asset-div2" + item.id}
                    >
                      <div
                        className="d-flex px-6 py-2 bg-peach rounded-t-lg flex items-center bg-peach-radius card-title-height"
                        id={"repair-asset-div3" + item.id}
                      >
                        <div
                          className="ic-user-new flex"
                          id={"repair-asset-div4" + item.id}
                        >
                          <img
                            src={getAssetIcon(item.assetType)}
                            alt=""
                            className="me-2 my-0 text-lg"
                          ></img>
                        </div>
                        <h2 className="card-text my-0 text-lg" id={item.id}>
                          {/* @ts-ignore */}
                          {BlockMap[item.assetType]}
                        </h2>
                      </div>
                      <p
                        className="card-text px-6 pt-3 global-txt m-0"
                        id={"repair-asset-p" + item.id}
                      >
                        {item.addresses?.postalAddresses[0]?.formattedAddress
                          .split(",")
                          .map((step: any, i: number) => (
                            <>
                              {i === 0 ? (
                                <strong className="py-2 my-2">{step}</strong>
                              ) : (
                                <p className="py-0 my-0">{step}</p>
                              )}
                              {"\n "}
                            </>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                )
              }
             
            </div>
          );
        }
      });
  });

  return (
    <Container id="back-modal-container" role="main" className="ctn-fht">
      <div
        id="ReapirAsset-back-div"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="ReapirAsset-p" className="text-teal text-xl mt-4 mb-8">
          <a
            className="back-btn"
            data-testid="backButtonHandler"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            {t("back")}
          </a>
        </p>
      </div>
      <div className="container">
        <div id="repair-asset-divs" className="mb-4 repair-text">
        {isSurvey?(

          <h2
            id="repair-asset-h2"
            className="text-purple-100 text-2xl mb-8 global-txt"
          >
           {t("survey_report_label")}
          </h2>
           ):(
            <h2
            id="repair-asset-h1"
            className="text-purple-100 text-2xl mb-8 global-txt"
          >
           
           {t("repair_report_label")}
          </h2>
           )}
        </div>
        <div className="lg:min-h-80">
          <div
            className="asset-panels grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 md:gap-8 mb-4 "
            id="options"
          >
            {fieldsArray}
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={5000}
        />
      ) : (
        <div
          style={{ display: eligible }}
          className="warning mb-8"
          role="alert"
          id="repair-asset-alert"
        >
          <div className="w-full px-4 py-2 bg-red-20 flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
            </div>
            <div className="flex items-center text-black">
              {errorCode !== "E1" &&
              errorCode !== "E4" &&
              errorCode !== "E5" ? (
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t(errorCode)}
                </p>
              ) : (
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {errorCode === "E1" ? (
                    <>
                      {errorCodeArray[0]}
                      <ul>
                        <li> {errorCodeArray[1]}</li>
                        <li> {errorCodeArray[2]}</li>
                      </ul>
                    </>
                  ) : (
                    <>
                      {errorCodeArray[0]}
                      <ul>
                        <li> {errorCodeArray[1]}</li>
                      </ul>
                    </>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="flex justify-between items-center pb-4 md:pb-8 container"
        id="repair-asset-btn"
      >
        <ExitModalContent
          id="repair-asset-exitmodal"
          exit={exitButtonHandler}
        />
        <Button
          id="btn_repairasset"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="button-class"
        >
          {t("continue")}{" "}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};
export default RepairAsset;
